import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { EditModalForm, EditModalProps } from './types'
import { editModalValidationSchema } from './validation'
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../../utils/toastMessage'
import {
  useEditEventMutation,
  useGetSingleEventQuery,
} from '../../../api/eventsApi'
import { EditEventResponse } from '../../../api/eventsApi/types'
import { formatDateToYYYYMMDD, formatTimeToHHMMSS } from '../../../utils/common'

const useEditModal = ({ id, openModal, handleCloseModal }: EditModalProps) => {
  const [eventImage, setEventImage] = useState<File | string | null>()

  // Fetching Event Data
  const {
    data: eventData,
    isSuccess: isEventSuccess,
    isFetching: isEventFetching,
  } = useGetSingleEventQuery(
    {
      id: id,
    },
    {
      skip: !openModal, // Skip the query if the modal is not open
    },
  )

  // Edit Event
  const [editEvent, { isLoading }] = useEditEventMutation()

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    watch,
  } = useForm<EditModalForm>({
    defaultValues: {
      isEventImage: false,
      title: '',
      description: '',
      videoUrl: '',
      date: '',
      time: '',
    },
    resolver: yupResolver(editModalValidationSchema),
  })

  // Handle Event Image Field
  const handleEventImage = (file: File) => {
    const reader = new FileReader()

    setEventImage(file)
    setValue('isEventImage', true, {
      shouldValidate: true,
    })

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  useEffect(() => {
    if (eventData?.event && isEventSuccess) {
      setEventImage(eventData?.event?.photo)
      setValue('isEventImage', true)
      setValue('title', eventData?.event?.title)
      setValue('description', eventData?.event?.description)
      setValue('videoUrl', eventData?.event?.video_link)
      setValue('date', eventData?.event?.date)
      setValue('time', eventData?.event?.time)
    }
  }, [eventData, setValue])

  const onSubmit: SubmitHandler<EditModalForm> = async (data) => {
    if (!eventImage) {
      return
    }
    const formData = new FormData()
    formData.append('photo', eventImage)
    formData.append('title', data?.title)
    formData.append('description', data?.description || '')
    formData.append('video_link', data?.videoUrl)
    formData.append('date', formatDateToYYYYMMDD(data?.date)) // Add date field
    formData.append('time', formatTimeToHHMMSS(data?.time)) // Add time field

    editEvent({ id: id, formData: formData })
      .unwrap()
      .then((data: EditEventResponse) => {
        if (data?.success) {
          toastSuccessMessage('Event Updated Successfully')
          handleCloseModal()
        } else {
          toastErrorMessage(data?.message || 'Unable to Updated Event')
        }
      })
      .catch((error) => {
        toastErrorMessage(error?.data?.message || 'Unable to Updated Event')
      })
  }

  return {
    control,
    register,
    errors,
    reset,
    onSubmit,
    handleSubmit,
    isLoading,
    eventImage,
    handleEventImage,
    isDataLoading: isEventFetching && !isEventSuccess,
  }
}

export default useEditModal
