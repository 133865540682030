import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { AppDispatch, RootState } from '../store'
import routeUrls from '../constants/routeUrls'
import { resetState } from '../store/slice/auth/authSlice'
import { toastSuccessMessage } from '../utils/toastMessage'

const UnProtectedRoute: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { isAuthenticated, username, id, loading } = useSelector(
    (state: RootState) => state.authReducer,
  )
  const location = useLocation()

  if (isAuthenticated && !!username && !!id) {
    if (loading === 'succeeded') {
      // dispatch(resetState())
      toastSuccessMessage('Successfully Login')
    }
    return <Navigate to={routeUrls.DASHBOARD} state={{ from: location }} />
  }
  return <Outlet />
}

export default UnProtectedRoute
