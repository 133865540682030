import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { EditModalForm, EditModalProps } from './types'
import { editModalValidationSchema } from './validation'
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../../utils/toastMessage'
import {
  useEditGiftMutation,
  useGetSingleGiftQuery,
} from '../../../api/giftsApi'
import { EditGiftResponse } from '../../../api/giftsApi/types'

const useEditModal = ({ id, openModal, handleCloseModal }: EditModalProps) => {
  const [giftImage, setGiftImage] = useState<File | string | null>()

  // Fetching Gift Data
  const {
    data: giftData,
    isSuccess: isGiftSuccess,
    isFetching: isGiftFetching,
  } = useGetSingleGiftQuery(
    {
      id: id,
    },
    {
      skip: !openModal, // Skip the query if the modal is not open
    },
  )

  // Edit Gift
  const [editGift, { isLoading }] = useEditGiftMutation()

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    watch,
  } = useForm<EditModalForm>({
    defaultValues: {
      isGiftImage: false,
      giftName: '',
      points: 0,
    },
    resolver: yupResolver(editModalValidationSchema),
  })

  // Handle Gift Image Field
  const handleGiftImage = (file: File) => {
    const reader = new FileReader()

    setGiftImage(file)
    setValue('isGiftImage', true, {
      shouldValidate: true,
    })

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  useEffect(() => {
    if (giftData?.data && isGiftSuccess) {
      setGiftImage(giftData?.data?.gift_photo)
      setValue('isGiftImage', true)
      setValue('giftName', giftData?.data?.gift_name)
      setValue('points', giftData?.data?.points)
    }
  }, [giftData, setValue])

  const onSubmit: SubmitHandler<EditModalForm> = async (data) => {
    if (!giftImage) {
      return
    }
    const formData = new FormData()
    formData.append('gift_photo', giftImage)
    formData.append('gift_name', data?.giftName)
    formData.append('points', data?.points.toString())

    editGift({ id: id, formData: formData })
      .unwrap()
      .then((data: EditGiftResponse) => {
        if (data?.success) {
          toastSuccessMessage('Gift Updated Successfully')
          handleCloseModal()
        } else {
          toastErrorMessage(data?.message || 'Unable to Updated Gift')
        }
      })
      .catch((error) => {
        toastErrorMessage(error?.data?.message || 'Unable to Updated Gift')
      })
  }

  return {
    control,
    register,
    errors,
    reset,
    onSubmit,
    handleSubmit,
    isLoading,
    giftImage,
    handleGiftImage,
    isDataLoading: isGiftFetching && !isGiftSuccess,
  }
}

export default useEditModal
