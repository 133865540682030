import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AddModalForm, AddModalProps } from './types'
import { addModalValidationSchema } from './validation'
import { useAddVisitingCardEligibilityLimitMutation } from '../../../../api/visitingCardEligibilityLimit'
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../../../utils/toastMessage'
import { AddVisitingCardEligibilityLimitResponse } from '../../../../api/visitingCardEligibilityLimit/types'

const useAddModal = ({ handleCloseModal }: AddModalProps) => {
  const [addVisitingCardLimit, { isLoading }] =
    useAddVisitingCardEligibilityLimitMutation()
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm<AddModalForm>({
    defaultValues: {
      district: undefined,
      state: undefined,
      points: 0,
    },
    resolver: yupResolver(addModalValidationSchema),
  })

  const onSubmit: SubmitHandler<AddModalForm> = async (data) => {
    const formData = new FormData()
    formData.append('district_id', data?.district.toString())
    formData.append('state_id', data?.state.toString())
    formData.append('points', data?.points.toString())

    addVisitingCardLimit(formData)
      .unwrap()
      .then((data: AddVisitingCardEligibilityLimitResponse) => {
        if (data?.success) {
          toastSuccessMessage('Visitng Card Limit Added Successfully')
          handleCloseModal()
        } else {
          toastErrorMessage(
            data?.message || 'Unable to Add Visiting Card Limit',
          )
        }
      })
      .catch((error) => {
        toastErrorMessage(
          error?.data?.message || 'Unable to Add Visiting Card Limit',
        )
      })
  }

  return {
    control,
    register,
    errors,
    reset,
    onSubmit,
    handleSubmit,
    isLoading,
    watch,
  }
}

export default useAddModal
