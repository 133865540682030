import React, { FC } from 'react'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import BreadcrumbsComponent from '../BreadcrumbItem'
import { TopBarProps } from './types'

const TopBar: FC<TopBarProps> = ({ title, breadcrumbsItems }) => {
  return (
    <Card elevation={0} sx={{ borderRadius: 3 }}>
      <CardContent style={{ paddingBottom: '16px' }}>
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Typography variant='h6' fontWeight={600}>
              {title}
            </Typography>
          </Grid>
          <Grid item alignContent={'center'}>
            <BreadcrumbsComponent items={breadcrumbsItems} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TopBar
