import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ListingResponse } from '../../types'
import { API_METHODS } from '../../../constants/contants'
import { API_ENDPOINTS } from '../../../constants/apiendpoints'
import {
  PendingInvitationUserPayload,
  PendingInvitationUserResponse,
  SinglePendingInvitationUserPayload,
  SinglePendingInvitationUserResponse,
  UpdatePendingInvitationUserStatusResponse,
} from './types'
import { ApprovedUsersApiTags, PendingInvitationUsersApiTags } from '../tags'
import { approvedUsersApi } from '../approvedUsersApi'

export const pendingInvitationUsersApi = createApi({
  reducerPath: 'pendingInvitationApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: [
    PendingInvitationUsersApiTags.PENDING_INVITATION_USERS_CREATED,
    PendingInvitationUsersApiTags.PENDING_INVITATION_USERS_DELETED,
    PendingInvitationUsersApiTags.PENDING_INVITATION_USERS_MODIFIED,
    PendingInvitationUsersApiTags.PENDING_INVITATION_USERS_SINGLE,
    ApprovedUsersApiTags.APPROVED_USERS_MODIFIED, // This allows us to refetch approvedUsers
  ],
  endpoints: (builder) => ({
    // Get All Pending Invitation Users
    pendingInvitationUsersDetails: builder.query<
      ListingResponse<PendingInvitationUserResponse[]>,
      PendingInvitationUserPayload
    >({
      query: (params: PendingInvitationUserPayload) => {
        return {
          url: API_ENDPOINTS.GET_ALL_PENDING_INVITATION_USERS,
          method: API_METHODS.GET,
          params,
        }
      },
      providesTags: [
        PendingInvitationUsersApiTags.PENDING_INVITATION_USERS_CREATED,
        PendingInvitationUsersApiTags.PENDING_INVITATION_USERS_DELETED,
        PendingInvitationUsersApiTags.PENDING_INVITATION_USERS_MODIFIED,
      ],
      transformResponse: (
        response: ListingResponse<PendingInvitationUserResponse[]>,
      ) => {
        return response
      },
    }),

    // Update Invitation User Status
    updatePendingInvitationUserStatus: builder.mutation<
      UpdatePendingInvitationUserStatusResponse,
      FormData
    >({
      query: (params: FormData) => ({
        url: API_ENDPOINTS.UPDATE_PENDING_INVITATION_USERS_STATUS,
        method: API_METHODS.POST,
        body: params,
      }),
      invalidatesTags: [
        PendingInvitationUsersApiTags.PENDING_INVITATION_USERS_MODIFIED,
        ApprovedUsersApiTags.APPROVED_USERS_MODIFIED, // This will refetch approvedUsersDetails
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          // Wait for the query to finish
          await queryFulfilled

          // After the query completes successfully, trigger refetch for the approved posts
          dispatch(
            approvedUsersApi.util.invalidateTags([
              ApprovedUsersApiTags.APPROVED_USERS_MODIFIED,
            ]),
          )
        } catch (err) {
          console.error('Error refetching:', err)
        }
      },
      transformResponse: (
        response: UpdatePendingInvitationUserStatusResponse,
      ) => {
        return response
      },
    }),

    // single pending user
    getSinglePendingUser: builder.query<
      SinglePendingInvitationUserResponse,
      SinglePendingInvitationUserPayload
    >({
      query: (params: SinglePendingInvitationUserPayload) => ({
        url: API_ENDPOINTS.GET_PENDING_INVITATION_USERS_BY_ID,
        method: API_METHODS.GET,
        params: params,
      }),
      providesTags: [
        PendingInvitationUsersApiTags.PENDING_INVITATION_USERS_SINGLE,
      ],
      transformResponse: (response: SinglePendingInvitationUserResponse) => {
        return response
      },
    }),
  }),
})

export const {
  useUpdatePendingInvitationUserStatusMutation,
  useGetSinglePendingUserQuery,
  usePendingInvitationUsersDetailsQuery,
} = pendingInvitationUsersApi
