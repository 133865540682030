import React from 'react'
import { Avatar, ButtonBase, useTheme } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { AvatarButtonProps } from './types'

const AvatarButton: React.FC<AvatarButtonProps> = ({
  onClick,
  icon,
  color = 'primary', // Default color to 'primary'
  sx,
}) => {
  const theme = useTheme() // Access the theme

  return (
    <ButtonBase
      sx={{ borderRadius: '9px', overflow: 'hidden' }}
      onClick={onClick}
    >
      <Avatar
        variant='rounded'
        sx={{
          backgroundColor: theme.palette[color].light, // Use the selected color's light shade
          color: theme.palette[color].main, // Use the selected color's main shade
          width: 35,
          height: 35,
          ':hover': {
            backgroundColor: theme.palette[color].main, // Change background on hover
            color: 'white',
          },
          ...sx, // Allow for custom styles via props
        }}
      >
        {icon || <MenuIcon fontSize='small' />}
      </Avatar>
    </ButtonBase>
  )
}

export default AvatarButton
