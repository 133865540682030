import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { AddModalForm, AddModalProps } from './types'
import { addModalValidationSchema } from './validation'
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../../utils/toastMessage'
import { useAddProductMutation } from '../../../api/productsApi'
import { AddProductResponse } from '../../../api/productsApi/types'

const useAddModal = ({ handleCloseModal }: AddModalProps) => {
  const [addProduct, { isLoading }] = useAddProductMutation()
  const [productImage, setProductImage] = useState<File | null>()

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm<AddModalForm>({
    defaultValues: {
      isProductImage: false,
      productName: '',
      points: undefined,
      packing: undefined,
    },
    resolver: yupResolver(addModalValidationSchema),
  })

  // Handle Product Image Field
  const handleProductImage = (file: File) => {
    const reader = new FileReader()

    setProductImage(file)
    setValue('isProductImage', true, {
      shouldValidate: true,
    })

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  const resetForm = () => {
    setProductImage(null)
    reset()
  }

  const onSubmit: SubmitHandler<AddModalForm> = async (data) => {
    if (!productImage) {
      return
    }
    const formData = new FormData()

    formData.append('product_photo', productImage)
    formData.append('product_name', data?.productName)
    formData.append('packing', data?.packing.toString())
    formData.append('points', data?.points.toString())

    addProduct(formData)
      .unwrap()
      .then((data: AddProductResponse) => {
        if (data?.success) {
          toastSuccessMessage('Product Added Successfully')
          handleCloseModal()
        } else {
          toastErrorMessage(data?.message || 'Unable to Add Product')
        }
      })
      .catch((error) => {
        toastErrorMessage(error?.data?.message || 'Unable to Add Product')
      })
  }

  return {
    control,
    register,
    errors,
    reset: resetForm,
    onSubmit,
    handleSubmit,
    isLoading,
    productImage,
    handleProductImage,
  }
}

export default useAddModal
