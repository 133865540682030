import { FC, useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useForm } from 'react-hook-form'
import RoundedImageUploader from '../../../../components/RoundedImageUploader'
import InputField from '../../../../components/InputField'
import DateTimeField from '../../../../components/DateTimeField'
import SelectField from '../../../../components/SelectField'
import FileInput from '../../../../components/FileInput'
import { RootState } from '../../../../store'
import { useSelector } from 'react-redux'
import { useDistrictDetailsQuery } from '../../../../api/districtApi'
import RadioFieldInput from '../../../../components/RadioFieldInput'
import { SELECT_ID_TYPE } from '../../../../constants/contants'
import { PendingUserForm, ViewPendingUserModalProps } from './types'
import { useGetSinglePendingUserQuery } from '../../../../api/userApi/pendingInvitationUsersApi'

const ViewPendingUserModal: FC<ViewPendingUserModalProps> = ({
  id,
  openModal,
  handleCloseModal,
}) => {
  // Fetching Pending User Data
  const { data, isSuccess, isFetching } = useGetSinglePendingUserQuery(
    {
      id: id,
    },
    {
      skip: !openModal, // Skip the query if the modal is not open
    },
  )

  const [profileImage, setProfileImage] = useState<File | string | null>()
  const [aadharPanImage, setAadharPanImage] = useState<File | string | null>()

  // Listing Query Params
  const listingQueryParams = useSelector(
    (state: RootState) => state.commonReducer.listingQueryParams,
  )
  const { district: districtQueryParams } = listingQueryParams

  // District List
  const {
    data: districtList,
    isFetching: isDistrictListFetching,
    isSuccess: isDistrictListSuccess,
  } = useDistrictDetailsQuery(districtQueryParams)

  const { control, setValue } = useForm<PendingUserForm>({
    defaultValues: {
      mobileNumber: '',
      fullName: '',
      password: '',
      dob: '',
      district: '',
      state: '',
      area: '',
      executiveName: '',
      selectId: '',
    },
  })

  useEffect(() => {
    if (data?.data) {
      // Set form values once data is available
      setProfileImage(data?.data?.profile_pic)
      setValue('mobileNumber', data?.data?.mobile_number)
      setValue('fullName', data?.data?.full_name)
      setValue('password', data?.data?.password)
      setValue('dob', data?.data?.date_of_birth)
      if (!!data?.data?.aadhaar_image?.length) {
        setValue('selectId', SELECT_ID_TYPE.AADHAR)
        setAadharPanImage(data?.data?.aadhaar_image)
      } else {
        setValue('selectId', SELECT_ID_TYPE.PAN)
        setAadharPanImage(data?.data?.pan_image)
      }
      setValue('state', data?.data?.state_name)
      setValue('district', data?.data?.district_name)
      setValue('area', data?.data?.area_name)
      setValue('executiveName', data?.data?.executive_name)
    }
  }, [data, setValue])

  return (
    <Dialog
      fullWidth
      sx={{
        '.MuiDialog-paper': {
          borderRadius: 4,
        },
      }}
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle
        sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }}
        id='customized-dialog-title'
      >
        View Pending Invitation User Details
        <IconButton
          aria-label='close'
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {isFetching || !isSuccess ? (
          <Box textAlign='center'>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={1}>
            {/* ProfileImage */}
            <Grid item xs={12}>
              <RoundedImageUploader value={profileImage} readOnly />
            </Grid>

            {/* Full Name Field */}
            <Grid item xs={12} sm={6}>
              <InputField
                name={'fullName'}
                control={control}
                label={'Full Name'}
                fullWidth
                readOnly
              />
            </Grid>

            {/* Mobile Number Field */}
            <Grid item xs={12} sm={6}>
              <InputField
                name={'mobileNumber'}
                control={control}
                label={'Mobile Number'}
                fullWidth
                readOnly
              />
            </Grid>

            {/* Password Field */}
            <Grid item xs={12} sm={6}>
              <InputField
                name={'password'}
                control={control}
                label={'Password'}
                type={'password'}
                fullWidth
                readOnly
              />
            </Grid>

            {/* Date of Birth Field */}
            <Grid item xs={12} sm={6} mt={2}>
              <DateTimeField
                name='dob'
                label='Date of Birth'
                disableFuture
                control={control}
                readOnly
              />
            </Grid>

            {/* State Field*/}
            <Grid item xs={12} sm={6}>
              <InputField
                name={'state'}
                control={control}
                label={'State'}
                fullWidth
                readOnly
              />
            </Grid>

            {/* District Field */}
            <Grid item xs={12} sm={6}>
              <InputField
                name={'district'}
                control={control}
                label={'District'}
                fullWidth
                readOnly
              />
            </Grid>

            {/* Area Name */}
            <Grid item xs={12} sm={6}>
              <InputField
                name={'area'}
                control={control}
                label={'Area'}
                fullWidth
                readOnly
              />
            </Grid>

            {/* Executive Name */}
            <Grid item xs={12} sm={6}>
              <InputField
                name={'executiveName'}
                control={control}
                label={'Executive Name'}
                fullWidth
                readOnly
              />
            </Grid>

            {/* Select Id Option Field */}
            <Grid item xs={12} mt={2}>
              <RadioFieldInput
                control={control}
                name='selectId'
                label='Select Id Type'
                options={[
                  { value: 'aadhar', label: 'Aadhar' },
                  { value: 'pan', label: 'Pan' },
                ]}
                direction='row'
                readOnly
              />
            </Grid>
            {/* Aadhar/Pan Image Field */}
            <Grid item xs={12}>
              <FileInput value={aadharPanImage} readOnly />
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ViewPendingUserModal
