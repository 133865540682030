import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { EditModalForm, EditModalProps } from './types'
import { editModalValidationSchema } from './validation'
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../../utils/toastMessage'
import {
  useEditQRCodeMutation,
  useGetSingleQRCodeQuery,
} from '../../../api/qrCodeApi'
import { EditQRCodeResponse } from '../../../api/qrCodeApi/types'

const useEditModal = ({ id, openModal, handleCloseModal }: EditModalProps) => {
  const [qrCodeImage, setQRCodeImage] = useState<File | string | null>(null)

  // Fetching QRCode Data
  const {
    data: qrCodeData,
    isSuccess: isQRCodeSuccess,
    isFetching: isQRCodeFetching,
  } = useGetSingleQRCodeQuery(
    {
      id: id,
    },
    {
      skip: !openModal, // Skip the query if the modal is not open
    },
  )

  // Edit QRCode
  const [editQRCode, { isLoading }] = useEditQRCodeMutation()
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm<EditModalForm>({
    defaultValues: {
      points: undefined,
      token: '',
    },
    resolver: yupResolver(editModalValidationSchema),
  })

  useEffect(() => {
    if (qrCodeData?.data && isQRCodeSuccess) {
      setQRCodeImage(qrCodeData?.data?.qr_image)
      setValue('token', qrCodeData?.data?.token)
      setValue('points', qrCodeData?.data?.points)
    }
  }, [qrCodeData, setValue])

  const onSubmit: SubmitHandler<EditModalForm> = async (data) => {
    const formData = new FormData()

    formData.append('points', data.points.toString())

    editQRCode({ id: id, formData: formData })
      .unwrap()
      .then((data: EditQRCodeResponse) => {
        if (data?.success) {
          toastSuccessMessage('QR Code Details Updated Successfully')
          handleCloseModal()
        } else {
          toastErrorMessage(data?.message || 'Unable to Update QR Code Details')
        }
      })
      .catch((error) => {
        toastErrorMessage(
          error?.data?.message || 'Unable to Update QR Code Details',
        )
      })
  }

  return {
    control,
    register,
    errors,
    reset,
    onSubmit,
    handleSubmit,
    isLoading,
    qrCodeImage,
    isDataLoading: isQRCodeFetching && !isQRCodeSuccess,
    qrCode: qrCodeData?.data?.token
  }
}

export default useEditModal
