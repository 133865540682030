import HomeIcon from '@mui/icons-material/Home'
import TopBar from '../../components/TopBar'
import routeUrls from '../../constants/routeUrls'
import { Box, Button } from '@mui/material'
import DataTable from './DataTable'
import { useEffect, useState } from 'react'
import { APP_CONFIG } from '../../constants/contants'
import { setListingQueryParams } from '../../store/slice/commonSlice'
import { useQrCodeDetailsQuery } from '../../api/qrCodeApi'
import { AppDispatch, RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import AddQRCodeModal from './AddQRCodeModal'
import EditQRCodeModal from './EditQRCodeModal'

const QRCodeManagement = () => {
  const dispatch = useDispatch<AppDispatch>()

  // Listing Query Params
  const listingQueryParams = useSelector(
    (state: RootState) => state.commonReducer.listingQueryParams,
  )

  const { qrCode: qrCodeQueryParams } = listingQueryParams

  // BreadCrumbsItems
  const breadcrumbsItems = [
    {
      to: routeUrls.DASHBOARD,
      text: 'Home',
      icon: <HomeIcon sx={{ mr: 0.5 }} fontSize='inherit' />,
    },
    { text: 'QR Code Management' },
  ]

  // Page
  const [page, setPage] = useState(APP_CONFIG.DEFAULT_PAGE)
  const [pageSize, setPageSize] = useState(APP_CONFIG.DEFAULT_PAGE_SIZE)

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage + 1)
  }

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(APP_CONFIG.DEFAULT_PAGE)
  }

  // Add Modal
  const [openAddModal, setOpenAddModal] = useState<boolean>(false)

  const handleOpenAddModal = () => {
    setOpenAddModal(true)
  }

  const handleCloseAddModal = () => {
    setOpenAddModal(false)
  }

  // Edit Modal
  const [openEditModal, setOpenEditModal] = useState({
    id: 0,
    open: false,
  })

  const handleOpenEditModal = (id: number) => {
    setOpenEditModal({
      id: id,
      open: true,
    })
  }

  const handleCloseEditModal = () => {
    setOpenEditModal({
      id: 0,
      open: false,
    })
  }

  const { data, isFetching, isSuccess } =
    useQrCodeDetailsQuery(qrCodeQueryParams)

  useEffect(() => {
    dispatch(
      setListingQueryParams({
        ...listingQueryParams,
        qrCode: {
          page: page,
          page_size: pageSize,
        },
      }),
    )
  }, [page, pageSize])

  return (
    <>
      <TopBar title='QR Code Management' breadcrumbsItems={breadcrumbsItems} />

      {/* Table */}
      <Box mt={2}>
        <DataTable
          headers={[
            '#',
            'Token Id',
            'Points',
            'Scanned Status',
            'Created At',
            'Updated At',
            'Download QR Code',
            'Actions',
          ]}
          action={
            <Button
              variant='contained'
              onClick={handleOpenAddModal}
              sx={{ textTransform: 'none', fontWeight: 600 }}
            >
              Create QR Code
            </Button>
          }
          data={data?.results}
          totalPages={data?.count}
          page={page}
          rowsPerPage={pageSize}
          handlePageChange={handlePageChange}
          handleOpenEditModal={handleOpenEditModal}
          handleChangeRowsPerPage={handleChangePageSize}
          isSuccess={isSuccess && !isFetching}
        />
      </Box>

      {/* Add Modal */}
      <AddQRCodeModal
        openModal={openAddModal}
        handleCloseModal={handleCloseAddModal}
      />

      {/* Edit Modal */}
      {!!openEditModal?.id && (
        <EditQRCodeModal
          id={openEditModal?.id}
          openModal={openEditModal?.open}
          handleCloseModal={handleCloseEditModal}
        />
      )}
    </>
  )
}

export default QRCodeManagement
