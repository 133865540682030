const routeUrls = {
  // page not found
  PAGENOTFOUND: '*',

  // Unprotected routes
  LOGIN: '/login',

  // Protected routes
  DASHBOARD: '/dashboard/overview',

  DISTRICTADMINSMANAGEMENT: '/administration/district-admins-management',
  USERSMANAGEMENT: '/administration/users-management',

  PRODUCTSMANANGEMENT: '/products/products-management',
  GIFTSMANANGEMENT: '/products/gifts-manangement',
  REDEEMPOINTSMANANGEMENT: '/products/redeem-points-manangement',

  STATELISTMANAGEMENT: '/location/state-management',
  DISTRICTLISTMANANGEMENT: '/location/district-list-management',
  VISITINGCARDMANANGEMENT: '/location/visiting-card-management',

  EVENTSMANANGEMENT: '/management/events-management',
  QRCODEMANAGEMENT: '/management/qrcode-management',

  USERSREPORTS: '/reports/user-reports',

  NOTIFICATIONS: '/notifications',
}

export default routeUrls
