import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ListingResponse } from '../../types'
import {
  RedeemPointsApprovedPayload,
  RedeemPointsApprovedResponse,
  SingleRedeemPointsApprovedPayload,
  SingleRedeemPointsApprovedResponse,
} from './types'
import { API_METHODS } from '../../../constants/contants'
import { API_ENDPOINTS } from '../../../constants/apiendpoints'
import { RedeemPointsApprovedApiTags } from './tags'

export const redeemPointsApprovedApi = createApi({
  reducerPath: 'redeemPointsApprovedApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: [
    RedeemPointsApprovedApiTags.REDEEM_POINTS_APPROVED_SINGLE,
    RedeemPointsApprovedApiTags.REDEEM_POINTS_APPROVED_MODIFIED,
  ],
  endpoints: (builder) => ({
    // Get All Rededem Points Approved
    redeemPointsApprovedListDetails: builder.query<
      ListingResponse<RedeemPointsApprovedResponse[]>,
      RedeemPointsApprovedPayload
    >({
      query: (params: RedeemPointsApprovedPayload) => {
        return {
          url: API_ENDPOINTS.GET_ALL_REDEEM_POINTS_APPROVED_LIST,
          method: API_METHODS.GET,
          params,
        }
      },
      providesTags: [
        RedeemPointsApprovedApiTags.REDEEM_POINTS_APPROVED_MODIFIED,
      ],
      transformResponse: (
        response: ListingResponse<RedeemPointsApprovedResponse[]>,
      ) => {
        return response
      },
    }),

    // single redeem poins approved details
    getSingleRedeemPointsApprovedDetails: builder.query<
      SingleRedeemPointsApprovedResponse,
      SingleRedeemPointsApprovedPayload
    >({
      query: (params: SingleRedeemPointsApprovedPayload) => ({
        url: API_ENDPOINTS.GET_SINGLE_REDEEM_POINTS_APPROVED_DETAILS, // The ID is part of the URL
        method: API_METHODS.GET,
        params: params,
      }),
      providesTags: [RedeemPointsApprovedApiTags.REDEEM_POINTS_APPROVED_SINGLE],
      transformResponse: (response: SingleRedeemPointsApprovedResponse) => {
        return response
      },
    }),
  }),
})

export const {
  useGetSingleRedeemPointsApprovedDetailsQuery,
  useRedeemPointsApprovedListDetailsQuery,
} = redeemPointsApprovedApi
