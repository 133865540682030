import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ListingResponse } from '../types'
import { DistrictApiTags } from './tags'
import {
  AddVisitingCardEligibilityLimitResponse,
  DeleteVisitingCardEligibilityLimitPayload,
  DeleteVisitingCardEligibilityLimitResponse,
  EditVisitingCardEligibilityLimitPayload,
  EditVisitingCardEligibilityLimitResponse,
  SingleVisitingCardEligibilityLimitPayload,
  SingleVisitingCardEligibilityLimitResponse,
  VisitingCardEligibilityLimitPayload,
  VisitingCardEligibilityLimitResponse,
} from './types'
import { API_METHODS } from '../../constants/contants'
import { API_ENDPOINTS } from '../../constants/apiendpoints'

export const visitingCardEligibilityLimitApi = createApi({
  reducerPath: 'visitingCardEligibilityLimitApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: [
    DistrictApiTags.VISITING_CARD_ELIGIBILITY_LIMIT_CREATED,
    DistrictApiTags.VISITING_CARD_ELIGIBILITY_LIMIT_DELETED,
    DistrictApiTags.VISITING_CARD_ELIGIBILITY_LIMIT_MODIFIED,
    DistrictApiTags.VISITING_CARD_ELIGIBILITY_LIMIT_SINGLE,
  ],
  endpoints: (builder) => ({
    // Get All Visiting Card Eligibility Limit
    visitingCardEligibilityLimitDetails: builder.query<
      ListingResponse<VisitingCardEligibilityLimitResponse[]>,
      VisitingCardEligibilityLimitPayload
    >({
      query: (params: VisitingCardEligibilityLimitPayload) => {
        return {
          url: API_ENDPOINTS.VISITING_CARD_ELIGIBILITY_LIMIT,
          method: API_METHODS.GET,
          params,
        }
      },
      providesTags: [
        DistrictApiTags.VISITING_CARD_ELIGIBILITY_LIMIT_CREATED,
        DistrictApiTags.VISITING_CARD_ELIGIBILITY_LIMIT_DELETED,
        DistrictApiTags.VISITING_CARD_ELIGIBILITY_LIMIT_MODIFIED,
      ],
      transformResponse: (
        response: ListingResponse<VisitingCardEligibilityLimitResponse[]>,
      ) => {
        return response
      },
    }),

    // Add Visiting Card Eligibility Limit
    addVisitingCardEligibilityLimit: builder.mutation<
      AddVisitingCardEligibilityLimitResponse,
      FormData
    >({
      query: (params: FormData) => ({
        url: API_ENDPOINTS.VISITING_CARD_ELIGIBILITY_LIMIT,
        method: API_METHODS.POST,
        body: params,
      }),
      invalidatesTags: [
        DistrictApiTags.VISITING_CARD_ELIGIBILITY_LIMIT_CREATED,
      ],
      transformResponse: (
        response: AddVisitingCardEligibilityLimitResponse,
      ) => {
        return response
      },
    }),

    // Delete Visiting Card Eligibility Limit
    deleteVisitingCardEligibilityLimit: builder.mutation<
      DeleteVisitingCardEligibilityLimitResponse,
      DeleteVisitingCardEligibilityLimitPayload
    >({
      query: (params: DeleteVisitingCardEligibilityLimitPayload) => ({
        url: API_ENDPOINTS.VISITING_CARD_ELIGIBILITY_LIMIT,
        method: API_METHODS.DELETE,
        params: params,
      }),
      invalidatesTags: [
        DistrictApiTags.VISITING_CARD_ELIGIBILITY_LIMIT_DELETED,
      ],
      transformResponse: (
        response: DeleteVisitingCardEligibilityLimitResponse,
      ) => {
        return response
      },
    }),

    // Edit Visiting Card Eligibility Limit
    editVisitingCardEligibilityLimit: builder.mutation<
      EditVisitingCardEligibilityLimitResponse,
      EditVisitingCardEligibilityLimitPayload
    >({
      query: ({ id, ...params }: EditVisitingCardEligibilityLimitPayload) => ({
        url: API_ENDPOINTS.VISITING_CARD_ELIGIBILITY_LIMIT, // Assuming the ID is part of the URL
        method: API_METHODS.PUT,
        body: params,
        params: {
          id,
        },
      }),
      invalidatesTags: [
        DistrictApiTags.VISITING_CARD_ELIGIBILITY_LIMIT_MODIFIED,
        DistrictApiTags.VISITING_CARD_ELIGIBILITY_LIMIT_SINGLE,
      ],
    }),

    // single Visiting Card Eligibility Limit
    getSingleVisitingCardEligibility: builder.query<
      SingleVisitingCardEligibilityLimitResponse,
      SingleVisitingCardEligibilityLimitPayload
    >({
      query: (params: SingleVisitingCardEligibilityLimitPayload) => ({
        url: API_ENDPOINTS.VISITING_CARD_ELIGIBILITY_LIMIT, // The ID is part of the URL
        method: API_METHODS.GET,
        params: params,
      }),
      providesTags: [DistrictApiTags.VISITING_CARD_ELIGIBILITY_LIMIT_SINGLE],
      transformResponse: (
        response: SingleVisitingCardEligibilityLimitResponse,
      ) => {
        return response
      },
    }),
  }),
})

export const {
  useAddVisitingCardEligibilityLimitMutation,
  useDeleteVisitingCardEligibilityLimitMutation,
  useEditVisitingCardEligibilityLimitMutation,
  useGetSingleVisitingCardEligibilityQuery,
  useVisitingCardEligibilityLimitDetailsQuery,
} = visitingCardEligibilityLimitApi
