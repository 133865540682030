import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import useAddModal from './useAddModal'
import InputField from '../../../components/InputField'
import ButtonField from '../../../components/ButtonField'
import { QRCodeSVG } from 'qrcode.react'
import { AddQRCodeModalProps } from './types'
import { useEffect } from 'react'

const AddQRCodeModal: React.FC<AddQRCodeModalProps> = ({
  openModal,
  handleCloseModal,
}) => {
  const {
    control,
    token,
    handleSubmit,
    onSubmit,
    errors,
    isLoading,
    isDataLoading,
    qrCodeRef,
    reset,
  } = useAddModal({
    openModal,
    handleCloseModal,
  })

  useEffect(() => {
    reset()
  }, [openModal])

  return (
    <Dialog
      fullWidth
      sx={{
        '.MuiDialog-paper': {
          borderRadius: 4,
        },
      }}
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      component='form'
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* Title */}
      <DialogTitle sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }} id='add-qrcode'>
        Create QR Code
      </DialogTitle>

      {/* Dialog box close button */}
      <IconButton
        onClick={handleCloseModal}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        {isDataLoading ? (
          <Box textAlign='center'>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={1}>
            {/* QR Code */}
            <Grid item xs={12} textAlign={'center'}>
              <QRCodeSVG
                size={240}
                level={'H'}
                includeMargin={true}
                value={token || ''}
                ref={qrCodeRef}
              />
            </Grid>

            {/* Token Field */}
            <Grid item xs={12}>
              <InputField
                name={'token'}
                control={control}
                label={'Token'}
                placeholder='Enter the token'
                fullWidth
                readOnly
              />
            </Grid>

            {/* Points */}
            <Grid item xs={12}>
              <InputField
                name={'points'}
                control={control}
                label={'Points'}
                type={'number'}
                placeholder='Enter the points'
                fullWidth
                required
                errors={!!errors.points}
                helperText={errors.points ? errors.points.message : ''}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <ButtonField
          variant='contained'
          onClick={handleCloseModal}
          buttonText={'Cancel'}
          sx={{
            backgroundColor: 'primary.main',
          }}
        />

        <ButtonField
          type='submit'
          loading={isLoading}
          variant='contained'
          buttonText={'Save'}
          sx={{
            backgroundColor: 'ternary.main',
            '&:hover': {
              backgroundColor: 'ternary.dark',
            },
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export default AddQRCodeModal
