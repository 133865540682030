import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { EditModalForm, EditModalProps } from './types'
import { editModalValidationSchema } from './validation'
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../../utils/toastMessage'
import {
  useEditDistrictAdminMutation,
  useGetSingleDistrictAdminQuery,
} from '../../../api/districtAdminApi'
import { SELECT_ID_TYPE } from '../../../constants/contants'
import { formatDateToYYYYMMDD } from '../../../utils/common'
import { EditDistrictResponse } from '../../../api/districtApi/types'

const useEditModal = ({ id, openModal, handleCloseModal }: EditModalProps) => {
  const [profileImage, setProfileImage] = useState<File | string | null>()
  const [aadharPanImage, setAadharPanImage] = useState<File | string | null>()
  const [hasLoaded, setHasLoaded] = useState(false)

  // Fetching District Data
  const {
    data: districtAdminData,
    isSuccess: isDistrictAdminSuccess,
    isFetching: isDistrictAdminFetching,
  } = useGetSingleDistrictAdminQuery(
    {
      id: id,
    },
    {
      skip: !openModal, // Skip the query if the modal is not open
    },
  )

  // Edit District Admin
  const [editDistrictAdmin, { isLoading }] = useEditDistrictAdminMutation()

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    watch,
  } = useForm<EditModalForm>({
    defaultValues: {
      isProfileImage: false,
      fullName: '',
      mobileNumber: '',
      password: '',
      confirmPassword: '',
      dob: '',
      district: undefined,
      state: undefined,
      selectId: '',
      isAadharPanImage: false,
    },
    resolver: yupResolver(editModalValidationSchema),
  })

  // Handle Profile Image Field
  const handleProfileImage = (file: File) => {
    const reader = new FileReader()

    setProfileImage(file)
    setValue('isProfileImage', true, {
      shouldValidate: true,
    })

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  // Handle Aadhar/Pan Image Field
  const handleAadharPanImage = (file: File) => {
    const reader = new FileReader()

    setAadharPanImage(file)
    setValue('isAadharPanImage', true, {
      shouldValidate: true,
    })

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  // Handle selectId changes
  useEffect(() => {
    const selectId = watch('selectId')

    if (hasLoaded) {
      if (
        selectId === SELECT_ID_TYPE.AADHAR ||
        selectId === SELECT_ID_TYPE.PAN
      ) {
        setAadharPanImage(null)
        setValue('isAadharPanImage', false)
      }
    }

    if (selectId) {
      setHasLoaded(true)
    }
  }, [watch('selectId')])

  useEffect(() => {
    if (districtAdminData?.data && isDistrictAdminSuccess) {
      setProfileImage(districtAdminData?.data?.profile_pic)
      setValue('isProfileImage', true)
      setValue('fullName', districtAdminData?.data?.full_name)
      setValue('password', districtAdminData?.data?.password)
      setValue('confirmPassword', districtAdminData?.data?.password)
      setValue('dob', districtAdminData?.data?.date_of_birth)
      setValue('mobileNumber', districtAdminData?.data?.mobile_number)
      if (!!districtAdminData?.data?.aadhaar_image?.length) {
        setValue('selectId', SELECT_ID_TYPE.AADHAR)
        setAadharPanImage(districtAdminData?.data?.aadhaar_image)
      } else {
        setValue('selectId', SELECT_ID_TYPE.PAN)
        setAadharPanImage(districtAdminData?.data?.pan_image)
      }
      setValue('isAadharPanImage', true)
      setValue('district', districtAdminData?.data?.district_id)
      setValue('state', districtAdminData?.data?.state_id)
    }
  }, [districtAdminData, isDistrictAdminSuccess, setValue])

  const onSubmit: SubmitHandler<EditModalForm> = async (data) => {
    if (!profileImage || !aadharPanImage) {
      return
    }
    const formData = new FormData()

    formData.append('full_name', data?.fullName)
    formData.append('profile_pic', profileImage)
    formData.append('mobile_number', data?.mobileNumber)
    formData.append('password', data?.password)
    formData.append('date_of_birth', formatDateToYYYYMMDD(data?.dob))
    formData.append('district_id', data?.district.toString())
    if (data?.selectId === SELECT_ID_TYPE.AADHAR) {
      formData.append('aadhaar_image', aadharPanImage)
    } else {
      formData.append('pan_image', aadharPanImage)
    }

    editDistrictAdmin({ id: id, formData: formData })
      .unwrap()
      .then((data: EditDistrictResponse) => {
        if (data?.success) {
          toastSuccessMessage('District Admin Updated Successfully')
          handleCloseModal()
        } else {
          toastErrorMessage(data?.message || 'Unable to Updated District Admin')
        }
      })
      .catch((error) => {
        toastErrorMessage(
          error?.data?.message || 'Unable to Updated District Admin',
        )
      })
  }

  return {
    control,
    register,
    errors,
    reset,
    onSubmit,
    handleSubmit,
    isLoading,
    profileImage,
    aadharPanImage,
    handleProfileImage,
    handleAadharPanImage,
    districtAdminData,
    watch,
    isDataLoading: isDistrictAdminFetching && !isDistrictAdminSuccess,
  }
}

export default useEditModal
