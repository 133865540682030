import { FC, useEffect } from 'react'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ViewModalProps, VisitingCardEligibilityLimitForm } from './types'
import { useForm } from 'react-hook-form'
import InputField from '../../../../components/InputField'
import SelectField from '../../../../components/SelectField'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { useDistrictDetailsQuery } from '../../../../api/districtApi'
import { useGetSingleVisitingCardEligibilityQuery } from '../../../../api/visitingCardEligibilityLimit'
import { useStateDetailsQuery } from '../../../../api/stateApi'

const ViewVisitingCardEligibilityLimitModal: FC<ViewModalProps> = ({
  id,
  openModal,
  handleCloseModal,
}) => {
  // Fetching District Data
  const { data, isSuccess, isFetching } =
    useGetSingleVisitingCardEligibilityQuery(
      {
        id: id,
      },
      {
        skip: !openModal, // Skip the query if the modal is not open
      },
    )

  const { control, setValue } = useForm<VisitingCardEligibilityLimitForm>({
    defaultValues: {
      state: undefined,
      district: undefined,
      points: 0,
    },
  })

  // Listing Query Params
  const listingQueryParams = useSelector(
    (state: RootState) => state.commonReducer.listingQueryParams,
  )

  // State List
  const { state: stateQueryParams } = listingQueryParams

  const {
    data: stateList,
    isFetching: isStateListFetching,
    isSuccess: isStateListSuccess,
  } = useStateDetailsQuery(stateQueryParams)

  // District List
  const { district: districtQueryParams } = listingQueryParams

  const {
    data: districtList,
    isFetching: isDistrictListFetching,
    isSuccess: isDistrictListSuccess,
  } = useDistrictDetailsQuery(districtQueryParams)

  useEffect(() => {
    if (data?.data) {
      // Set form values once data is available
      setValue('state', data?.data?.state_id)
      setValue('district', data?.data?.district_id)
      setValue('points', data?.data?.points)
    }
  }, [data, setValue])

  return (
    <Dialog
      fullWidth
      sx={{
        '.MuiDialog-paper': {
          borderRadius: 4,
        },
      }}
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle
        sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }}
        id='customized-dialog-title'
      >
        View Visiting Card Eligibility Limit Details
        <IconButton
          aria-label='close'
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {isFetching || !isSuccess ? (
          <Box textAlign='center'>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={1}>
            {/* District Field */}
            {/* State Field */}
            <Grid item xs={12} sm={6} mt={2}>
              <SelectField
                label={'State'}
                name={'state'}
                options={stateList?.results}
                loading={!isStateListSuccess && isStateListFetching}
                control={control}
                getOptionLabel={(option) => option.state_name} // Use 'name' as the label
                getOptionValue={(option) => option.id} // Use 'id' as the value
                selectedOption={{
                  id: data?.data?.state_id,
                  state_name: data?.data?.state_name,
                }}
                required
                readOnly
              />
            </Grid>

            {/* District Field */}
            <Grid item xs={12} sm={6} mt={2}>
              <SelectField
                label={'District'}
                name={'district'}
                options={districtList?.results}
                loading={!isDistrictListSuccess && isDistrictListFetching}
                control={control}
                getOptionLabel={(option) => option.district_name} // Use 'name' as the label
                getOptionValue={(option) => option.id} // Use 'id' as the value
                selectedOption={{
                  id: data?.data?.district_id,
                  district_name: data?.data?.district_name,
                }}
                readOnly
              />
            </Grid>

            {/* Points Limit */}
            <Grid item xs={12}>
              <InputField
                name={'points'}
                control={control}
                label={'Points Limit'}
                type='number'
                placeholder='Enter the points limit'
                fullWidth
                readOnly
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ViewVisitingCardEligibilityLimitModal
