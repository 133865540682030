import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ListingResponse } from '../types'
import {
  AddEventResponse,
  DeleteEventPayload,
  DeleteEventResponse,
  EditEventResponse,
  EventQueryPayload,
  EventResponse,
  SingleEventPayload,
  SingleEventResponse,
} from './types'
import { API_ENDPOINTS } from '../../constants/apiendpoints'
import { API_METHODS } from '../../constants/contants'
import { EventApiTags } from './tags'

export const eventApi = createApi({
  reducerPath: 'eventApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: [
    EventApiTags.EVENT_CREATED,
    EventApiTags.EVENT_DELETED,
    EventApiTags.EVENT_MODIFIED,
    EventApiTags.EVENT_SINGLE,
  ],
  endpoints: (builder) => ({
    // Get All Event List
    eventDetails: builder.query<
      ListingResponse<EventResponse[]>,
      EventQueryPayload
    >({
      query: (params: EventQueryPayload) => {
        return {
          url: API_ENDPOINTS.GET_ALL_EVENTS,
          method: API_METHODS.GET,
          params,
        }
      },
      providesTags: [
        EventApiTags.EVENT_CREATED,
        EventApiTags.EVENT_DELETED,
        EventApiTags.EVENT_MODIFIED,
      ],
      transformResponse: (response: ListingResponse<EventResponse[]>) => {
        return response
      },
    }),

    // Add Event
    addEvent: builder.mutation<AddEventResponse, FormData>({
      query: (params: FormData) => ({
        url: API_ENDPOINTS.ADD_EVENT,
        method: API_METHODS.POST,
        body: params,
      }),
      invalidatesTags: [EventApiTags.EVENT_CREATED],
      transformResponse: (response: AddEventResponse) => {
        return response
      },
    }),

    // Delete Event
    deleteEvent: builder.mutation<DeleteEventResponse, DeleteEventPayload>({
      query: (params: DeleteEventPayload) => ({
        url: API_ENDPOINTS.DELETE_EVENT,
        method: API_METHODS.DELETE,
        params: params,
      }),
      invalidatesTags: [EventApiTags.EVENT_DELETED],
      transformResponse: (response: DeleteEventResponse) => {
        return response
      },
    }),

    // Edit Event
    editEvent: builder.mutation<
      EditEventResponse,
      { id: number; formData: FormData }
    >({
      query: ({ id, formData }) => ({
        url: API_ENDPOINTS.UPDATE_EVENT, // Assuming the ID is part of the URL
        method: API_METHODS.POST,
        body: formData,
        params: {
          id,
        },
      }),
      invalidatesTags: [EventApiTags.EVENT_MODIFIED, EventApiTags.EVENT_SINGLE],
    }),

    // Single Event
    getSingleEvent: builder.query<SingleEventResponse, SingleEventPayload>({
      query: (params: SingleEventPayload) => ({
        url: API_ENDPOINTS.GET_EVENT_BY_ID, // The ID is part of the URL
        method: API_METHODS.GET,
        params: params,
      }),
      providesTags: [EventApiTags.EVENT_SINGLE],
      transformResponse: (response: SingleEventResponse) => {
        return response
      },
    }),
  }),
})

export const {
  useAddEventMutation,
  useDeleteEventMutation,
  useEditEventMutation,
  useEventDetailsQuery,
  useGetSingleEventQuery,
} = eventApi
