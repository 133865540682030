import React, { FC, useState } from 'react'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Controller } from 'react-hook-form'
import { InputFieldProps } from './types'

const InputField: FC<InputFieldProps> = ({
  name,
  control,
  type = 'text',
  label,
  placeholder = '',
  required = false,
  autoFocus = false,
  fullWidth = false,
  disabled = false,
  readOnly = false, // Destructured readOnly prop
  errors = false,
  helperText = '',
  multiline = false, // New multiline prop
  rows = 3, // Default rows for textarea
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required }}
      render={({ field }) => (
        <TextField
          id={field.name}
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          type={type === 'password' && showPassword ? 'text' : type}
          label={label}
          placeholder={placeholder}
          autoFocus={autoFocus}
          fullWidth={fullWidth}
          disabled={disabled}
          multiline={multiline} // Enable multiline if the prop is true
          rows={multiline ? rows : undefined} // Set rows if multiline
          InputProps={{
            endAdornment:
              type === 'password' && !multiline ? (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ) : undefined,
            readOnly, // Pass readOnly to InputProps
          }}
          error={errors}
          helperText={helperText}
          margin='normal'
          variant='outlined'
          sx={{
            borderRadius: '10px',
            '& .MuiInputBase-root': {
              borderRadius: '10px',
            },
            '& .MuiOutlinedInput-root': {
              backgroundColor: 'rgb(248, 250, 252)', // Ensure the background color applies inside the input
            },
          }}
        />
      )}
    />
  )
}

export default InputField
