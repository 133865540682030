import HomeIcon from '@mui/icons-material/Home'
import routeUrls from '../../constants/routeUrls'
import { TabItem } from '../../components/TabPanelSwitcher/types'
import { Box } from '@mui/material'
import TabPanelSwitcher from '../../components/TabPanelSwitcher'
import TopBar from '../../components/TopBar'
import ApprovedInvitation from './ApprovedInvitation'
import PendingInvitation from './PendingInvitation'
import DeclinedInvitation from './DeclinedInvitation'

const RedeemPointsManagement = () => {

  // BreadCrumbsItems
  const breadcrumbsItems = [
    {
      to: routeUrls.DASHBOARD,
      text: 'Home',
      icon: <HomeIcon sx={{ mr: 0.5 }} fontSize='inherit' />,
    },
    { text: 'Redeem Points' },
  ]

  // Tabs List
  const tabsList: TabItem[] = [
    {
      label: 'Approved Invitation',
      value: '1',
      Component: ApprovedInvitation,
    },
    {
      label: 'Pending Invitation',
      value: '2',
      Component: PendingInvitation,
    },
    {
      label: 'Declined Invitation',
      value: '3',
      Component: DeclinedInvitation,
    },
  ]
  return (
    <>
      <TopBar
        title='Redeem Points Management'
        breadcrumbsItems={breadcrumbsItems}
      />

      {/* Table */}
      <Box mt={2}>
        <TabPanelSwitcher tabs={tabsList} fullWidth />
      </Box>
    </>
  )
}

export default RedeemPointsManagement
