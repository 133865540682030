import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useRef, useState } from 'react'
import { AddModalForm, AddModalProps } from './types'
import { addModalValidationSchema } from './validation'
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../../utils/toastMessage'
import {
  useAddQRCodeMutation,
  useGetQRCodeTokenQuery,
} from '../../../api/qrCodeApi'

const useAddModal = ({ openModal, handleCloseModal }: AddModalProps) => {
  const [addQRCode, { isLoading }] = useAddQRCodeMutation()
  const [qrCodeImage, setQRCodeImage] = useState<File | null>(null)
  const qrCodeRef = useRef<SVGSVGElement>(null) // Ref for QR Code

  // Fetching QRCode Token Data
  const {
    data: qrCodeTokenData,
    isSuccess: isQRCodeTokenSuccess,
    isFetching: isQRCodeTokenFetching,
    refetch: refetchQRCodeToken,
  } = useGetQRCodeTokenQuery(undefined, {
    skip: !openModal, // Skip fetching if modal is not open
  })

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm<AddModalForm>({
    defaultValues: {
      points: undefined,
      token: '',
    },
    resolver: yupResolver(addModalValidationSchema),
  })

  // Convert QRCode SVG to PNG and store in qrCodeImage state
  const generateQRCodeImage = () => {
    const svg = qrCodeRef.current
    if (svg) {
      const svgData = new XMLSerializer().serializeToString(svg)
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const img = new Image()

      img.onload = () => {
        canvas.width = img.width
        canvas.height = img.height
        ctx?.drawImage(img, 0, 0)
        canvas.toBlob((blob) => {
          if (blob) {
            const qrCodeFile = new File([blob], 'qrcode.png', {
              type: 'image/png',
            })
            setQRCodeImage(qrCodeFile)
          }
        })
      }
      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`
    }
  }

  // Function to download the QR code image
  const downloadQRCodeImage = (canvas: HTMLCanvasElement) => {
    const link = document.createElement('a')
    link.href = canvas.toDataURL('image/png')
    link.download = qrCodeTokenData?.token + '.png' || 'qrcode.png'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  // Fetch a new QR code token whenever the modal opens
  useEffect(() => {
    if (openModal) {
      refetchQRCodeToken() // Refetch token when modal opens
    }
  }, [openModal, refetchQRCodeToken])

  // Update form with the fetched token once the API call succeeds
  useEffect(() => {
    if (isQRCodeTokenSuccess && qrCodeTokenData?.token) {
      setValue('token', qrCodeTokenData.token)
      generateQRCodeImage() // Generate QR code and trigger image download
    }
  }, [qrCodeTokenData, isQRCodeTokenSuccess, setValue])

  const onSubmit: SubmitHandler<AddModalForm> = async (data) => {
    if (!qrCodeImage) {
      return
    }

    const formData = new FormData()
    formData.append('qr_image', qrCodeImage) // Add QR Code image to FormData
    formData.append('points', data.points.toString())
    formData.append('token', data.token.toString())

    addQRCode(formData)
      .unwrap()
      .then((data) => {
        if (data.success) {
          toastSuccessMessage('QR Code Added Successfully')

          // Trigger the download after successful submission
          if (qrCodeRef.current) {
            const svg = qrCodeRef.current
            const svgData = new XMLSerializer().serializeToString(svg)
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')
            const img = new Image()

            img.onload = () => {
              canvas.width = img.width
              canvas.height = img.height
              ctx?.drawImage(img, 0, 0)
              downloadQRCodeImage(canvas) // Download the image
            }
            img.src = `data:image/svg+xml;base64,${btoa(svgData)}`
          }

          handleCloseModal()
        } else {
          toastErrorMessage(data.message || 'Unable to Add QR Code')
        }
      })
      .catch((error) => {
        toastErrorMessage(error.data?.message || 'Unable to Add QR Code')
      })
  }

  return {
    control,
    register,
    errors,
    onSubmit,
    handleSubmit,
    isLoading,
    token: qrCodeTokenData?.token,
    isDataLoading: isQRCodeTokenFetching && !isQRCodeTokenSuccess,
    qrCodeRef,
    reset,
  }
}

export default useAddModal
