import { FC, useEffect } from 'react'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useForm } from 'react-hook-form'
import InputField from '../../../../components/InputField'
import {
  ViewVisitingCardRecordsModalProps,
  VisitingCardRecordsForm,
} from './types'
import { useGetSingleVisitingCardRecordsQuery } from '../../../../api/visitingCardRecords'

const ViewVisitingCardRecordsModal: FC<ViewVisitingCardRecordsModalProps> = ({
  id,
  openModal,
  handleCloseModal,
}) => {
  // Fetching Visiting Card Records Data
  const { data, isSuccess, isFetching } = useGetSingleVisitingCardRecordsQuery(
    {
      id: id,
    },
    {
      skip: !openModal, // Skip the query if the modal is not open
    },
  )

  const { control, setValue } = useForm<VisitingCardRecordsForm>({
    defaultValues: {
      fullName: '',
      mobileNumber: '',
      address: '',
      language: '',
    },
  })

  useEffect(() => {
    if (data?.data) {
      setValue('language', data.data.language)
      setValue('fullName', data.data.user_name)
      setValue('mobileNumber', data.data.mobile_number)
      setValue('address', data.data.address)
    }
  }, [data, setValue])

  return (
    <Dialog
      fullWidth
      sx={{
        '.MuiDialog-paper': {
          borderRadius: 4,
        },
      }}
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle
        sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }}
        id='customized-dialog-title'
      >
        View Visiting Card Records Details
        <IconButton
          aria-label='close'
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {isFetching || !isSuccess ? (
          <Box textAlign='center'>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={1}>
            {/* Language Field */}
            <Grid item xs={12} sm={6}>
              <InputField
                name={'language'}
                control={control}
                label={'Language'}
                placeholder='Enter the Language'
                fullWidth
                readOnly
              />
            </Grid>

            {/* Full Name */}
            <Grid item xs={12} sm={6}>
              <InputField
                name={'fullName'}
                control={control}
                label={'Full Name'}
                placeholder='Enter the full name'
                fullWidth
                readOnly
              />
            </Grid>

            {/* mobile number */}
            <Grid item xs={12}>
              <InputField
                name={'mobileNumber'}
                control={control}
                label={'Mobile Number'}
                placeholder='Enter the Mobile Number'
                fullWidth
                readOnly
              />
            </Grid>

            {/* Address */}
            <Grid item xs={12}>
              <InputField
                name={'address'}
                control={control}
                label={'Address'}
                placeholder='Enter the address'
                multiline
                fullWidth
                readOnly
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ViewVisitingCardRecordsModal
