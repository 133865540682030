import { Grid } from '@mui/material'
import DashboardCard from '../../components/DashboardCard'
import useDashboard from './useDashboard'
import { DashboardProps } from './types'

const Dashboard = () => {
  const { dashboardList } = useDashboard()
  return (
    <>
      <Grid container spacing={2}>
        {dashboardList.map((data: DashboardProps, index: number) => {
          const Icon = data?.Icon
          return (
            <Grid item xs={data?.xs} lg={data?.lg} key={index}>
              <DashboardCard
                title={data?.title}
                color={data?.color}
                count={data?.count}
                icon={<Icon sx={{ opacity: 0.7, fontSize: '80px' }} />}
              />
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

export default Dashboard
