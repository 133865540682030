import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ListingResponse } from '../types'
import { GiftApiTags } from './tags'
import {
  AddGiftResponse,
  DeleteGiftPayload,
  DeleteGiftResponse,
  GiftQueryPayload,
  GiftResponse,
  EditGiftPayload,
  EditGiftResponse,
  SingleGiftPayload,
  SingleGiftResponse,
} from './types'
import { API_ENDPOINTS } from '../../constants/apiendpoints'
import { API_METHODS } from '../../constants/contants'

export const giftApi = createApi({
  reducerPath: 'giftApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: [
    GiftApiTags.GIFT_SINGLE,
    GiftApiTags.GIFT_CREATED,
    GiftApiTags.GIFT_DELETED,
    GiftApiTags.GIFT_MODIFIED,
  ],
  endpoints: (builder) => ({
    // Get All Gift List
    giftDetails: builder.query<
      ListingResponse<GiftResponse[]>,
      GiftQueryPayload
    >({
      query: (params: GiftQueryPayload) => {
        return {
          url: API_ENDPOINTS.GET_ALL_GIFTS,
          method: API_METHODS.GET,
          params,
        }
      },
      providesTags: [
        GiftApiTags.GIFT_CREATED,
        GiftApiTags.GIFT_DELETED,
        GiftApiTags.GIFT_MODIFIED,
      ],
      transformResponse: (response: ListingResponse<GiftResponse[]>) => {
        return response
      },
    }),

    // Add Gift
    addGift: builder.mutation<AddGiftResponse, FormData>({
      query: (params: FormData) => ({
        url: API_ENDPOINTS.ADD_GIFT,
        method: API_METHODS.POST,
        body: params,
      }),
      invalidatesTags: [GiftApiTags.GIFT_CREATED],
      transformResponse: (response: AddGiftResponse) => {
        return response
      },
    }),

    // Delete Gift
    deleteGift: builder.mutation<DeleteGiftResponse, DeleteGiftPayload>({
      query: (params: DeleteGiftPayload) => ({
        url: API_ENDPOINTS.DELETE_GIFT,
        method: API_METHODS.DELETE,
        params: params,
      }),
      invalidatesTags: [GiftApiTags.GIFT_DELETED],
      transformResponse: (response: DeleteGiftResponse) => {
        return response
      },
    }),

    // Edit Gift
    editGift: builder.mutation<
      EditGiftResponse,
      { id: number; formData: FormData }
    >({
      query: ({ id, formData }) => ({
        url: API_ENDPOINTS.UPDATE_GIFT, // Assuming the ID is part of the URL
        method: API_METHODS.POST,
        body: formData,
        params: {
          id,
        },
      }),
      invalidatesTags: [GiftApiTags.GIFT_MODIFIED, GiftApiTags.GIFT_SINGLE],
    }),

    // Single Gift
    getSingleGift: builder.query<SingleGiftResponse, SingleGiftPayload>({
      query: (params: SingleGiftPayload) => ({
        url: API_ENDPOINTS.GET_GIFT_BY_ID, // The ID is part of the URL
        method: API_METHODS.GET,
        params: params,
      }),
      providesTags: [GiftApiTags.GIFT_SINGLE],
      transformResponse: (response: SingleGiftResponse) => {
        return response
      },
    }),
  }),
})

export const {
  useAddGiftMutation,
  useDeleteGiftMutation,
  useGiftDetailsQuery,
  useEditGiftMutation,
  useGetSingleGiftQuery,
} = giftApi
