import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Controller } from 'react-hook-form'
import { FC } from 'react'
import { DateTimeFieldProps } from './types'
import dayjs from 'dayjs'

const DateTimeField: FC<DateTimeFieldProps> = ({
  name,
  control,
  label,
  type = 'date',
  disablePast = false,
  disableFuture = false,
  defaultValue = null,
  required = false,
  errors = false,
  helperText = '',
  readOnly = false,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required: required }}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {type === 'date' && (
            <DatePicker
              sx={{
                borderRadius: '10px',
                '& .MuiInputBase-root': {
                  borderRadius: '10px',
                },
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'rgb(248, 250, 252)', // Ensure the background color applies inside the input
                },
                width: '100%',
              }}
              label={label}
              value={field.value ? dayjs(field.value) : null}
              readOnly={readOnly}
              disablePast={disablePast}
              disableFuture={disableFuture}
              views={['year', 'month', 'day']}
              onChange={(date) => field.onChange(date)}
              slotProps={{
                textField: {
                  error: errors,
                  helperText: helperText,
                  // required: required,
                },
              }}
            />
          )}
          {type === 'time' && (
            <TimePicker
              views={['hours', 'minutes', 'seconds']}
              sx={{
                borderRadius: '10px',
                '& .MuiInputBase-root': {
                  borderRadius: '10px',
                },
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'rgb(248, 250, 252)', // Ensure the background color applies inside the input
                },
                width: '100%',
              }}
              label={label}
              value={
                field.value
                  ? dayjs(field.value, 'HH:mm:ss').isValid()
                    ? dayjs(field.value, 'HH:mm:ss')
                    : null
                  : null
              }
              disablePast={disablePast}
              disableFuture={disableFuture}
              onChange={(time) => field.onChange(time)}
              readOnly={readOnly}
              slotProps={{
                textField: {
                  error: errors,
                  helperText: helperText,
                  // required: required,
                },
              }}
            />
          )}
          {type === 'datetime' && (
            <DateTimePicker
              sx={{
                borderRadius: '10px',
                '& .MuiInputBase-root': {
                  borderRadius: '10px',
                },
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'rgb(248, 250, 252)', // Ensure the background color applies inside the input
                },
                width: '100%',
              }}
              label={label}
              value={field.value ? dayjs(field.value) : null}
              disablePast={disablePast}
              disableFuture={disableFuture}
              readOnly={readOnly}
              views={['year', 'month', 'day', 'hours', 'minutes']}
              onChange={(dateTime) => field.onChange(dateTime)}
              slotProps={{
                textField: {
                  error: errors,
                  helperText: helperText,
                  // required: required,
                },
              }}
            />
          )}
        </LocalizationProvider>
      )}
    />
  )
}

export default DateTimeField
