import * as yup from 'yup'

export const editModalValidationSchema = yup.object().shape({
  district: yup.number().required('District is required'),
  state: yup.number().required('State is required'),
  points: yup
    .number()
    .typeError('Please enter a valid number')
    .required('Points are required')
    .min(1, 'Points must be greater than 0'),
})
