import HomeIcon from '@mui/icons-material/Home'

import { Box, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { APP_CONFIG } from '../../../constants/contants'
import DataTable from './DataTable'
import { setListingQueryParams } from '../../../store/slice/commonSlice'
import { useUserReportDetailsQuery } from '../../../api/reportsApi'
import { DateRange } from '@mui/x-date-pickers-pro'
import dayjs, { Dayjs } from 'dayjs'
import TopBar from '../../../components/TopBar'
import routeUrls from '../../../constants/routeUrls'
import { HeaderType } from './DataTable/types'

const UsersReports = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [sortBy, setSortBy] = useState<'asc' | 'desc'>('asc')

  // BreadCrumbsItems
  const breadcrumbsItems = [
    {
      to: routeUrls.DASHBOARD,
      text: 'Home',
      icon: <HomeIcon sx={{ mr: 0.5 }} fontSize='inherit' />,
    },
    { text: 'User Reports' },
  ]

  // Listing Query Params
  const listingQueryParams = useSelector(
    (state: RootState) => state.commonReducer.listingQueryParams,
  )

  const { userReports: userReportsQueryParams } = listingQueryParams

  // Page
  const [page, setPage] = useState(APP_CONFIG.DEFAULT_PAGE)
  const [pageSize, setPageSize] = useState(APP_CONFIG.DEFAULT_PAGE_SIZE)

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage + 1)
  }

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(APP_CONFIG.DEFAULT_PAGE)
  }

  // Search Value
  const [search, setSearch] = useState('')

  const handleSearchValue = (value: string) => {
    setSearch(value)
  }

  // Date Range Value
  const [dateRangeValue, setDateRangeValue] = useState<DateRange<Dayjs>>([
    null,
    null,
  ])

  const handleDateRangeValue = (newValue: DateRange<Dayjs>) => {
    setDateRangeValue(newValue)
  }

  const { data, isFetching, isSuccess } = useUserReportDetailsQuery(
    userReportsQueryParams,
  )

  // Clear Filters
  const handleClearFilters = () => {
    setDateRangeValue([null, null])
  }

  useEffect(() => {
    // Extract the start and end date in 'YYYY-MM-DD' format
    const startDate = dateRangeValue[0]
      ? dayjs(dateRangeValue[0]).format('YYYY-MM-DD')
      : undefined
    const endDate = dateRangeValue[1]
      ? dayjs(dateRangeValue[1]).format('YYYY-MM-DD')
      : undefined

    dispatch(
      setListingQueryParams({
        ...listingQueryParams,
        userReports: {
          q: search,
          sort_order: sortBy,
          page: page,
          page_size: pageSize,
          start_date: startDate, // Pass start date
          end_date: endDate, // Pass end date
        },
      }),
    )
  }, [search, sortBy, page, pageSize, dateRangeValue])

  const [headers, setHeaders] = useState<HeaderType[]>([
    {
      id: 1,
      name: '#',
    },
    {
      id: 2,
      name: 'Full Name',
    },
    {
      id: 3,
      name: 'State',
    },
    {
      id: 4,
      name: 'District',
    },
    {
      id: 5,
      name: 'Points',
    },
  ])

  const handleSortByPoints = () => {
    setSortBy(sortBy === 'asc' ? 'desc' : 'asc')
  }

  return (
    <>
      <TopBar title='User Reports' breadcrumbsItems={breadcrumbsItems} />

      {/* Table */}
      <Box mt={2}>
        <DataTable
          headers={headers}
          action={
            <Button
              variant='contained'
              onClick={handleClearFilters}
              sx={{ textTransform: 'none', fontWeight: 600 }}
            >
              Clear Filter
            </Button>
          }
          data={data?.results}
          totalPages={data?.count}
          page={page}
          rowsPerPage={pageSize}
          handlePageChange={handlePageChange}
          handleSearchValue={handleSearchValue}
          handleChangeRowsPerPage={handleChangePageSize}
          dateRangeValue={dateRangeValue}
          handleDateRangeValue={handleDateRangeValue}
          isSuccess={isSuccess && !isFetching}
          sortBy={sortBy}
          handleSortByPoints={handleSortByPoints}
        />
      </Box>
    </>
  )
}

export default UsersReports
