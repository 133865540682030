import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ListingResponse } from '../../types'
import {
  RedeemPointsPendingPayload,
  RedeemPointsPendingResponse,
  SingleRedeemPointsPendingPayload,
  SingleRedeemPointsPendingResponse,
} from './types'
import { API_METHODS } from '../../../constants/contants'
import { API_ENDPOINTS } from '../../../constants/apiendpoints'
import { RedeemPointsPendingApiTags } from './tags'

export const redeemPointsPendingApi = createApi({
  reducerPath: 'redeemPointsPendingApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: [
    RedeemPointsPendingApiTags.REDEEM_POINTS_Pending_SINGLE,
    RedeemPointsPendingApiTags.REDEEM_POINTS_Pending_MODIFIED,
  ],
  endpoints: (builder) => ({
    // Get All Rededem Points Pending
    redeemPointsPendingListDetails: builder.query<
      ListingResponse<RedeemPointsPendingResponse[]>,
      RedeemPointsPendingPayload
    >({
      query: (params: RedeemPointsPendingPayload) => {
        return {
          url: API_ENDPOINTS.GET_ALL_REDEEM_POINTS_PENDING_LIST,
          method: API_METHODS.GET,
          params,
        }
      },
      providesTags: [RedeemPointsPendingApiTags.REDEEM_POINTS_Pending_MODIFIED],
      transformResponse: (
        response: ListingResponse<RedeemPointsPendingResponse[]>,
      ) => {
        return response
      },
    }),

    // single redeem poins Pending details
    getSingleRedeemPointsPendingDetails: builder.query<
      SingleRedeemPointsPendingResponse,
      SingleRedeemPointsPendingPayload
    >({
      query: (params: SingleRedeemPointsPendingPayload) => ({
        url: API_ENDPOINTS.GET_SINGLE_REDEEM_POINTS_PENDING_DETAILS, // The ID is part of the URL
        method: API_METHODS.GET,
        params: params,
      }),
      providesTags: [RedeemPointsPendingApiTags.REDEEM_POINTS_Pending_SINGLE],
      transformResponse: (response: SingleRedeemPointsPendingResponse) => {
        return response
      },
    }),
  }),
})

export const {
  useGetSingleRedeemPointsPendingDetailsQuery,
  useRedeemPointsPendingListDetailsQuery,
} = redeemPointsPendingApi
