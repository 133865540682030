import { FC } from 'react'
import { Controller, Control } from 'react-hook-form'
import { styled } from '@mui/material/styles'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  RadioProps,
  FormHelperText,
} from '@mui/material'
import { RadioFieldInputProps } from './types'

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}))

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
})

const BpRadio = (props: RadioProps) => {
  return (
    <Radio
      disableRipple
      color='default'
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  )
}

const RadioFieldInput: FC<RadioFieldInputProps> = ({
  name,
  control,
  label,
  options,
  defaultValue,
  direction = 'column',
  fullWidth = false,
  errors = false,
  helperText = '',
  readOnly = false,
}) => {
  return (
    <FormControl fullWidth={fullWidth} error={errors}>
      <FormLabel
        sx={{
          '&.Mui-focused': {
            color: 'rgb(105, 117, 134)',
          },
        }}
        id={`${name}-label`}
      >
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <RadioGroup
            {...field}
            aria-labelledby={`${name}-label`}
            name={name}
            row={direction === 'row'}
            sx={{
              display: 'flex',
              flexDirection: direction,
              justifyContent:
                direction === 'row' ? 'space-between' : 'flex-start',
            }}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<BpRadio />}
                label={option.label}
                sx={{
                  pointerEvents: readOnly ? 'none' : 'auto',
                }}
              />
            ))}
          </RadioGroup>
        )}
      />
      {errors && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default RadioFieldInput
