import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import useAddModal from './useAddModal'
import InputField from '../../../components/InputField'
import ButtonField from '../../../components/ButtonField'
import { useEffect } from 'react'
import FileInput from '../../../components/FileInput'
import { AddProductModalProps } from './types'

const AddProductModal: React.FC<AddProductModalProps> = ({
  openModal,
  handleCloseModal,
}) => {
  const {
    isLoading,
    control,
    handleSubmit,
    onSubmit,
    errors,
    reset,
    productImage,
    handleProductImage,
  } = useAddModal({
    handleCloseModal,
  })

  // reset data when open the modal
  useEffect(() => {
    reset()
  }, [openModal])

  return (
    <Dialog
      fullWidth
      sx={{
        '.MuiDialog-paper': {
          borderRadius: 4,
        },
      }}
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      component='form'
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* Title */}
      <DialogTitle sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }} id='add-product'>
        Add Product
      </DialogTitle>

      {/* Dialog box close button */}
      <IconButton
        onClick={handleCloseModal}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        <Grid container spacing={1}>
          {/* Product Image */}
          <Grid item xs={12}>
            <FileInput
              value={productImage}
              error={errors.isProductImage ? errors.isProductImage.message : ''}
              onChange={handleProductImage}
            />
          </Grid>

          {/* Product Name Field */}
          <Grid item xs={12} sm={6}>
            <InputField
              name={'productName'}
              control={control}
              label={'Product Name'}
              placeholder='Enter the product name'
              fullWidth
              errors={!!errors.productName}
              helperText={errors.productName ? errors.productName.message : ''}
              required
            />
          </Grid>

          {/* Packing Field */}
          <Grid item xs={12} sm={6}>
            <InputField
              name={'packing'}
              control={control}
              label={'Packing in Kg'}
              placeholder='Enter the packing(kg)'
              type='number'
              fullWidth
              required
              errors={!!errors.packing}
              helperText={errors.packing ? errors.packing.message : ''}
            />
          </Grid>

          {/* Points */}
          <Grid item xs={12}>
            <InputField
              name={'points'}
              control={control}
              label={'Points'}
              type={'number'}
              placeholder='Enter the points'
              fullWidth
              required
              errors={!!errors.points}
              helperText={errors.points ? errors.points.message : ''}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <ButtonField
          variant='contained'
          onClick={handleCloseModal}
          buttonText={'Cancel'}
          sx={{
            backgroundColor: 'primary.main',
          }}
        />

        <ButtonField
          type='submit'
          loading={isLoading}
          variant='contained'
          buttonText={'Save'}
          sx={{
            backgroundColor: 'ternary.main',
            '&:hover': {
              backgroundColor: 'ternary.dark',
            },
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export default AddProductModal
