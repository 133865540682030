import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { AddModalForm, AddModalProps } from './types'
import { addModalValidationSchema } from './validation'
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../../utils/toastMessage'
import { useAddDistrictAdminMutation } from '../../../api/districtAdminApi'
import { AddDistrictAdminResponse } from '../../../api/districtAdminApi/types'
import { SELECT_ID_TYPE } from '../../../constants/contants'
import { formatDateToYYYYMMDD } from '../../../utils/common'

const useAddModal = ({ handleCloseModal }: AddModalProps) => {
  const [addDistrictAdmin, { isLoading }] = useAddDistrictAdminMutation()
  const [profileImage, setProfileImage] = useState<File | null>()
  const [aadharPanImage, setAadharPanImage] = useState<File | null>()

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm<AddModalForm>({
    defaultValues: {
      isProfileImage: false,
      fullName: '',
      mobileNumber: '',
      password: '',
      confirmPassword: '',
      dob: '',
      district: undefined,
      state: undefined,
      selectId: '',
      isAadharPanImage: false,
    },
    resolver: yupResolver(addModalValidationSchema),
  })

  const selectId = watch('selectId')

  // Handle Profile Image Field
  const handleProfileImage = (file: File) => {
    const reader = new FileReader()

    setProfileImage(file)
    setValue('isProfileImage', true, {
      shouldValidate: true,
    })

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  // Handle Aadhar/Pan Image Field
  const handleAadharPanImage = (file: File) => {
    const reader = new FileReader()

    setAadharPanImage(file)
    setValue('isAadharPanImage', true, {
      shouldValidate: true,
    })

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  const resetForm = () => {
    setProfileImage(null)
    setAadharPanImage(null)
    reset()
  }

  useEffect(() => {
    setAadharPanImage(null)
    setValue('isAadharPanImage', false)
  }, [selectId])

  const onSubmit: SubmitHandler<AddModalForm> = async (data) => {
    if (!profileImage || !aadharPanImage) {
      return
    }
    const formData = new FormData()

    formData.append('full_name', data?.fullName)
    formData.append('profile_pic', profileImage)
    formData.append('mobile_number', data?.mobileNumber)
    formData.append('password', data?.password)
    formData.append('date_of_birth', formatDateToYYYYMMDD(data?.dob))
    formData.append('state_id', data.state.toString())
    formData.append('district_id', data?.district.toString())
    if (data?.selectId === SELECT_ID_TYPE.AADHAR) {
      formData.append('aadhaar_image', aadharPanImage)
    } else {
      formData.append('pan_image', aadharPanImage)
    }

    addDistrictAdmin(formData)
      .unwrap()
      .then((data: AddDistrictAdminResponse) => {
        if (data?.success) {
          toastSuccessMessage('District Admin Added Successfully')
          handleCloseModal()
        } else {
          toastErrorMessage(data?.message || 'Unable to Add District Admin')
        }
      })
      .catch((error) => {
        toastErrorMessage(
          error?.data?.message || 'Unable to Add District Admin',
        )
      })
  }

  return {
    control,
    register,
    errors,
    reset: resetForm,
    onSubmit,
    handleSubmit,
    isLoading,
    selectId,
    profileImage,
    aadharPanImage,
    handleProfileImage,
    handleAadharPanImage,
    watch,
  }
}

export default useAddModal
