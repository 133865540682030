import {
  Box,
  CSSObject,
  SwipeableDrawer,
  Theme,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import MenuList from './MenuList'
import { setModalsData, TModalState } from '../../../store/slice/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import LogoSection from '../Header/LogoSection'
import { drawerWidth } from '../../../constants/contants'

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  overflowY: 'auto',
  maxHeight: '100%',
  [theme.breakpoints.up('md')]: {
    maxHeight: '85vh',
  },
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  overflowY: 'auto',
  maxHeight: '100%',
  [theme.breakpoints.up('md')]: {
    maxHeight: '85vh',
  },
  width: `calc(${theme.spacing(8)} + 10px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 10px)`,
  },
})

const Drawer = styled(SwipeableDrawer)<{
  open?: boolean
}>(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',

  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

const Sidebar = () => {
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const dispatch = useDispatch()
  const modalsState: TModalState = useSelector(
    (state: any) => state.commonReducer.modals,
  )
  const handleOpenSidebar = () => {
    dispatch(setModalsData({ ...modalsState, showSidebar: true }))
  }

  const handleCloseSidebar = () => {
    dispatch(setModalsData({ ...modalsState, showSidebar: false }))
  }
  return (
    <>
      <Box
        component='nav'
        sx={{ flexShrink: { md: 0 }, width: 'auto' }}
        aria-label='sidebar'
      >
        <Drawer
          onOpen={handleOpenSidebar}
          onClose={handleCloseSidebar}
          variant={matchUpMd ? 'permanent' : 'temporary'}
          open={modalsState.showSidebar}
          anchor='left'
          sx={{
            '& .MuiDrawer-paper': {
              zIndex: 1,
              borderRight: 'none',
              '&::-webkit-scrollbar': {
                width: '0.3em',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#999',
                borderRadius: '3px',
              },
              [theme.breakpoints.up('md')]: {
                top: '80px',
              },
            },
          }}
        >
          {/* Logo Section */}
          <Box
            sx={{
              display: {
                xs: 'flex',
                md: 'none',
              },
            }}
            padding={'15px'}
          >
            <LogoSection show={true} />
          </Box>

          <MenuList />
        </Drawer>
      </Box>
    </>
  )
}

export default Sidebar
