import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ListingResponse } from '../../types'
import { API_METHODS } from '../../../constants/contants'
import { API_ENDPOINTS } from '../../../constants/apiendpoints'
import { RedeemPointsStatusApiTags } from './tags'
import { UpdateRedeemPointsStatusResponse } from './types'
import { redeemPointsPendingApi } from '../redeemPointsPendingApi'
import { RedeemPointsPendingApiTags } from '../redeemPointsPendingApi/tags'
import { redeemPointsApprovedApi } from '../redeemPointsApprovedApi'
import { RedeemPointsApprovedApiTags } from '../redeemPointsApprovedApi/tags'
import { redeemPointsDeclinedApi } from '../redeemPointsDeclinedApi'
import { RedeemPointsDeclinedApiTags } from '../redeemPointsDeclinedApi/tags'

export const redeemPointsStatusApi = createApi({
  reducerPath: 'redeemPointsStatusApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: [
    RedeemPointsStatusApiTags.REDEEM_POINTS_STATUS_MODIFIED,
    RedeemPointsApprovedApiTags.REDEEM_POINTS_APPROVED_MODIFIED,
    RedeemPointsPendingApiTags.REDEEM_POINTS_Pending_MODIFIED,
    RedeemPointsDeclinedApiTags.REDEEM_POINTS_DECLINED_MODIFIED,
  ],
  endpoints: (builder) => ({
    // Edit Redeem Points
    updateRedeemPointsStatus: builder.mutation<
      UpdateRedeemPointsStatusResponse,
      { id: number; formData: FormData }
    >({
      query: ({ id, formData }) => ({
        url: API_ENDPOINTS.EDIT_REDEEM_POINTS_STATUS, // Assuming the ID is part of the URL
        method: API_METHODS.POST,
        body: formData,
        params: {
          id,
        },
      }),
      invalidatesTags: [
        RedeemPointsStatusApiTags.REDEEM_POINTS_STATUS_MODIFIED,
        RedeemPointsApprovedApiTags.REDEEM_POINTS_APPROVED_MODIFIED,
        RedeemPointsPendingApiTags.REDEEM_POINTS_Pending_MODIFIED,
        RedeemPointsDeclinedApiTags.REDEEM_POINTS_DECLINED_MODIFIED,
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          // Wait for the query to finish
          await queryFulfilled

          // After the query completes successfully, trigger refetch for the approved posts
          dispatch(
            redeemPointsPendingApi.util.invalidateTags([
              RedeemPointsPendingApiTags.REDEEM_POINTS_Pending_MODIFIED,
            ]),
          )
          dispatch(
            redeemPointsApprovedApi.util.invalidateTags([
              RedeemPointsApprovedApiTags.REDEEM_POINTS_APPROVED_MODIFIED,
            ]),
          )
          dispatch(
            redeemPointsDeclinedApi.util.invalidateTags([
              RedeemPointsDeclinedApiTags.REDEEM_POINTS_DECLINED_MODIFIED,
            ]),
          )
        } catch (err) {
          console.error('Error refetching:', err)
        }
      },
    }),
  }),
})

export const { useUpdateRedeemPointsStatusMutation } = redeemPointsStatusApi
