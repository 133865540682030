import { Grid } from '@mui/material'
import InputField from '../../../../../components/InputField'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { RedeemStatusForm } from './types'

const RedeemStatus: FC<any> = ({ data }) => {
  const { control, setValue } = useForm<RedeemStatusForm>({
    defaultValues: {
      status: 'Pending',
    },
  })

  return (
    <>
      <Grid container spacing={1}>
        {/* Status */}
        <Grid item xs={12}>
          <InputField
            name={'status'}
            control={control}
            label={'Status'}
            fullWidth
            readOnly
          />
        </Grid>
      </Grid>
    </>
  )
}

export default RedeemStatus
