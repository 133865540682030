import * as yup from 'yup'

export const addModalValidationSchema = yup.object().shape({
  giftName: yup.string().required('Gift Name is required'),
  points: yup
    .number()
    .typeError('Please type any number')
    .required('Points are required')
    .positive('Points must be a positive number')
    .integer('Points must be an integer'),
  isGiftImage: yup
    .boolean()
    .oneOf([true], 'Gift image must be uploaded')
    .required('Gift image is required'), // Ensure the field is included
})
