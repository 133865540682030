import { Card, CardContent, Grid, Typography } from '@mui/material'
import { dashboardCardsColorList } from '../../constants/contants'
import { DashboardCardProps } from './types'

const DashboardCard: React.FC<DashboardCardProps> = ({
  color,
  subtitle = '',
  title,
  count,
  icon,
}) => {
  const colorValue = dashboardCardsColorList[color]
  return (
    <Card elevation={0} sx={{ backgroundColor: colorValue, borderRadius: 3 }}>
      <CardContent
        sx={{
          color: 'white',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Grid container alignItems='center'>
          <Grid item xs={8}>
            <Typography variant='h6'>{title}</Typography>
            <Typography variant='h5' fontWeight='600' mt={1}>
              {count}
            </Typography>
            {!!subtitle?.length && (
              <Typography variant='h6'>{subtitle}</Typography>
            )}
          </Grid>
          <Grid item xs={4} textAlign='end'>
            {icon}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default DashboardCard
