import { IconBell } from '@tabler/icons-react'
import AvatarButton from '../../../../components/AvatarButton'
import { Badge } from '@mui/material'
import { useNavigate } from 'react-router-dom' // Updated import
import routeUrls from '../../../../constants/routeUrls'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { useNotificationsDetailsQuery } from '../../../../api/notificationsApi'

const Notification = () => {
  const navigate = useNavigate() // Updated hook

  const handleNavigation = () => {
    navigate(routeUrls.NOTIFICATIONS)
  }

  // Listing Query Params
  const listingQueryParams = useSelector(
    (state: RootState) => state.commonReducer.listingQueryParams,
  )

  const { notifications: notificationsQueryParams } = listingQueryParams

  const { data } = useNotificationsDetailsQuery(notificationsQueryParams, {
    pollingInterval: 30000,
  })

  return (
    <>
      <Badge badgeContent={data?.unread_count || 0} color='error'>
        <AvatarButton
          onClick={handleNavigation}
          color='secondary'
          icon={<IconBell width={'20px'} height={'20px'} strokeWidth={'1.5'} />}
        />
      </Badge>
    </>
  )
}

export default Notification
