import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  IconButton,
  Collapse,
  Badge,
  CardActions,
  Box,
  Tooltip,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import MarkAsReadIcon from '@mui/icons-material/MarkChatRead'
import { FC, useState } from 'react'
import { NotificationCardProps } from './types'
import { formatDistanceToNow } from 'date-fns'

const NotificationCard: FC<NotificationCardProps> = ({
  notification_id,
  title,
  description,
  date,
  status,
  handleNotificationMarkRead,
}) => {
  const [expanded, setExpanded] = useState(false)

  const toggleExpand = () => setExpanded(!expanded)

  const handleMarkAsRead = () => {
    handleNotificationMarkRead(notification_id)
  }

  return (
    <Card
      elevation={3}
      sx={{
        mb: 2,
        p: 2,
        borderRadius: 3,
        borderLeft: `6px solid`,
        borderColor: status ? 'grey.500' : 'primary.main',
        transition: 'transform 0.3s, box-shadow 0.3s',
        ':hover': {
          transform: 'scale(1.02)',
          boxShadow: 6,
        },
      }}
    >
      <CardHeader
        title={
          <Badge
            color={status ? 'default' : 'primary'}
            variant='dot'
            overlap='rectangular'
          >
            {title}
          </Badge>
        }
        subheader={
          <Typography variant='body2' color='text.secondary'>
            {formatDistanceToNow(new Date(date), { addSuffix: true })}
          </Typography>
        }
        action={
          <>
            {!status && (
              <Tooltip title='Mark as read' arrow>
                <IconButton
                  onClick={handleMarkAsRead}
                  aria-label='Mark as read'
                >
                  <MarkAsReadIcon color='primary' />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={expanded ? 'Collapse' : 'Expand'} arrow>
              <IconButton onClick={toggleExpand} aria-label='Expand message'>
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Tooltip>
          </>
        }
        titleTypographyProps={{ fontWeight: 'bold', fontSize: '1.1rem' }}
        sx={{ pb: 0 }}
      />

      <CardContent
        sx={{
          pb: 0,
          display: expanded ? 'none' : '',
        }}
      >
        {/* Preview of the description */}
        <Typography
          variant='body2'
          color='text.secondary'
          sx={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: expanded ? 'none' : 2,
            overflow: 'hidden',
            lineHeight: 1.5,
          }}
        >
          {description}
        </Typography>
      </CardContent>

      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent>
          <Typography
            variant='body2'
            sx={{ color: 'text.primary', lineHeight: 1.6 }}
          >
            {description}
          </Typography>
        </CardContent>
      </Collapse>

      <CardActions sx={{ justifyContent: 'space-between', pt: 0 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {expanded && (
            <Typography variant='caption' sx={{ color: 'text.secondary' }}>
              {formatDistanceToNow(new Date(date), { addSuffix: true })}
            </Typography>
          )}
        </Box>
      </CardActions>
    </Card>
  )
}

export default NotificationCard
