import { Box, useMediaQuery, useTheme } from '@mui/material'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import Header from './Header'
import Sidebar from './Sidebar'

const MainLayout = () => {
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <>
      {/* Header Section */}
      <Header />

      <Box display={'flex'}>
        {/* Sidebar Section */}
        <Sidebar />
        <Box
          sx={{
            flexGrow: 1,
            marginTop: '80px',
            minHeight: 'calc(-80px + 100vh)',
            backgroundColor: 'rgb(238, 242, 246)',
            borderRadius: '8px 8px 0px 0px',
            mr: 3,
            ml: matchUpMd ? 0 : 3,
            p: 2,
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </>
  )
}

export default MainLayout
