import { Stack, Box, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'
import { FileInputProps } from './types'

const FileInput: FC<FileInputProps> = ({
  onChange,
  readOnly = false,
  error,
  value,
}) => {
  const [isDragging, setIsDragging] = useState(false)
  const [draggedFile, setDraggedFile] = useState<File | null>(null)
  const [preview, setPreview] = useState<string | null>(null)
  const [isHovered, setIsHovered] = useState(false)
  const [imageHeight, setImageHeight] = useState(200)
  useEffect(() => {
    if (value) {
      setPreview(typeof value === 'string' ? value : URL.createObjectURL(value))
    } else {
      setPreview(null) // Clear preview when value is reset
    }

    // Clean up URL.createObjectURL if used
    return () => {
      if (typeof value !== 'string' && value instanceof File) {
        URL.revokeObjectURL(preview || '')
      }
    }
  }, [value])

  const handleDragOver = (event: React.DragEvent) => {
    if (readOnly) return
    event.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = () => {
    if (readOnly) return
    setIsDragging(false)
  }

  const handleDrop = (event: React.DragEvent) => {
    if (readOnly) return
    event.preventDefault()
    setIsDragging(false)
    const file = event.dataTransfer.files[0]
    if (file) {
      handleFile(file)
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (readOnly) return
    const file = event.target.files?.[0]
    if (file) {
      handleFile(file)
    }
  }

  const handleFile = (file: File) => {
    setDraggedFile(file)
    if (onChange) {
      onChange(file)
    }
    const reader = new FileReader()
    reader.onloadend = () => {
      setPreview(reader.result as string)
      const img = new Image()
      img.src = reader.result as string
      img.onload = () => {
        setImageHeight(300)
      }
    }
    reader.readAsDataURL(file)
  }

  return (
    <Stack display={'flex'} flexDirection={'column'} alignItems={'center'}>
      {!readOnly && (
        <input
          accept='image/*'
          id='image-uploader'
          type='file'
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      )}
      <label
        htmlFor='image-uploader'
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onMouseEnter={() => !readOnly && setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          display: 'inline-block',
          width: '100%', // Full width
          cursor: readOnly ? 'not-allowed' : 'pointer',
        }}
      >
        <Box
          sx={{
            width: '100%', // Full width
            height: imageHeight,
            p: 1,
            borderRadius: '8px',
            border: isDragging ? '1px solid' : '1px dashed',
            borderColor: error ? 'error.main' : 'secondary.main',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            mb: 2,
            transition:
              'height 0.5s ease-in-out, background-color 0.2s, border-color 0.2s',
            overflow: 'hidden',
          }}
        >
          {preview ? (
            <>
              <img
                src={preview}
                alt='Preview'
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'fill',
                }}
              />
              {isHovered && !readOnly && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                ></Box>
              )}
            </>
          ) : (
            <Stack
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              spacing={0.5}
            >
              <PhotoLibraryIcon
                color='info'
                sx={{
                  fontSize: '3rem',
                }}
              />

              <Typography variant='body2' fontWeight={'bold'}>
                Drop your image here, or browse
              </Typography>
              <Typography variant='caption' color={'rgb(105, 117, 134)'}>
                Supports JPG, PNG, JPEG
              </Typography>
            </Stack>
          )}
        </Box>
      </label>
      {error && (
        <Typography variant='body2' color='error' align='center'>
          {error}
        </Typography>
      )}
    </Stack>
  )
}

export default FileInput
