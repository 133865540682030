import { Box } from '@mui/material'
import TopBar from '../../components/TopBar'
import routeUrls from '../../constants/routeUrls'
import HomeIcon from '@mui/icons-material/Home'
import DataTable from './DataTable'
import { useEffect, useState } from 'react'
import { APP_CONFIG } from '../../constants/contants'
import {
  useEditNotificationMutation,
  useNotificationsDetailsQuery,
} from '../../api/notificationsApi'
import { RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { setListingQueryParams } from '../../store/slice/commonSlice'
import { TAuthState } from '../../store/slice/auth/authSlice'
import { EditNotificationResponse } from '../../api/notificationsApi/types'

const Notifications = () => {
  const dispatch = useDispatch()
  const authState: TAuthState = useSelector((state: any) => state.authReducer)

  // BreadCrumbsItems
  const breadcrumbsItems = [
    {
      to: routeUrls.DASHBOARD,
      text: 'Home',
      icon: <HomeIcon sx={{ mr: 0.5 }} fontSize='inherit' />,
    },
    { text: 'Notifications' },
  ]

  // Page
  const [page, setPage] = useState(APP_CONFIG.DEFAULT_PAGE)
  const [pageSize, setPageSize] = useState(APP_CONFIG.DEFAULT_PAGE_SIZE)

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage + 1)
  }

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(APP_CONFIG.DEFAULT_PAGE)
  }

  const [editNotification] = useEditNotificationMutation()

  const handleNotificationMarkRead = (notificationId: string) => {
    const formData = new FormData()
    formData.append('notification_id', notificationId)
    formData.append('is_read', 'true')

    editNotification({ admin_id: authState.id, formData: formData })
      .unwrap()
      .then((data: EditNotificationResponse) => {
        if (data?.success) {
          console.log('notification read successfully')
        } else {
          console.log('unable to read notification')
        }
      })
      .catch((error) => {
        console.log('unable to read notification')
      })
  }

  // Listing Query Params
  const listingQueryParams = useSelector(
    (state: RootState) => state.commonReducer.listingQueryParams,
  )

  const { notifications: notificationsQueryParams } = listingQueryParams

  const { data, isFetching, isSuccess } = useNotificationsDetailsQuery(
    notificationsQueryParams,
  )

  useEffect(() => {
    dispatch(
      setListingQueryParams({
        ...listingQueryParams,
        notifications: {
          admin_id: authState.id,
          page: page,
          page_size: pageSize,
        },
      }),
    )
  }, [page, pageSize])

  return (
    <>
      <TopBar title='Notifications' breadcrumbsItems={breadcrumbsItems} />

      {/* Notification List */}
      <Box mt={2}>
        <DataTable
          headers={[]}
          data={data?.notifications}
          totalPages={data?.count}
          page={page}
          rowsPerPage={pageSize}
          handlePageChange={handlePageChange}
          handleChangeRowsPerPage={handleChangePageSize}
          handleNotificationMarkRead={handleNotificationMarkRead}
          isSuccess={isSuccess && !isFetching}
        />
      </Box>
    </>
  )
}

export default Notifications
