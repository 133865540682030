import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ListingResponse } from '../types'
import {
  AddProductResponse,
  DeleteProductPayload,
  DeleteProductResponse,
  EditProductResponse,
  ProductPayload,
  ProductResponse,
  SingleProductPayload,
  SingleProductResponse,
} from './types'
import { API_METHODS } from '../../constants/contants'
import { API_ENDPOINTS } from '../../constants/apiendpoints'
import { ProductApiTags } from './tags'

export const productsApi = createApi({
  reducerPath: 'productsApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: [
    ProductApiTags.PRODUCT_CREATED,
    ProductApiTags.PRODUCT_DELETED,
    ProductApiTags.PRODUCT_MODIFIED,
    ProductApiTags.PRODUCT_SINGLE,
  ],
  endpoints: (builder) => ({
    // Get All Products
    productsDetails: builder.query<
      ListingResponse<ProductResponse[]>,
      ProductPayload
    >({
      query: (params: ProductPayload) => {
        return {
          url: API_ENDPOINTS.GET_ALL_PRODUCTS,
          method: API_METHODS.GET,
          params,
        }
      },
      providesTags: [
        ProductApiTags.PRODUCT_CREATED,
        ProductApiTags.PRODUCT_DELETED,
        ProductApiTags.PRODUCT_MODIFIED,
      ],
      transformResponse: (response: ListingResponse<ProductResponse[]>) => {
        return response
      },
    }),

    // Add Product
    addProduct: builder.mutation<AddProductResponse, FormData>({
      query: (params: FormData) => ({
        url: API_ENDPOINTS.ADD_PRODUCT,
        method: API_METHODS.POST,
        body: params,
      }),
      invalidatesTags: [ProductApiTags.PRODUCT_CREATED],
      transformResponse: (response: AddProductResponse) => {
        return response
      },
    }),

    // Delete Product
    deleteProduct: builder.mutation<
      DeleteProductResponse,
      DeleteProductPayload
    >({
      query: (params: DeleteProductPayload) => ({
        url: API_ENDPOINTS.DELETE_PRODUCT,
        method: API_METHODS.DELETE,
        params: params,
      }),
      invalidatesTags: [ProductApiTags.PRODUCT_DELETED],
      transformResponse: (response: DeleteProductResponse) => {
        return response
      },
    }),

    // Edit Product
    editProduct: builder.mutation<
      EditProductResponse,
      { id: number; formData: FormData }
    >({
      query: ({ id, formData }) => ({
        url: API_ENDPOINTS.UPDATE_PRODUCT, // Assuming the ID is part of the URL
        method: API_METHODS.POST,
        body: formData,
        params: {
          id,
        },
      }),
      invalidatesTags: [
        ProductApiTags.PRODUCT_MODIFIED,
        ProductApiTags.PRODUCT_SINGLE,
      ],
    }),

    // single product
    getSingleProduct: builder.query<
      SingleProductResponse,
      SingleProductPayload
    >({
      query: (params: SingleProductPayload) => ({
        url: API_ENDPOINTS.GET_PRODUCT_BY_ID, // The ID is part of the URL
        method: API_METHODS.GET,
        params: params,
      }),
      providesTags: [ProductApiTags.PRODUCT_SINGLE],
      transformResponse: (response: SingleProductResponse) => {
        return response
      },
    }),
  }),
})

export const {
  useAddProductMutation,
  useDeleteProductMutation,
  useEditProductMutation,
  useGetSingleProductQuery,
  useProductsDetailsQuery,
} = productsApi
