import { configureStore } from '@reduxjs/toolkit'
import commonSlice from './slice/commonSlice'
import authSlice from './slice/auth/authSlice'
import { districtApi } from '../api/districtApi'
import { districtAdminApi } from '../api/districtAdminApi'
import { giftApi } from '../api/giftsApi'
import { stateApi } from '../api/stateApi'
import { pendingInvitationUsersApi } from '../api/userApi/pendingInvitationUsersApi'
import { approvedUsersApi } from '../api/userApi/approvedUsersApi'
import { productsApi } from '../api/productsApi'
import { redeemPointsApprovedApi } from '../api/redeemPointsApi/redeemPointsApprovedApi'
import { redeemPointsPendingApi } from '../api/redeemPointsApi/redeemPointsPendingApi'
import { redeemPointsStatusApi } from '../api/redeemPointsApi/redeemPointsStatusApi'
import { redeemPointsDeclinedApi } from '../api/redeemPointsApi/redeemPointsDeclinedApi'
import { eventApi } from '../api/eventsApi'
import { visitingCardEligibilityLimitApi } from '../api/visitingCardEligibilityLimit'
import { visitingCardRecordsApi } from '../api/visitingCardRecords'
import { qrCodeApi } from '../api/qrCodeApi'
import { reportsApi } from '../api/reportsApi'
import { notificationsApi } from '../api/notificationsApi'
const store = configureStore({
  reducer: {
    commonReducer: commonSlice,
    authReducer: authSlice,
    [districtApi.reducerPath]: districtApi.reducer,
    [districtAdminApi.reducerPath]: districtAdminApi.reducer,
    [pendingInvitationUsersApi.reducerPath]: pendingInvitationUsersApi.reducer,
    [approvedUsersApi.reducerPath]: approvedUsersApi.reducer,
    [giftApi.reducerPath]: giftApi.reducer,
    [stateApi.reducerPath]: stateApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [redeemPointsApprovedApi.reducerPath]: redeemPointsApprovedApi.reducer,
    [redeemPointsPendingApi.reducerPath]: redeemPointsPendingApi.reducer,
    [redeemPointsDeclinedApi.reducerPath]: redeemPointsDeclinedApi.reducer,
    [redeemPointsStatusApi.reducerPath]: redeemPointsStatusApi.reducer,
    [eventApi.reducerPath]: eventApi.reducer,
    [visitingCardEligibilityLimitApi.reducerPath]:
      visitingCardEligibilityLimitApi.reducer,
    [visitingCardRecordsApi.reducerPath]: visitingCardRecordsApi.reducer,
    [qrCodeApi.reducerPath]: qrCodeApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      districtApi.middleware,
      districtAdminApi.middleware,
      pendingInvitationUsersApi.middleware,
      approvedUsersApi.middleware,
      giftApi.middleware,
      stateApi.middleware,
      productsApi.middleware,
      redeemPointsApprovedApi.middleware,
      redeemPointsDeclinedApi.middleware,
      redeemPointsPendingApi.middleware,
      redeemPointsStatusApi.middleware,
      eventApi.middleware,
      visitingCardEligibilityLimitApi.middleware,
      visitingCardRecordsApi.middleware,
      qrCodeApi.middleware,
      reportsApi.middleware,
      notificationsApi.middleware,
    ),
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export default store
