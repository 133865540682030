import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants/apiendpoints'

export const authlogin = createAsyncThunk(
  'auth/login',
  async (formData: FormData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.LOGIN}`,
        formData,
      )
      return { ...response.data, username: formData.get('email') }
    } catch (error: any) {
      // Return a rejected action with the error message
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message || 'Login Failed')
      }
    }
  },
)
