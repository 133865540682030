import { InputAdornment, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { debounce } from 'lodash'
import { ChangeEvent } from 'react'
import { SearchBarProps } from './types'

export default function SearchBar({ handleSearchValue }: SearchBarProps) {
  const debouncedHandleSearchValue = debounce(handleSearchValue, 1000)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    debouncedHandleSearchValue(value)
  }

  return (
    <>
      <TextField
        id='search-bar'
        placeholder='Search'
        type='search'
        onChange={handleChange}
        sx={{
          m: 1,
          width: '18ch',
          '.MuiInputBase-root': {
            borderRadius: 3,
            background: 'rgb(248, 250, 252)',
          },
          '.MuiInputBase-input': {
            padding: '10px 14px',
            paddingLeft: '5px',
            background: 'rgb(248, 250, 252)',
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon fontSize='small' />
            </InputAdornment>
          ),
        }}
      />
    </>
  )
}
