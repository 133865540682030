import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import useAddModal from './useAddModal'
import InputField from '../../../components/InputField'
import ButtonField from '../../../components/ButtonField'
import { useEffect } from 'react'
import { AddStateModalProps } from './types'

const AddStateModal: React.FC<AddStateModalProps> = ({
  openModal,
  handleCloseModal,
}) => {
  const { isLoading, control, handleSubmit, onSubmit, errors, reset } =
    useAddModal({
      handleCloseModal,
    })

  useEffect(() => {
    reset()
  }, [openModal])

  return (
    <Dialog
      fullWidth
      sx={{
        '.MuiDialog-paper': {
          borderRadius: 4,
        },
      }}
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      component='form'
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* Title */}
      <DialogTitle
        sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }}
        id='customized-dialog-title'
      >
        Add State
      </DialogTitle>

      {/* Dialog box close button */}
      <IconButton
        aria-label='close'
        onClick={handleCloseModal}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        <Grid container spacing={1}>
          {/* State Field */}
          <Grid item xs={12}>
            <InputField
              name={'state'}
              control={control}
              label={'State'}
              placeholder='Enter the state name'
              fullWidth
              errors={!!errors.state}
              helperText={errors.state ? errors.state.message : ''}
              required
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <ButtonField
          variant='contained'
          onClick={handleCloseModal}
          buttonText={'Cancel'}
          sx={{
            backgroundColor: 'primary.main',
          }}
        />

        <ButtonField
          type='submit'
          loading={isLoading}
          variant='contained'
          buttonText={'Save'}
          sx={{
            backgroundColor: 'ternary.main',
            '&:hover': {
              backgroundColor: 'ternary.dark',
            },
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export default AddStateModal
