import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import useEditModal from './useEditModal'
import InputField from '../../../components/InputField'
import ButtonField from '../../../components/ButtonField'
import { EditDistrictModalProps } from './types'
import SelectField from '../../../components/SelectField'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { useEffect, useState } from 'react'
import { useStateDetailsQuery } from '../../../api/stateApi'
import { setListingQueryParams } from '../../../store/slice/commonSlice'
import { APP_CONFIG } from '../../../constants/contants'
import { Controller } from 'react-hook-form'

const EditDistrictModal: React.FC<EditDistrictModalProps> = ({
  id,
  openModal,
  handleCloseModal,
}) => {
  const {
    isLoading,
    isDataLoading,
    control,
    handleSubmit,
    onSubmit,
    errors,
    singleDistrictData,
  } = useEditModal({
    id,
    openModal,
    handleCloseModal,
  })

  const dispatch = useDispatch()

  // Listing Query Params
  const listingQueryParams = useSelector(
    (state: RootState) => state.commonReducer.listingQueryParams,
  )
  const { state: stateQueryParams } = listingQueryParams

  // State List
  const [stateSearch, setStateSearch] = useState('')

  const {
    data: stateList,
    isFetching: isStateFetching,
    isSuccess: isStateSuccess,
  } = useStateDetailsQuery(stateQueryParams, {
    skip: !openModal,
  })

  useEffect(() => {
    dispatch(
      setListingQueryParams({
        ...listingQueryParams,
        state: {
          q: stateSearch,
          page: APP_CONFIG.DEFAULT_PAGE,
          page_size: APP_CONFIG.DEFAULT_PAGE_SIZE,
        },
      }),
    )
  }, [stateSearch])

  return (
    <Dialog
      fullWidth
      sx={{
        '.MuiDialog-paper': {
          borderRadius: 4,
        },
      }}
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      component='form'
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* Title */}
      <DialogTitle
        sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }}
        id='customized-dialog-title'
      >
        Edit District
      </DialogTitle>

      {/* Dialog box close button */}
      <IconButton
        aria-label='close'
        onClick={handleCloseModal}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        {/* District Field */}
        {isDataLoading ? (
          <Box textAlign='center'>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={1} justifyContent={'center'}>
            {/* State Field */}
            <Grid item xs={12}>
              <SelectField
                label={'State'}
                name={'state'}
                options={stateList?.results}
                loading={!isStateSuccess && isStateFetching}
                control={control}
                errors={!!errors.state}
                helperText={errors.state ? errors.state.message : ''}
                getOptionLabel={(option) => option.state_name} // Use 'name' as the label
                getOptionValue={(option) => option.id} // Use 'id' as the value
                selectedOption={{
                  id: singleDistrictData?.data?.state_id,
                  state_name: singleDistrictData?.data?.state_name,
                }}
                onSearch={setStateSearch}
                required
              />
            </Grid>

            {/* District Field */}
            <Grid item xs={12}>
              <InputField
                name={'district'}
                control={control}
                label={'District'}
                placeholder='Enter the district name'
                fullWidth
                errors={!!errors.district}
                helperText={errors.district ? errors.district.message : ''}
                required
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <ButtonField
          variant='contained'
          onClick={handleCloseModal}
          buttonText={'Cancel'}
          sx={{
            backgroundColor: 'primary.main',
          }}
        />

        <ButtonField
          type='submit'
          loading={isLoading || isDataLoading}
          variant='contained'
          buttonText={'Save'}
          sx={{
            backgroundColor: 'ternary.main',
            '&:hover': {
              backgroundColor: 'ternary.dark',
            },
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export default EditDistrictModal
