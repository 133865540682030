import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { AddModalForm, AddModalProps } from './types'
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../../utils/toastMessage'
import { useAddGiftMutation } from '../../../api/giftsApi'
import { addModalValidationSchema } from './validation'
import { AddGiftResponse } from '../../../api/giftsApi/types'

const useAddModal = ({ handleCloseModal }: AddModalProps) => {
  const [addGift, { isLoading }] = useAddGiftMutation()
  const [giftImage, setGiftImage] = useState<File | null>()

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm<AddModalForm>({
    defaultValues: {
      isGiftImage: false,
      giftName: '',
      points: undefined,
    },
    resolver: yupResolver(addModalValidationSchema),
  })

  // Handle Gift Image Field
  const handleGiftImage = (file: File) => {
    const reader = new FileReader()

    setGiftImage(file)
    setValue('isGiftImage', true, {
      shouldValidate: true,
    })

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  const resetForm = () => {
    setGiftImage(null)
    reset()
  }

  const onSubmit: SubmitHandler<AddModalForm> = async (data) => {
    if (!giftImage) {
      return
    }
    const formData = new FormData()
    formData.append('gift_photo', giftImage)
    formData.append('gift_name', data?.giftName)
    formData.append('points', data?.points.toString())

    addGift(formData)
      .unwrap()
      .then((data: AddGiftResponse) => {
        if (data?.success) {
          toastSuccessMessage('Gift Added Successfully')
          handleCloseModal()
        } else {
          toastErrorMessage(data?.message || 'Unable to Add Gift')
        }
      })
      .catch((error) => {
        toastErrorMessage(error?.data?.message || 'Unable to Add Gift')
      })
  }

  return {
    control,
    register,
    errors,
    reset: resetForm,
    onSubmit,
    handleSubmit,
    isLoading,
    giftImage,
    handleGiftImage,
  }
}

export default useAddModal
