import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ListingResponse } from '../../types'
import { API_METHODS } from '../../../constants/contants'
import { API_ENDPOINTS } from '../../../constants/apiendpoints'
import {
  ApprovedUserPayload,
  ApprovedUserResponse,
  DeleteApprovedUserPayload,
  DeleteApprovedUserResponse,
  SingleApprovedUserPayload,
  SingleApprovedUserResponse,
} from './types'
import { ApprovedUsersApiTags, PendingInvitationUsersApiTags } from '../tags'

export const approvedUsersApi = createApi({
  reducerPath: 'approvedUsersApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: [
    ApprovedUsersApiTags.APPROVED_USERS_CREATED,
    ApprovedUsersApiTags.APPROVED_USERS_DELETED,
    ApprovedUsersApiTags.APPROVED_USERS_MODIFIED,
    ApprovedUsersApiTags.APPROVED_USERS_SINGLE,
  ],
  endpoints: (builder) => ({
    // Get All Approved Users
    approvedUsersDetails: builder.query<
      ListingResponse<ApprovedUserResponse[]>,
      ApprovedUserPayload
    >({
      query: (params: ApprovedUserPayload) => {
        return {
          url: API_ENDPOINTS.GET_ALL_APPROVED_USERS,
          method: API_METHODS.GET,
          params,
        }
      },
      providesTags: [
        ApprovedUsersApiTags.APPROVED_USERS_CREATED,
        ApprovedUsersApiTags.APPROVED_USERS_DELETED,
        ApprovedUsersApiTags.APPROVED_USERS_MODIFIED,
      ],
      transformResponse: (
        response: ListingResponse<ApprovedUserResponse[]>,
      ) => {
        return response
      },
    }),

    // Delete Approved User
    deleteApprovedUser: builder.mutation<
      DeleteApprovedUserResponse,
      DeleteApprovedUserPayload
    >({
      query: (params: DeleteApprovedUserPayload) => ({
        url: API_ENDPOINTS.DELETE_APPROVED_USER,
        method: API_METHODS.DELETE,
        params: params,
      }),
      invalidatesTags: [ApprovedUsersApiTags.APPROVED_USERS_DELETED],
      transformResponse: (response: DeleteApprovedUserResponse) => {
        return response
      },
    }),

    // single approved user
    getSingleApprovedUser: builder.query<
      SingleApprovedUserResponse,
      SingleApprovedUserPayload
    >({
      query: (params: SingleApprovedUserPayload) => ({
        url: API_ENDPOINTS.GET_APPROVED_USER_BY_ID, // The ID is part of the URL
        method: API_METHODS.GET,
        params: params,
      }),
      providesTags: [ApprovedUsersApiTags.APPROVED_USERS_SINGLE],
      transformResponse: (response: SingleApprovedUserResponse) => {
        return response
      },
    }),
  }),
})

export const {
  useApprovedUsersDetailsQuery,
  useDeleteApprovedUserMutation,
  useGetSingleApprovedUserQuery,
} = approvedUsersApi
