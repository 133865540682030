import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AddModalForm, AddModalProps } from './types'
import { addModalValidationSchema } from './validation'
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../../utils/toastMessage'
import { useAddDistrictMutation } from '../../../api/districtApi'
import { AddDistrictResponse } from '../../../api/districtApi/types'

const useAddModal = ({ handleCloseModal }: AddModalProps) => {
  const [addDistrict, { isLoading }] = useAddDistrictMutation()

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<AddModalForm>({
    defaultValues: {
      state: undefined,
      district: '',
    },
    resolver: yupResolver(addModalValidationSchema),
  })

  const onSubmit: SubmitHandler<AddModalForm> = async (data) => {
    const formData = new FormData()

    formData.append('state_id', data?.state.toString())
    formData.append('district_name', data?.district)

    addDistrict(formData)
      .unwrap()
      .then((data: AddDistrictResponse) => {
        if (data?.success) {
          toastSuccessMessage('District Added Successfully')
          handleCloseModal()
        } else {
          toastErrorMessage(data?.message || 'Unable to Add District')
        }
      })
      .catch((error) => {
        toastErrorMessage(error?.data?.message || 'Unable to Add District')
      })
  }

  return {
    control,
    register,
    errors,
    reset,
    onSubmit,
    handleSubmit,
    isLoading,
  }
}

export default useAddModal
