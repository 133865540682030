import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { EditModalForm, EditModalProps } from './types'
import { editModalValidationSchema } from './validation'
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../../utils/toastMessage'
import dayjs from 'dayjs'
import {
  useEditDistrictAdminMutation,
  useGetSingleDistrictAdminQuery,
} from '../../../api/districtAdminApi'
import { SELECT_ID_TYPE } from '../../../constants/contants'
import { formatDateToYYYYMMDD } from '../../../utils/common'
import { EditDistrictResponse } from '../../../api/districtApi/types'
import {
  useEditProductMutation,
  useGetSingleProductQuery,
} from '../../../api/productsApi'
import { EditProductResponse } from '../../../api/productsApi/types'

const useEditModal = ({ id, openModal, handleCloseModal }: EditModalProps) => {
  const [productImage, setProductImage] = useState<File | string | null>()

  // Fetching Product Data
  const {
    data: productData,
    isSuccess: isProductSuccess,
    isFetching: isProductFetching,
  } = useGetSingleProductQuery(
    {
      id: id,
    },
    {
      skip: !openModal, // Skip the query if the modal is not open
    },
  )

  // Edit Product
  const [editProduct, { isLoading }] = useEditProductMutation()
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    watch,
  } = useForm<EditModalForm>({
    defaultValues: {
      isProductImage: false,
      productName: '',
      points: 0,
      packing: 0,
    },
    resolver: yupResolver(editModalValidationSchema),
  })

  // Handle Product Image Field
  const handleProductImage = (file: File) => {
    const reader = new FileReader()

    setProductImage(file)
    setValue('isProductImage', true, {
      shouldValidate: true,
    })

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  useEffect(() => {
    if (productData?.data && isProductSuccess) {
      setProductImage(productData?.data?.product_photo)
      setValue('isProductImage', true)
      setValue('productName', productData?.data?.product_name)
      setValue('packing', productData?.data?.packing)
      setValue('points', productData?.data?.points)
    }
  }, [productData, setValue])

  const onSubmit: SubmitHandler<EditModalForm> = async (data) => {
    if (!productImage) {
      return
    }
    const formData = new FormData()

    formData.append('product_photo', productImage)
    formData.append('product_name', data?.productName)
    formData.append('packing', data?.packing.toString())
    formData.append('points', data?.points.toString())

    editProduct({ id: id, formData: formData })
      .unwrap()
      .then((data: EditProductResponse) => {
        if (data?.success) {
          toastSuccessMessage('Product Updated Successfully')
          handleCloseModal()
        } else {
          toastErrorMessage(data?.message || 'Unable to Update Product')
        }
      })
      .catch((error) => {
        toastErrorMessage(error?.data?.message || 'Unable to Update Product')
      })
  }

  return {
    control,
    register,
    errors,
    reset,
    onSubmit,
    handleSubmit,
    isLoading,
    productImage,
    handleProductImage,
    watch,
    isDataLoading: isProductFetching && !isProductSuccess,
  }
}

export default useEditModal
