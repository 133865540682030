import * as yup from 'yup'

export const addModalValidationSchema = yup.object().shape({
  token: yup.string().required('Token are required'),
  points: yup
    .number()
    .typeError('Points are required')
    .required('Points are required')
    .positive('Points must be a positive number')
    .integer('Points must be an number'),
})
