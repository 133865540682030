import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  DistrictByStatePayload,
  DistrictPayload,
} from '../../api/districtApi/types'
import { APP_CONFIG, localstoragekeys } from '../../constants/contants'
import { DistrictAdminPayload } from '../../api/districtAdminApi/types'
import { ProductPayload } from '../../api/productsApi/types'
import { GiftQueryPayload } from '../../api/giftsApi/types'
import { StatePayload } from '../../api/stateApi/types'
import { ApprovedUserPayload } from '../../api/userApi/approvedUsersApi/types'
import { PendingInvitationUserPayload } from '../../api/userApi/pendingInvitationUsersApi/types'
import { RedeemPointsApprovedPayload } from '../../api/redeemPointsApi/redeemPointsApprovedApi/types'
import { RedeemPointsDeclinedPayload } from '../../api/redeemPointsApi/redeemPointsDeclinedApi/types'
import { RedeemPointsPendingPayload } from '../../api/redeemPointsApi/redeemPointsPendingApi/types'
import { EventQueryPayload } from '../../api/eventsApi/types'
import { VisitingCardEligibilityLimitPayload } from '../../api/visitingCardEligibilityLimit/types'
import { VisitingCardRecordsPayload } from '../../api/visitingCardRecords/types'
import { QRCodePayload } from '../../api/qrCodeApi/types'
import { UserReportPayload } from '../../api/reportsApi/types'
import { NotificationPayload } from '../../api/notificationsApi/types'

export type TModalState = {
  showSidebar: boolean
}

export type TListingQueryParams = {
  district: DistrictPayload
  districtByStateId: DistrictByStatePayload
  districtAdmin: DistrictAdminPayload
  approvedUsers: ApprovedUserPayload
  pendingInvitationUsers: PendingInvitationUserPayload
  products: ProductPayload
  gift: GiftQueryPayload
  redeemPointsApprovedInvitation: RedeemPointsApprovedPayload
  redeemPointsDeclinedInvitation: RedeemPointsDeclinedPayload
  redeemPointsPendingInvitation: RedeemPointsPendingPayload
  state: StatePayload
  events: EventQueryPayload
  visitingCardEligibilityLimit: VisitingCardEligibilityLimitPayload
  visitingCardRecords: VisitingCardRecordsPayload
  qrCode: QRCodePayload
  userReports: UserReportPayload
  notifications: NotificationPayload
}

export type CommonState = {
  modals: TModalState
  listingQueryParams: TListingQueryParams
}

const initialState: CommonState = {
  modals: {
    showSidebar: true,
  },
  listingQueryParams: {
    district: {
      q: '',
      page_size: APP_CONFIG.DEFAULT_PAGE_SIZE,
      page: APP_CONFIG.DEFAULT_PAGE,
    },
    districtByStateId: {
      state_id: 0,
    },
    state: {
      q: '',
      page_size: APP_CONFIG.DEFAULT_PAGE_SIZE,
      page: APP_CONFIG.DEFAULT_PAGE,
    },
    districtAdmin: {
      q: '',
      page_size: APP_CONFIG.DEFAULT_PAGE_SIZE,
      page: APP_CONFIG.DEFAULT_PAGE,
    },
    approvedUsers: {
      q: '',
      page_size: APP_CONFIG.DEFAULT_PAGE_SIZE,
      page: APP_CONFIG.DEFAULT_PAGE,
    },
    pendingInvitationUsers: {
      q: '',
      page_size: APP_CONFIG.DEFAULT_PAGE_SIZE,
      page: APP_CONFIG.DEFAULT_PAGE,
    },
    products: {
      q: '',
      page_size: APP_CONFIG.DEFAULT_PAGE_SIZE,
      page: APP_CONFIG.DEFAULT_PAGE,
    },
    gift: {
      q: '',
      page_size: APP_CONFIG.DEFAULT_PAGE_SIZE,
      page: APP_CONFIG.DEFAULT_PAGE,
    },
    redeemPointsApprovedInvitation: {
      q: '',
      page_size: APP_CONFIG.DEFAULT_PAGE_SIZE,
      page: APP_CONFIG.DEFAULT_PAGE,
    },
    redeemPointsDeclinedInvitation: {
      q: '',
      page_size: APP_CONFIG.DEFAULT_PAGE_SIZE,
      page: APP_CONFIG.DEFAULT_PAGE,
    },
    redeemPointsPendingInvitation: {
      q: '',
      page_size: APP_CONFIG.DEFAULT_PAGE_SIZE,
      page: APP_CONFIG.DEFAULT_PAGE,
    },
    events: {
      q: '',
      page_size: APP_CONFIG.DEFAULT_PAGE_SIZE,
      page: APP_CONFIG.DEFAULT_PAGE,
    },
    visitingCardEligibilityLimit: {
      q: '',
      page_size: APP_CONFIG.DEFAULT_PAGE_SIZE,
      page: APP_CONFIG.DEFAULT_PAGE,
    },
    visitingCardRecords: {
      q: '',
      page_size: APP_CONFIG.DEFAULT_PAGE_SIZE,
      page: APP_CONFIG.DEFAULT_PAGE,
    },
    qrCode: {
      page_size: APP_CONFIG.DEFAULT_PAGE_SIZE,
      page: APP_CONFIG.DEFAULT_PAGE,
    },
    userReports: {
      q: '',
      sort_order: 'desc',
      page_size: APP_CONFIG.DEFAULT_PAGE_SIZE,
      page: APP_CONFIG.DEFAULT_PAGE,
    },
    notifications: {
      admin_id: localStorage.getItem(localstoragekeys.id) || '',
      page_size: APP_CONFIG.DEFAULT_PAGE_SIZE,
      page: APP_CONFIG.DEFAULT_PAGE,
    },
  },
}

export const commonSlice = createSlice({
  name: 'commonSlice',
  initialState: initialState,
  reducers: {
    setListingQueryParams: (
      state,
      action: PayloadAction<TListingQueryParams>,
    ) => {
      state.listingQueryParams = {
        ...(state.listingQueryParams || {}),
        ...action?.payload,
      }
    },
    setModalsData: (state, action: PayloadAction<TModalState>) => {
      state.modals = { ...(state.modals || {}), ...action?.payload }
    },
  },
})

export const { setModalsData, setListingQueryParams } = commonSlice.actions

export default commonSlice.reducer
