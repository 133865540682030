import { Box, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { APP_CONFIG, REDEEM_STATUS } from '../../../constants/contants'
import DataTable from './DataTable'
import { setListingQueryParams } from '../../../store/slice/commonSlice'
import ViewPendingInvitationModal from './ViewPendingInvitation'
import Swal from 'sweetalert2'
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../../utils/toastMessage'
import { useRedeemPointsPendingListDetailsQuery } from '../../../api/redeemPointsApi/redeemPointsPendingApi'
import { useUpdateRedeemPointsStatusMutation } from '../../../api/redeemPointsApi/redeemPointsStatusApi'
import { UpdateRedeemPointsStatusResponse } from '../../../api/redeemPointsApi/redeemPointsStatusApi/types'

const PendingInvitation = () => {
  const dispatch = useDispatch<AppDispatch>()

  // Listing Query Params
  const listingQueryParams = useSelector(
    (state: RootState) => state.commonReducer.listingQueryParams,
  )

  const {
    redeemPointsPendingInvitation: redeemPointsPendingInvitationQueryParams,
  } = listingQueryParams

  // Page
  const [page, setPage] = useState(APP_CONFIG.DEFAULT_PAGE)
  const [pageSize, setPageSize] = useState(APP_CONFIG.DEFAULT_PAGE_SIZE)

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage + 1)
  }

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(APP_CONFIG.DEFAULT_PAGE)
  }

  // Search Value
  const [search, setSearch] = useState('')

  const handleSearchValue = (value: string) => {
    setSearch(value)
  }

  // View Modal
  const [openViewModal, setOpenViewModal] = useState({
    id: 0,
    open: false,
  })

  const handleOpenViewModal = (id: number) => {
    setOpenViewModal({
      id: id,
      open: true,
    })
  }

  const handleCloseViewModal = () => {
    setOpenViewModal({
      id: 0,
      open: false,
    })
  }

  const { data, isFetching, isSuccess } =
    useRedeemPointsPendingListDetailsQuery(
      redeemPointsPendingInvitationQueryParams,
    )

  // edit Redeem Points invitation user status
  const [updateRedeemPointsStatus, { isLoading }] =
    useUpdateRedeemPointsStatusMutation()

  // Approved Redeem Points Invitation
  const handleApprovedRedeemPointsInvitation = (id: number) => {
    Swal.fire({
      title: 'Are you sure you want to approved redeem points invitation?',
      icon: 'warning',
      input: 'text',
      inputPlaceholder: 'Write the message for user...!',
      inputValidator: (value) => {
        if (!value) {
          return 'You need to enter the message for user'
        }
      },
      showCancelButton: true,
      customClass: {
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button',
        title: 'custom-title',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const formData = new FormData()
        const currentDate = new Date()
        const date = currentDate.toISOString().split('T')[0]
        const time = currentDate.toTimeString().split(' ')[0]

        formData.append('status', REDEEM_STATUS.APPROVED)
        formData.append('message', result.value)
        formData.append('date', date)
        formData.append('time', time)

        updateRedeemPointsStatus({ id: id, formData: formData })
          .unwrap()
          .then((data: UpdateRedeemPointsStatusResponse) => {
            if (data?.success) {
              toastSuccessMessage(
                'Redeem Points Invitation has been Approved successfully',
              )
            } else {
              toastErrorMessage(
                data?.message ||
                  'Unable to Update Redeem Points Invitation Status',
              )
            }
          })
          .catch((error) => {
            toastErrorMessage(
              error?.data?.message ||
                'Unable to Update Redeem Points Invitation Status',
            )
          })
      }
    })
  }

  // Rejected Redeem Points Invitation
  const handleDeclinedRedeemPointsInvitation = (id: number) => {
    Swal.fire({
      title: 'Are you sure you want to declined redeem points invitation?',
      icon: 'warning',
      showCancelButton: true,
      input: 'text',
      inputPlaceholder: 'Write the message for user...!',
      inputValidator: (value) => {
        if (!value) {
          return 'You need to enter the message for user'
        }
      },
      customClass: {
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button',
        title: 'custom-title',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const currentDate = new Date()
        const date = currentDate.toISOString().split('T')[0]
        const time = currentDate.toTimeString().split(' ')[0]

        const formData = new FormData()
        formData.append('status', REDEEM_STATUS.DECLINED)
        formData.append('message', result.value)
        formData.append('date', date)
        formData.append('time', time)

        updateRedeemPointsStatus({ id: id, formData: formData })
          .unwrap()
          .then((data: UpdateRedeemPointsStatusResponse) => {
            if (data?.success) {
              toastSuccessMessage(
                'Redeem Points Invitation has been Declined successfully',
              )
            } else {
              toastErrorMessage(
                data?.message ||
                  'Unable to Update Redeem Points Invitation Status',
              )
            }
          })
          .catch((error) => {
            toastErrorMessage(
              error?.data?.message ||
                'Unable to Update Redeem Points Invitation Status',
            )
          })
      }
    })
  }

  useEffect(() => {
    dispatch(
      setListingQueryParams({
        ...listingQueryParams,
        redeemPointsPendingInvitation: {
          q: search,
          page: page,
          page_size: pageSize,
        },
      }),
    )
  }, [search, page, pageSize])

  return (
    <>
      {/* Table */}
      <Box>
        <DataTable
          headers={[
            '#',
            'Full Name',
            'Mobile Number',
            'Gift Name',
            'Gift Points',
            'Approval Req. Date',
            'Status',
            'View',
            'Actions',
          ]}
          data={data?.results}
          totalPages={data?.count}
          page={page}
          rowsPerPage={pageSize}
          handlePageChange={handlePageChange}
          handleSearchValue={handleSearchValue}
          handleOpenViewModal={handleOpenViewModal}
          handleChangeRowsPerPage={handleChangePageSize}
          handleApprovedRedeemPointsInvitation={
            handleApprovedRedeemPointsInvitation
          }
          handleDeclinedRedeemPointsInvitation={
            handleDeclinedRedeemPointsInvitation
          }
          isSuccess={isSuccess && !isFetching}
        />
      </Box>

      {/* View Modal */}
      {!!openViewModal?.id && (
        <ViewPendingInvitationModal
          id={openViewModal?.id}
          openModal={openViewModal?.open}
          handleCloseModal={handleCloseViewModal}
        />
      )}
    </>
  )
}

export default PendingInvitation
