import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material'
import { DataTableProps } from './types'
import NotificationCard from '../NotificationCard'

export default function DataTable({
  headers,
  data,
  page,
  rowsPerPage,
  handlePageChange,
  handleChangeRowsPerPage,
  totalPages,
  isSuccess,
  action,
  handleNotificationMarkRead,
}: DataTableProps) {
  return (
    <Card elevation={0} sx={{ borderRadius: 3 }}>
      <CardHeader
        sx={{
          display: 'flex',
          flexDirection: 'column', // Stack title and action vertically on small screens
          alignItems: 'flex-start', // Align content to the start
          '.MuiCardHeader-action': {
            alignSelf: 'end',
            mr: 1,
            '@media (min-width:480px)': {
              alignSelf: 'center', // Align action to center on larger screens
            },
          },
          '@media (min-width:480px)': {
            flexDirection: 'row', // Default behavior on larger screens
            alignItems: 'center', // Align content to the center
          },
        }}
        // title={<SearchBar handleSearchValue={handleSearchValue} />}
        action={action}
      />

      <Divider />

      <CardContent sx={{ px: 0, pb: 0 }}>
        <Table aria-label='simple table'>
          <TableBody>
            {isSuccess ? (
              data && data.length > 0 ? (
                data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <NotificationCard
                        key={row?.notification_id}
                        notification_id={row?.notification_id}
                        title={row?.title}
                        description={row?.description}
                        date={row?.created_at}
                        status={row.is_read}
                        handleNotificationMarkRead={handleNotificationMarkRead}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={headers.length} align='center'>
                    No records found
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell
                  sx={{ textAlign: 'center', py: 5 }}
                  colSpan={headers.length}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>

      <CardActions
        sx={{
          justifyContent: 'end',
        }}
      >
        <TablePagination
          component='div'
          color='primary'
          count={totalPages || 0}
          page={page - 1}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CardActions>
    </Card>
  )
}
