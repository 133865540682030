import {
  Box,
  Divider,
  List,
  ListSubheader,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import MenuListItem from '../MenuListItem'
import { menuList } from './menuListItems'
import { setModalsData, TModalState } from '../../../../store/slice/commonSlice'
import { useDispatch, useSelector } from 'react-redux'

const MenuList = () => {
  const modalsState: TModalState = useSelector(
    (state: any) => state.commonReducer.modals,
  )

  const dispatch = useDispatch()
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))

  const handleCloseSidebar = () => {
    dispatch(setModalsData({ ...modalsState, showSidebar: false }))
  }

  const onItemClick = () => {
    if (!matchUpMd) {
      handleCloseSidebar()
    }
  }

  return (
    <>
      {menuList.map((menu, index) => (
        <Box key={index}>
          <List
            sx={{
              pb: modalsState.showSidebar ? 1 : 0,
              pt: 0,
            }}
            subheader={
              modalsState.showSidebar && (
                <ListSubheader
                  component='div'
                  sx={{ ml: 1, color: 'black', fontWeight: 600 }}
                  id='nested-list-subheader'
                >
                  {menu.title}
                </ListSubheader>
              )
            }
          >
            {menu.item.map((item, index) => {
              const Icon = item.Icon
              return (
                <MenuListItem
                  key={index}
                  open={modalsState.showSidebar}
                  title={item.title}
                  icon={<Icon fontSize='small' />}
                  to={item.to}
                  onItemClick={onItemClick}
                />
              )
            })}
          </List>
          {modalsState.showSidebar && menuList.length !== index + 1 && (
            <Divider sx={{ mx: 2 }} />
          )}
        </Box>
      ))}
    </>
  )
}

export default MenuList
