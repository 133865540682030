import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import useAddModal from './useAddModal'
import InputField from '../../../components/InputField'
import RadioFieldInput from '../../../components/RadioFieldInput'
import ButtonField from '../../../components/ButtonField'
import DateTimeField from '../../../components/DateTimeField'
import SelectField from '../../../components/SelectField'
import { useEffect, useState } from 'react'
import { AddDistrictAdminModalProps } from './types'
import {
  useDistrictDetailsByStateQuery,
  useDistrictDetailsQuery,
} from '../../../api/districtApi'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { setListingQueryParams } from '../../../store/slice/commonSlice'
import { APP_CONFIG, SELECT_ID_TYPE } from '../../../constants/contants'
import FileInput from '../../../components/FileInput'
import RoundedImageUploader from '../../../components/RoundedImageUploader'
import { useStateDetailsQuery } from '../../../api/stateApi'
import { IconSquareRotatedForbid } from '@tabler/icons-react'

const AddDistrictAdminModal: React.FC<AddDistrictAdminModalProps> = ({
  openModal,
  handleCloseModal,
}) => {
  const {
    isLoading,
    control,
    handleSubmit,
    onSubmit,
    handleProfileImage,
    handleAadharPanImage,
    errors,
    reset,
    selectId,
    profileImage,
    aadharPanImage,
    watch,
  } = useAddModal({
    handleCloseModal,
  })

  const dispatch = useDispatch()

  // Listing Query Params
  const listingQueryParams = useSelector(
    (state: RootState) => state.commonReducer.listingQueryParams,
  )

  // State List
  const [stateSearch, setStateSearch] = useState('')
  const { state: stateQueryParams } = listingQueryParams
  const {
    data: stateList,
    isFetching: isStateFetching,
    isSuccess: isStateSuccess,
  } = useStateDetailsQuery(stateQueryParams, {
    skip: !openModal,
  })

  useEffect(() => {
    dispatch(
      setListingQueryParams({
        ...listingQueryParams,
        state: {
          q: stateSearch,
          page: APP_CONFIG.DEFAULT_PAGE,
          page_size: APP_CONFIG.DEFAULT_PAGE_SIZE,
        },
      }),
    )
  }, [stateSearch, openModal])

  // District List
  const stateId = watch('state')

  const { districtByStateId: districtByStateQueryParams } = listingQueryParams
  const {
    data: districtList,
    isFetching: isDistrictFetching,
    isSuccess: isDistrictSuccess,
  } = useDistrictDetailsByStateQuery(districtByStateQueryParams, {
    skip: !openModal || !stateId || stateId < 0,
  })

  useEffect(() => {
    if (stateId) {
      dispatch(
        setListingQueryParams({
          ...listingQueryParams,
          districtByStateId: {
            state_id: stateId,
          },
        }),
      )
    }
  }, [stateId])

  // reset data when open the modal
  useEffect(() => {
    reset()
  }, [openModal])

  return (
    <Dialog
      fullWidth
      sx={{
        '.MuiDialog-paper': {
          borderRadius: 4,
        },
      }}
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      component='form'
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* Title */}
      <DialogTitle
        sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }}
        id='add-district-admin'
      >
        Add District Admin
      </DialogTitle>

      {/* Dialog box close button */}
      <IconButton
        onClick={handleCloseModal}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        <Grid container spacing={1}>
          {/* ProfileImage */}
          <Grid item xs={12}>
            <RoundedImageUploader
              value={profileImage}
              error={errors.isProfileImage ? errors.isProfileImage.message : ''}
              onChange={handleProfileImage}
            />
          </Grid>

          {/* Full Name Field */}
          <Grid item xs={12} sm={6}>
            <InputField
              name={'fullName'}
              control={control}
              label={'Full Name'}
              fullWidth
              errors={!!errors.fullName}
              helperText={errors.fullName ? errors.fullName.message : ''}
              required
            />
          </Grid>

          {/* Mobile Number Field */}
          <Grid item xs={12} sm={6}>
            <InputField
              name={'mobileNumber'}
              control={control}
              label={'Mobile Number'}
              fullWidth
              required
              errors={!!errors.mobileNumber}
              helperText={
                errors.mobileNumber ? errors.mobileNumber.message : ''
              }
            />
          </Grid>

          {/* Password Field */}
          <Grid item xs={12} sm={6}>
            <InputField
              name={'password'}
              control={control}
              label={'Password'}
              type={'password'}
              fullWidth
              required
              errors={!!errors.password}
              helperText={errors.password ? errors.password.message : ''}
            />
          </Grid>

          {/* Confirm Password Field */}
          <Grid item xs={12} sm={6}>
            <InputField
              name={'confirmPassword'}
              control={control}
              label={'Confirm Password'}
              type={'password'}
              fullWidth
              required
              errors={!!errors.confirmPassword}
              helperText={
                errors.confirmPassword ? errors.confirmPassword.message : ''
              }
            />
          </Grid>

          {/* Date of Birth Field */}
          <Grid item xs={12} mt={2}>
            <DateTimeField
              name='dob'
              label='Date of Birth'
              disableFuture
              control={control}
              required
              errors={!!errors.dob}
              helperText={errors.dob ? errors.dob.message : ''}
            />
          </Grid>

          {/* State Field */}
          <Grid item xs={12} sm={6} mt={2}>
            <SelectField
              label={'State'}
              name={'state'}
              options={stateList?.results}
              loading={!isStateSuccess && isStateFetching}
              control={control}
              errors={!!errors.state}
              helperText={errors.state ? errors.state.message : ''}
              getOptionLabel={(option) => option.state_name} // Use 'name' as the label
              getOptionValue={(option) => option.id} // Use 'id' as the value
              onSearch={setStateSearch}
              required
            />
          </Grid>

          {/* District Field */}
          <Grid item xs={12} sm={6} mt={2}>
            {!!stateId && (
              <SelectField
                label={'District'}
                name={'district'}
                options={districtList?.results}
                loading={!isDistrictSuccess && isDistrictFetching}
                control={control}
                errors={!!errors.district}
                helperText={errors.district ? errors.district.message : ''}
                getOptionLabel={(option) => option.district_name} // Use 'name' as the label
                getOptionValue={(option) => option.id} // Use 'id' as the value
                required
              />
            )}
          </Grid>

          {/* Select Id Option Field */}
          <Grid item xs={12} mt={2}>
            <RadioFieldInput
              control={control}
              name='selectId'
              label='Select Id Type'
              options={[
                { value: SELECT_ID_TYPE.AADHAR, label: 'Aadhar' },
                { value: SELECT_ID_TYPE.PAN, label: 'Pan' },
              ]}
              direction='row'
              errors={!!errors.selectId}
              helperText={errors.selectId ? errors.selectId.message : ''}
            />
          </Grid>
          {/* Aadhar/Pan Image Field */}
          <Grid item xs={12}>
            {selectId && (
              <FileInput
                value={aadharPanImage}
                error={
                  errors.isAadharPanImage ? errors.isAadharPanImage.message : ''
                }
                onChange={handleAadharPanImage}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <ButtonField
          variant='contained'
          onClick={handleCloseModal}
          buttonText={'Cancel'}
          sx={{
            backgroundColor: 'primary.main',
          }}
        />

        <ButtonField
          type='submit'
          loading={isLoading}
          variant='contained'
          buttonText={'Save'}
          sx={{
            backgroundColor: 'ternary.main',
            '&:hover': {
              backgroundColor: 'ternary.dark',
            },
          }}
        />
      </DialogActions>
      {/* </Box> */}
    </Dialog>
  )
}

export default AddDistrictAdminModal
