import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { EditModalForm, EditModalProps } from './types'
import { editModalValidationSchema } from './validation'
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../../utils/toastMessage'
import {
  useEditDistrictMutation,
  useGetSingleDistrictQuery,
} from '../../../api/districtApi'
import { EditDistrictResponse } from '../../../api/districtApi/types'

const useEditModal = ({ id, openModal, handleCloseModal }: EditModalProps) => {
  // Get District
  const {
    data: singleDistrictData,
    isSuccess: isSingleDistrictDataSuccess,
    isFetching: isSingleDistrictDataFetching,
  } = useGetSingleDistrictQuery(
    {
      id: id,
    },
    {
      skip: !openModal, // Skip the query if the modal is not open
    },
  )

  // Edit District
  const [editDistrict, { isLoading }] = useEditDistrictMutation()

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm<EditModalForm>({
    defaultValues: {
      state: undefined,
      district: '',
    },
    resolver: yupResolver(editModalValidationSchema),
  })

  useEffect(() => {
    if (singleDistrictData?.data) {
      setValue('state', singleDistrictData?.data?.state_id)
      setValue('district', singleDistrictData?.data?.district_name)
    }
  }, [singleDistrictData?.data, setValue])

  const onSubmit: SubmitHandler<EditModalForm> = async (data) => {
    const formData = {
      id: singleDistrictData?.data?.id || 0,
      state_id: data?.state || 0,
      district_name: data?.district,
    }

    editDistrict(formData)
      .unwrap()
      .then((data: EditDistrictResponse) => {
        if (data?.success) {
          toastSuccessMessage('District Updated Successfully')
          handleCloseModal()
        } else {
          toastErrorMessage(data?.message || 'Unable to Updated District')
        }
      })
      .catch((error) => {
        toastErrorMessage(error?.data?.message || 'Unable to Updated District')
      })
  }

  return {
    control,
    register,
    errors,
    reset,
    onSubmit,
    handleSubmit,
    isLoading,
    singleDistrictData,
    isDataLoading: isSingleDistrictDataFetching && !isSingleDistrictDataSuccess,
  }
}

export default useEditModal
