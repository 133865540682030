import { Box, Button } from '@mui/material'
import DataTable from './DataTable'
import { AppDispatch, RootState } from '../../../store'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { APP_CONFIG } from '../../../constants/contants'
import Swal from 'sweetalert2'
import AddVisitingCardEligibilityLimitModal from './AddVisitingCardEligibilityLimitModal'
import EditVisitingCardEligibilityLimitModal from './EditVisitingCardEligibilityLimitModal'
import ViewVisitingCardEligibilityLimitModal from './ViewVisitingCardEligibilityLimitModal'
import {
  useDeleteVisitingCardEligibilityLimitMutation,
  useVisitingCardEligibilityLimitDetailsQuery,
} from '../../../api/visitingCardEligibilityLimit'
import { setListingQueryParams } from '../../../store/slice/commonSlice'
import { DeleteVisitingCardEligibilityLimitResponse } from '../../../api/visitingCardEligibilityLimit/types'
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../../utils/toastMessage'

const VisitingCardEligibilityLimit = () => {
  const dispatch = useDispatch<AppDispatch>()

  // Listing Query Params
  const listingQueryParams = useSelector(
    (state: RootState) => state.commonReducer.listingQueryParams,
  )
  const {
    visitingCardEligibilityLimit: visitingCardEligibilityLimitQueryParams,
  } = listingQueryParams

  // Page
  const [page, setPage] = useState(APP_CONFIG.DEFAULT_PAGE)
  const [pageSize, setPageSize] = useState(APP_CONFIG.DEFAULT_PAGE_SIZE)

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage + 1)
  }

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(APP_CONFIG.DEFAULT_PAGE)
  }

  // Search Value
  const [search, setSearch] = useState('')

  const handleSearchValue = (value: string) => {
    setSearch(value)
  }

  // Add Modal
  const [openAddModal, setOpenAddModal] = useState<boolean>(false)

  const handleOpenAddModal = () => {
    setOpenAddModal(true)
  }

  const handleCloseAddModal = () => {
    setOpenAddModal(false)
  }

  // Edit Modal
  const [openEditModal, setOpenEditModal] = useState({
    id: 0,
    open: false,
  })

  const handleOpenEditModal = (id: number) => {
    setOpenEditModal({
      id: id,
      open: true,
    })
  }

  const handleCloseEditModal = () => {
    setOpenEditModal({
      id: 0,
      open: false,
    })
  }

  // View Modal
  const [openViewModal, setOpenViewModal] = useState({
    id: 0,
    open: false,
  })

  const handleOpenViewModal = (id: number) => {
    setOpenViewModal({
      id: id,
      open: true,
    })
  }

  const handleCloseViewModal = () => {
    setOpenViewModal({
      id: 0,
      open: false,
    })
  }

  // Delete Modal

  const [deleteVisitingCardLimit] =
    useDeleteVisitingCardEligibilityLimitMutation()

  const handleOpenDeleteModal = (id: number) => {
    Swal.fire({
      title: 'Are you sure you want to delete visiting card eligibility limit?',
      icon: 'warning',
      showCancelButton: true,
      customClass: {
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button',
        title: 'custom-title',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteVisitingCardLimit({
          id: id,
        })
          .unwrap()
          .then((data: DeleteVisitingCardEligibilityLimitResponse) => {
            if (data?.success) {
              toastSuccessMessage(
                'Visiting card limit has been deleted successfully',
              )
            } else {
              toastErrorMessage(
                data?.message || 'Unable to Delete Visiting card limit',
              )
            }
          })
          .catch((error) => {
            toastErrorMessage(
              error?.data?.message || 'Unable to Delete Visiting card limit',
            )
          })
      }
    })
  }

  const { data, isFetching, isSuccess } =
    useVisitingCardEligibilityLimitDetailsQuery(
      visitingCardEligibilityLimitQueryParams,
    )

  useEffect(() => {
    dispatch(
      setListingQueryParams({
        ...listingQueryParams,
        visitingCardEligibilityLimit: {
          q: search,
          page: page,
          page_size: pageSize,
        },
      }),
    )
  }, [search, page, pageSize])

  return (
    <>
      {/* Table */}
      <Box>
        <DataTable
          headers={['#', 'State', 'District', 'Points Limit', 'Actions']}
          action={
            <Button
              variant='contained'
              onClick={handleOpenAddModal}
              sx={{ textTransform: 'none', fontWeight: 600 }}
            >
              Add Token Limit
            </Button>
          }
          data={data?.results}
          totalPages={data?.count}
          page={page}
          rowsPerPage={pageSize}
          handlePageChange={handlePageChange}
          handleSearchValue={handleSearchValue}
          handleOpenEditModal={handleOpenEditModal}
          handleOpenDeleteModal={handleOpenDeleteModal}
          handleOpenViewModal={handleOpenViewModal}
          handleChangeRowsPerPage={handleChangePageSize}
          isSuccess={isSuccess && !isFetching}
        />
      </Box>

      {/* Add Modal */}
      <AddVisitingCardEligibilityLimitModal
        openModal={openAddModal}
        handleCloseModal={handleCloseAddModal}
      />

      {!!openEditModal?.id && (
        <EditVisitingCardEligibilityLimitModal
          id={openEditModal?.id}
          openModal={openEditModal?.open}
          handleCloseModal={handleCloseEditModal}
        />
      )}

      {/* View Modal */}
      {!!openViewModal?.id && (
        <ViewVisitingCardEligibilityLimitModal
          id={openViewModal?.id}
          openModal={openViewModal?.open}
          handleCloseModal={handleCloseViewModal}
        />
      )}
    </>
  )
}

export default VisitingCardEligibilityLimit
