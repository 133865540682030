import * as yup from 'yup'

export const editModalValidationSchema = yup.object().shape({
  isProductImage: yup
    .boolean()
    .oneOf([true], 'Product image must be uploaded')
    .required('Product image is required'), // Ensure the field is included in the form data
  productName: yup.string().required('Product Name is required'),
  packing: yup
    .number()
    .typeError('Packing is required')
    .required('Packing is required')
    .positive('Packing must be a positive number')
    .integer('Packing must be an integer'),
  points: yup
    .number()
    .typeError('Points is required')
    .required('Points are required')
    .positive('Points must be a positive number')
    .integer('Points must be an integer'),
})
