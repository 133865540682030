import React, { FC, useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import InputField from '../../../components/InputField'
import { useForm } from 'react-hook-form'
import FileInput from '../../../components/FileInput'
import { GiftForm, ViewGiftModalProps } from './types'
import { useGetSingleGiftQuery } from '../../../api/giftsApi'

const ViewGiftModal: FC<ViewGiftModalProps> = ({
  id,
  openModal,
  handleCloseModal,
}) => {
  const [giftImage, setGiftImage] = useState<File | string | null>()

  // Fetching Gift Data
  const { data, isSuccess, isFetching } = useGetSingleGiftQuery(
    {
      id: id,
    },
    {
      skip: !openModal, // Skip the query if the modal is not open
    },
  )

  const { control, setValue } = useForm<GiftForm>({
    defaultValues: {
      isGiftImage: false,
      giftName: '',
      points: 0,
    },
  })

  useEffect(() => {
    if (data?.data) {
      setGiftImage(data?.data?.gift_photo)
      setValue('giftName', data?.data?.gift_name)
      setValue('points', data?.data?.points)
    }
  }, [data, setValue])

  return (
    <Dialog
      fullWidth
      sx={{
        '.MuiDialog-paper': {
          borderRadius: 4,
        },
      }}
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle
        sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }}
        id='customized-dialog-title'
      >
        View Gift Details
        <IconButton
          aria-label='close'
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {isFetching || !isSuccess ? (
          <Box textAlign='center'>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={1}>
            {/* Gift Image Image Field */}
            <Grid item xs={12}>
              <FileInput value={giftImage} readOnly />
            </Grid>

            {/* Gift Name Field */}
            <Grid item xs={12} sm={6}>
              <InputField
                name={'giftName'}
                control={control}
                label={'Gift Name'}
                fullWidth
                readOnly
              />
            </Grid>

            {/* Points Field */}
            <Grid item xs={12} sm={6}>
              <InputField
                name={'points'}
                control={control}
                label={'Points'}
                type='number'
                fullWidth
                readOnly
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ViewGiftModal
