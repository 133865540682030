import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ListingResponse } from '../types'
import { DistrictApiTags } from './tags'
import {
  AddDistrictResponse,
  DeleteDistrictPayload,
  DeleteDistrictResponse,
  DistrictByStatePayload,
  DistrictPayload,
  DistrictResponse,
  EditDistrictPayload,
  EditDistrictResponse,
  SingleDistrictPayload,
  SingleDistrictResponse,
} from './types'
import { API_METHODS } from '../../constants/contants'
import { API_ENDPOINTS } from '../../constants/apiendpoints'

export const districtApi = createApi({
  reducerPath: 'districtApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: [
    DistrictApiTags.DISTIRCT_SINGLE,
    DistrictApiTags.DISTRICT_CREATED,
    DistrictApiTags.DISTRICT_DELETED,
    DistrictApiTags.DISTRICT_MODIFIED,
  ],
  endpoints: (builder) => ({
    // Get All District List
    districtDetails: builder.query<
      ListingResponse<DistrictResponse[]>,
      DistrictPayload
    >({
      query: (params: DistrictPayload) => {
        return {
          url: API_ENDPOINTS.DISTRICT,
          method: API_METHODS.GET,
          params,
        }
      },
      providesTags: [
        DistrictApiTags.DISTRICT_CREATED,
        DistrictApiTags.DISTRICT_DELETED,
        DistrictApiTags.DISTRICT_MODIFIED,
      ],
      transformResponse: (response: ListingResponse<DistrictResponse[]>) => {
        return response
      },
    }),

    // Get All District List By State
    districtDetailsByState: builder.query<
      ListingResponse<DistrictResponse[]>,
      DistrictByStatePayload
    >({
      query: (params: DistrictByStatePayload) => {
        return {
          url: API_ENDPOINTS.DISTRICTBYSTATE,
          method: API_METHODS.GET,
          params,
        }
      },
      providesTags: [
        DistrictApiTags.DISTRICT_CREATED,
        DistrictApiTags.DISTRICT_DELETED,
        DistrictApiTags.DISTRICT_MODIFIED,
      ],
      transformResponse: (response: ListingResponse<DistrictResponse[]>) => {
        return response
      },
    }),

    // Add District
    addDistrict: builder.mutation<AddDistrictResponse, FormData>({
      query: (params: FormData) => ({
        url: API_ENDPOINTS.DISTRICT,
        method: API_METHODS.POST,
        body: params,
      }),
      invalidatesTags: [DistrictApiTags.DISTRICT_CREATED],
      transformResponse: (response: AddDistrictResponse) => {
        return response
      },
    }),

    // Delete District
    deleteDistrict: builder.mutation<
      DeleteDistrictResponse,
      DeleteDistrictPayload
    >({
      query: (params: DeleteDistrictPayload) => ({
        url: API_ENDPOINTS.DISTRICT,
        method: API_METHODS.DELETE,
        params: params,
      }),
      invalidatesTags: [DistrictApiTags.DISTRICT_DELETED],
      transformResponse: (response: DeleteDistrictResponse) => {
        return response
      },
    }),

    // Edit District
    editDistrict: builder.mutation<EditDistrictResponse, EditDistrictPayload>({
      query: ({ id, ...params }: EditDistrictPayload) => ({
        url: API_ENDPOINTS.DISTRICT, // Assuming the ID is part of the URL
        method: API_METHODS.PUT,
        body: params,
        params: {
          id,
        },
      }),
      invalidatesTags: [
        DistrictApiTags.DISTRICT_MODIFIED,
        DistrictApiTags.DISTIRCT_SINGLE,
      ],
    }),

    // single district
    getSingleDistrict: builder.query<
      SingleDistrictResponse,
      SingleDistrictPayload
    >({
      query: (params: SingleDistrictPayload) => ({
        url: API_ENDPOINTS.DISTRICT, // The ID is part of the URL
        method: API_METHODS.GET,
        params: params,
      }),
      providesTags: [DistrictApiTags.DISTIRCT_SINGLE],
      transformResponse: (response: SingleDistrictResponse) => {
        return response
      },
    }),
  }),
})

export const {
  useDistrictDetailsQuery,
  useAddDistrictMutation,
  useDeleteDistrictMutation,
  useGetSingleDistrictQuery,
  useEditDistrictMutation,
  useDistrictDetailsByStateQuery,
} = districtApi
