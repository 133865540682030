import HomeIcon from '@mui/icons-material/Home'

import { Box, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { APP_CONFIG } from '../../../constants/contants'
import DataTable from './DataTable'
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../../utils/toastMessage'
import { setListingQueryParams } from '../../../store/slice/commonSlice'
import ViewApprovedUserModal from './ViewApprovedUserModal'
import {
  useApprovedUsersDetailsQuery,
  useDeleteApprovedUserMutation,
} from '../../../api/userApi/approvedUsersApi'
import { DeleteApprovedUserResponse } from '../../../api/userApi/approvedUsersApi/types'

const ApprovedInvitation = () => {
  const dispatch = useDispatch<AppDispatch>()

  // Listing Query Params
  const listingQueryParams = useSelector(
    (state: RootState) => state.commonReducer.listingQueryParams,
  )
  const { approvedUsers: approvedUsersQueryParams } = listingQueryParams

  // Page
  const [page, setPage] = useState(APP_CONFIG.DEFAULT_PAGE)
  const [pageSize, setPageSize] = useState(APP_CONFIG.DEFAULT_PAGE_SIZE)

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage + 1)
  }

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(APP_CONFIG.DEFAULT_PAGE)
  }

  // Search Value
  const [search, setSearch] = useState('')

  const handleSearchValue = (value: string) => {
    setSearch(value)
  }

  // View Modal
  const [openViewModal, setOpenViewModal] = useState({
    id: 0,
    open: false,
  })

  const handleOpenViewModal = (id: number) => {
    setOpenViewModal({
      id: id,
      open: true,
    })
  }

  const handleCloseViewModal = () => {
    setOpenViewModal({
      id: 0,
      open: false,
    })
  }

  // Delete Modal

  const [deleteApprovedUser] = useDeleteApprovedUserMutation()

  const handleOpenDeleteModal = (id: number) => {
    Swal.fire({
      title: 'Are you sure you want to delete approved user?',
      icon: 'warning',
      showCancelButton: true,
      customClass: {
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button',
        title: 'custom-title',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteApprovedUser({
          id: id,
        })
          .unwrap()
          .then((data: DeleteApprovedUserResponse) => {
            if (data?.success) {
              toastSuccessMessage('Approved User has been deleted successfully')
            } else {
              toastErrorMessage(
                data?.message || 'Unable to Delete Approved User',
              )
            }
          })
          .catch((error) => {
            toastErrorMessage(
              error?.data?.message || 'Unable to Delete Approved User',
            )
          })
      }
    })
  }

  const { data, isFetching, isSuccess } = useApprovedUsersDetailsQuery(
    approvedUsersQueryParams,
  )

  useEffect(() => {
    dispatch(
      setListingQueryParams({
        ...listingQueryParams,
        approvedUsers: {
          q: search,
          page: page,
          page_size: pageSize,
        },
      }),
    )
  }, [search, page, pageSize])

  return (
    <>
      {/* Table */}
      <Box>
        <DataTable
          headers={[
            '#',
            'Full Name',
            'Mobile Number',
            'State',
            'District',
            'Area',
            'DOB',
            'Status',
            'Actions',
          ]}
          data={data?.results}
          totalPages={data?.count}
          page={page}
          rowsPerPage={pageSize}
          handlePageChange={handlePageChange}
          handleSearchValue={handleSearchValue}
          handleOpenEditModal={() => {}}
          handleOpenDeleteModal={handleOpenDeleteModal}
          handleOpenViewModal={handleOpenViewModal}
          handleChangeRowsPerPage={handleChangePageSize}
          isSuccess={isSuccess && !isFetching}
        />
      </Box>

      {/* View Modal */}
      {!!openViewModal?.id && (
        <ViewApprovedUserModal
          id={openViewModal?.id}
          openModal={openViewModal?.open}
          handleCloseModal={handleCloseViewModal}
        />
      )}
    </>
  )
}

export default ApprovedInvitation
