import { FC } from 'react'
import { Breadcrumbs, Typography, Link } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Link as LinkRouterDom } from 'react-router-dom'
import { BreadcrumbItemProps } from './types'

const BreadcrumbItem: FC<BreadcrumbItemProps> = ({ to, text, icon }) => {
  return to ? (
    <Link
      component={LinkRouterDom}
      underline='none'
      sx={{ display: 'flex', alignItems: 'center' }}
      color='primary.main'
      to={to}
    >
      {icon}
    </Link>
  ) : (
    <Typography variant='body2' sx={{ display: 'flex', alignItems: 'center' }}>
      {icon}
      {text}
    </Typography>
  )
}

interface BreadcrumbsComponentProps {
  items: BreadcrumbItemProps[]
}

const BreadcrumbsComponent = ({ items }: BreadcrumbsComponentProps) => {
  return (
    <Breadcrumbs
      sx={{ justifyContent: 'flex-end' }}
      separator={<ChevronRightIcon fontSize='small' />}
    >
      {items.map((item, index) => (
        <BreadcrumbItem
          key={index}
          to={item.to}
          text={item.text}
          icon={item.icon}
        />
      ))}
    </Breadcrumbs>
  )
}

export default BreadcrumbsComponent
