import './App.css'
import './styles/css/Style.css'
import Routes from './routes/routes'
import { CssBaseline, ThemeProvider } from '@mui/material'
import theme from './styles/theme/theme'
import { Provider } from 'react-redux'
import store from './store'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <Routes />
          <ToastContainer />
        </Provider>
      </ThemeProvider>
    </>
  )
}

export default App
