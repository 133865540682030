import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import useEditModal from './useEditModal'
import InputField from '../../../components/InputField'
import RadioFieldInput from '../../../components/RadioFieldInput'
import ButtonField from '../../../components/ButtonField'
import DateTimeField from '../../../components/DateTimeField'
import SelectField from '../../../components/SelectField'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDistrictDetailsQuery } from '../../../api/districtApi'
import { RootState } from '../../../store'
import { setListingQueryParams } from '../../../store/slice/commonSlice'
import { APP_CONFIG } from '../../../constants/contants'
import RoundedImageUploader from '../../../components/RoundedImageUploader'
import FileInput from '../../../components/FileInput'
import { EditGiftModalProps } from './types'

const EditGiftModal: React.FC<EditGiftModalProps> = ({
  id,
  openModal,
  handleCloseModal,
}) => {
  const {
    isLoading,
    control,
    handleSubmit,
    onSubmit,
    giftImage,
    handleGiftImage,
    isDataLoading,
    errors,
  } = useEditModal({
    id,
    openModal,
    handleCloseModal,
  })

  const dispatch = useDispatch()

  return (
    <Dialog
      fullWidth
      sx={{
        '.MuiDialog-paper': {
          borderRadius: 4,
        },
      }}
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      component='form'
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* Title */}
      <DialogTitle
        sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }}
        id='customized-dialog-title'
      >
        Edit Gift
      </DialogTitle>

      {/* Dialog box close button */}
      <IconButton
        aria-label='close'
        onClick={handleCloseModal}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        {isDataLoading ? (
          <Box textAlign='center'>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={1}>
            {/* Gift Image Image Field */}
            <Grid item xs={12}>
              <FileInput
                value={giftImage}
                error={errors.isGiftImage ? errors.isGiftImage.message : ''}
                onChange={handleGiftImage}
              />
            </Grid>

            {/* Gift Name Field */}
            <Grid item xs={12} sm={6}>
              <InputField
                name={'giftName'}
                control={control}
                label={'Gift Name'}
                fullWidth
                errors={!!errors.giftName}
                helperText={errors.giftName ? errors.giftName.message : ''}
                required
              />
            </Grid>

            {/* Points Field */}
            <Grid item xs={12} sm={6}>
              <InputField
                name={'points'}
                control={control}
                label={'Points'}
                type='number'
                fullWidth
                required
                errors={!!errors.points}
                helperText={errors.points ? errors.points.message : ''}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <ButtonField
          variant='contained'
          onClick={handleCloseModal}
          buttonText={'Cancel'}
          sx={{
            backgroundColor: 'primary.main',
          }}
        />

        <ButtonField
          type='submit'
          loading={isLoading}
          variant='contained'
          buttonText={'Save'}
          sx={{
            backgroundColor: 'ternary.main',
            '&:hover': {
              backgroundColor: 'ternary.dark',
            },
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export default EditGiftModal
