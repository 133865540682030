import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { EditModalForm, EditModalProps } from './types'
import { editModalValidationSchema } from './validation'
import {
  useEditVisitingCardEligibilityLimitMutation,
  useGetSingleVisitingCardEligibilityQuery,
} from '../../../../api/visitingCardEligibilityLimit'
import { useEffect } from 'react'
import { EditVisitingCardEligibilityLimitResponse } from '../../../../api/visitingCardEligibilityLimit/types'
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../../../utils/toastMessage'

const useEditModal = ({ id, openModal, handleCloseModal }: EditModalProps) => {
  // Get Visiting Card
  const {
    data: singleVisitingCardLimitData,
    isSuccess: isSingleVisitingCardLimitSuccess,
    isFetching: isSingleVisitingCardLimitFetching,
  } = useGetSingleVisitingCardEligibilityQuery(
    {
      id: id,
    },
    {
      skip: !openModal, // Skip the query if the modal is not open
    },
  )

  // Edit Visiting Card
  const [editVisitingCardLimit, { isLoading }] =
    useEditVisitingCardEligibilityLimitMutation()

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<EditModalForm>({
    defaultValues: {
      state: undefined,
      district: undefined,
      points: 0,
    },
    resolver: yupResolver(editModalValidationSchema),
  })

  useEffect(() => {
    if (singleVisitingCardLimitData?.data) {
      setValue('state', singleVisitingCardLimitData?.data?.state_id)
      setValue('district', singleVisitingCardLimitData?.data?.district_id)
      setValue('points', singleVisitingCardLimitData?.data?.points)
    }
  }, [singleVisitingCardLimitData?.data, setValue])

  const onSubmit: SubmitHandler<EditModalForm> = async (data) => {
    const formData = {
      id: singleVisitingCardLimitData?.data?.id || 0,
      state_id: data?.state,
      district_id: data?.district,
      points: data?.points,
    }
    editVisitingCardLimit(formData)
      .unwrap()
      .then((data: EditVisitingCardEligibilityLimitResponse) => {
        if (data?.success) {
          toastSuccessMessage(
            'Visiting Card Eligibility Limit Updated Successfully',
          )
          handleCloseModal()
        } else {
          toastErrorMessage(
            data?.message ||
              'Unable to Updated Visiting Card Eligibility Limit',
          )
        }
      })
      .catch((error) => {
        toastErrorMessage(
          error?.data?.message ||
            'Unable to Updated Visiting Card Eligibility Limit',
        )
      })
  }

  return {
    control,
    register,
    errors,
    reset,
    onSubmit,
    handleSubmit,
    isLoading,
    isDataLoading:
      isSingleVisitingCardLimitFetching && !isSingleVisitingCardLimitSuccess,
    watch,
  }
}

export default useEditModal
