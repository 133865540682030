import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { authlogin } from './authActions'
import { localstoragekeys } from '../../../constants/contants'

export type TAuthState = {
  username: string
  isAuthenticated: boolean
  id: string
  loading: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string | null
}

const initialState: TAuthState = {
  username: localStorage.getItem(localstoragekeys.username) || '',
  isAuthenticated: !!localStorage.getItem(localstoragekeys.username) || false,
  id: localStorage.getItem(localstoragekeys.id) || '',
  loading: 'idle',
  error: null,
}

export const authSlice = createSlice({
  name: 'authSlice',
  initialState: initialState,
  reducers: {
    authlogout: (state) => {
      state.isAuthenticated = false
      state.username = ''
      state.id = ''
      state.error = null
      state.loading = 'idle'

      localStorage.clear()
    },
    resetState: (state) => {
      state.error = null
      state.loading = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authlogin.pending, (state, action) => {
        state.loading = 'pending'
        state.username = ''
        state.isAuthenticated = false
        state.id = ''
        state.error = ''

        localStorage.clear()
      })
      .addCase(authlogin.fulfilled, (state, action) => {
        state.loading = 'succeeded'
        state.username = action.payload.username
        state.id = action.payload.admin_id
        state.isAuthenticated = true
        state.error = ''

        localStorage.setItem(localstoragekeys.username, state.username)
        localStorage.setItem(localstoragekeys.id, state.id)
      })
      .addCase(authlogin.rejected, (state, action: any) => {
        state.loading = 'failed'
        state.username = ''
        state.id = ''
        state.isAuthenticated = false
        state.error = action?.payload || ''

        localStorage.clear()
      })
  },
})

export const { authlogout, resetState } = authSlice.actions

export default authSlice.reducer
