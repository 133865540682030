export const EMAILREGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export const localstoragekeys = {
  username: 'username',
  id: 'id',
}

export const dashboardCardsColorList = {
  deepPurple: 'rgb(103, 58, 183)',
  blue: 'rgb(30, 136, 229)',
  red: 'rgb(216, 67, 21)',
  teal: 'rgb(0, 150, 136)',
  indigo: 'rgb(63, 81, 181)',
  pink: 'rgb(233, 30, 99)',
  lightBlue: 'rgb(33, 150, 243)',
  green: 'rgb(76, 175, 80)',
}

export const drawerWidth = 260

export const API_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
}

export const APP_CONFIG = {
  DEFAULT_PAGE_SIZE: 10,
  DEFAULT_PAGE: 1,
}

export const SELECT_ID_TYPE = {
  AADHAR: 'aadhar',
  PAN: 'pan',
}

export const USER_STATUS = {
  APPROVED: 'approved',
  DENIED: 'deny',
}

export const REDEEM_STATUS = {
  APPROVED: 'approved',
  DECLINED: 'denied',
}
