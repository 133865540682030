import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AddModalForm, AddModalProps } from './types'
import { addModalValidationSchema } from './validation'
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../../utils/toastMessage'
import { useAddStateMutation } from '../../../api/stateApi'
import { AddStateResponse } from '../../../api/stateApi/types'

const useAddModal = ({ handleCloseModal }: AddModalProps) => {
  const [addState, { isLoading }] = useAddStateMutation()

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<AddModalForm>({
    defaultValues: {
      state: '',
    },
    resolver: yupResolver(addModalValidationSchema),
  })

  const onSubmit: SubmitHandler<AddModalForm> = async (data) => {
    const formData = new FormData()
    formData.append('state_name', data?.state)

    addState(formData)
      .unwrap()
      .then((data: AddStateResponse) => {
        if (data?.success) {
          toastSuccessMessage('State Added Successfully')
          handleCloseModal()
        } else {
          toastErrorMessage(data?.message || 'Unable to Add State')
        }
      })
      .catch((error) => {
        toastErrorMessage(error?.data?.message || 'Unable to Add State')
      })
  }

  return {
    control,
    register,
    errors,
    reset,
    onSubmit,
    handleSubmit,
    isLoading,
  }
}

export default useAddModal
