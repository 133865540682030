import {
  Box,
  CssBaseline,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import LoginImageBG from '../../assets/images/loginimagebg.jpg'
import InputField from '../../components/InputField'
import useLogin from './useLogin'
import ButtonField from '../../components/ButtonField'

const Login = () => {
  const { control, errors, isLoading, onSubmit, handleSubmit } = useLogin()

  const theme = useTheme()
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <>
      <Grid container component='main' sx={{ height: '100vh' }}>
        <CssBaseline />

        <Grid
          item
          xs={false}
          md={7}
          sx={{
            backgroundImage: `url(${LoginImageBG})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />

        <Grid
          item
          xs={12}
          md={5}
          component={Paper}
          elevation={6}
          square
          sx={{
            [theme.breakpoints.down('md')]: {
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: `url(${LoginImageBG})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                opacity: 0.15, // Set your desired opacity
              },
            },
          }}
        >
          <Box
            sx={{
              mt: 14,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography
              variant={isMediumScreen ? 'h5' : 'h4'}
              fontWeight={'bold'}
              color={'primary.main'}
              gutterBottom
            >
              Hi, Welcome Back!
            </Typography>

            <Typography variant='body1'>
              Enter your credentials to continue
            </Typography>

            <Box
              component='form'
              noValidate
              width={'100%'}
              onSubmit={handleSubmit(onSubmit)}
              sx={{ mt: 1 }}
            >
              <InputField
                name='userName'
                control={control}
                label='Email'
                placeholder='Enter your email here...'
                errors={!!errors?.userName}
                helperText={errors.userName ? errors.userName.message : ''}
                required
                fullWidth
              />

              <InputField
                name='password'
                label='Password'
                type='password'
                control={control}
                placeholder='Enter your password here...'
                errors={!!errors?.password}
                helperText={errors.password ? errors.password.message : ''}
                fullWidth
                required
              />

              <ButtonField
                type='submit'
                fullWidth
                loading={isLoading}
                variant='contained'
                sx={{ mt: 3, mb: 2, py: 1, fontWeight: 'bold' }}
                buttonText={'Sign In'}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default Login
