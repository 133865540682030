import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ListingResponse } from '../types'
import { API_METHODS } from '../../constants/contants'
import { API_ENDPOINTS } from '../../constants/apiendpoints'
import { QRCodeApiTags } from './tags'
import {
  AddQRCodeResponse,
  EditQRCodeResponse,
  QRCodePayload,
  QRCodeResponse,
  QRCodeTokenResponse,
  SingleQRCodePayload,
  SingleQRCodeResponse,
} from './types'

export const qrCodeApi = createApi({
  reducerPath: 'qrCodeApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: [
    QRCodeApiTags.QRCODE_CREATED,
    QRCodeApiTags.QRCODE_DELETED,
    QRCodeApiTags.QRCODE_MODIFIED,
    QRCodeApiTags.QRCODE_SINGLE,
  ],
  endpoints: (builder) => ({
    // Get All QR Code
    qrCodeDetails: builder.query<
      ListingResponse<QRCodeResponse[]>,
      QRCodePayload
    >({
      query: (params: QRCodePayload) => {
        return {
          url: API_ENDPOINTS.GET_ALL_QRCODE,
          method: API_METHODS.GET,
          params,
        }
      },
      providesTags: [
        QRCodeApiTags.QRCODE_CREATED,
        QRCodeApiTags.QRCODE_DELETED,
        QRCodeApiTags.QRCODE_MODIFIED,
      ],
      transformResponse: (response: ListingResponse<QRCodeResponse[]>) => {
        return response
      },
    }),

    // Add QRCode
    addQRCode: builder.mutation<AddQRCodeResponse, FormData>({
      query: (params: FormData) => ({
        url: API_ENDPOINTS.ADD_QRCODE,
        method: API_METHODS.POST,
        body: params,
      }),
      invalidatesTags: [QRCodeApiTags.QRCODE_CREATED],
      transformResponse: (response: AddQRCodeResponse) => {
        return response
      },
    }),

    // Edit QR Code
    editQRCode: builder.mutation<
      EditQRCodeResponse,
      { id: number; formData: FormData }
    >({
      query: ({ id, formData }) => ({
        url: API_ENDPOINTS.UPDATE_QRCODE, // Assuming the ID is part of the URL
        method: API_METHODS.POST,
        body: formData,
        params: {
          id,
        },
      }),
      invalidatesTags: [
        QRCodeApiTags.QRCODE_MODIFIED,
        QRCodeApiTags.QRCODE_SINGLE,
      ],
    }),

    // single qr code
    getSingleQRCode: builder.query<SingleQRCodeResponse, SingleQRCodePayload>({
      query: (params: SingleQRCodePayload) => ({
        url: API_ENDPOINTS.GET_QRCODE_BY_ID, // The ID is part of the URL
        method: API_METHODS.GET,
        params: params,
      }),
      providesTags: [QRCodeApiTags.QRCODE_SINGLE],
      transformResponse: (response: SingleQRCodeResponse) => {
        return response
      },
    }),

    // qr code token
    getQRCodeToken: builder.query<QRCodeTokenResponse, void>({
      query: () => ({
        url: API_ENDPOINTS.GET_QRCODE_TOKEN, // The ID is part of the URL
        method: API_METHODS.GET,
      }),
      transformResponse: (response: QRCodeTokenResponse) => {
        return response
      },
    }),
  }),
})

export const {
  useAddQRCodeMutation,
  useEditQRCodeMutation,
  useGetSingleQRCodeQuery,
  useQrCodeDetailsQuery,
  useGetQRCodeTokenQuery,
} = qrCodeApi
