import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ListingResponse } from '../types'
import {
  SingleVisitingCardRecordsPayload,
  SingleVisitingCardRecordsResponse,
  VisitingCardRecordsPayload,
  VisitingCardRecordsResponse,
} from './types'
import { API_METHODS } from '../../constants/contants'
import { API_ENDPOINTS } from '../../constants/apiendpoints'
import { VisitingCardRecordsApiTags } from './tags'

export const visitingCardRecordsApi = createApi({
  reducerPath: 'visitingCardRecordsApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: [
    VisitingCardRecordsApiTags.VISITING_CARD_RECORDS_CREATED,
    VisitingCardRecordsApiTags.VISITING_CARD_RECORDS_DELETED,
    VisitingCardRecordsApiTags.VISITING_CARD_RECORDS_MODIFIED,
    VisitingCardRecordsApiTags.VISITING_CARD_RECORDS_SINGLE,
  ],
  endpoints: (builder) => ({
    // Get All Visiting Card Records
    visitingCardRecordsDetails: builder.query<
      ListingResponse<VisitingCardRecordsResponse[]>,
      VisitingCardRecordsPayload
    >({
      query: (params: VisitingCardRecordsPayload) => {
        return {
          url: API_ENDPOINTS.GET_ALL_VISITING_CARD_RECORDS,
          method: API_METHODS.GET,
          params,
        }
      },
      providesTags: [
        VisitingCardRecordsApiTags.VISITING_CARD_RECORDS_CREATED,
        VisitingCardRecordsApiTags.VISITING_CARD_RECORDS_DELETED,
        VisitingCardRecordsApiTags.VISITING_CARD_RECORDS_MODIFIED,
      ],
      transformResponse: (
        response: ListingResponse<VisitingCardRecordsResponse[]>,
      ) => {
        return response
      },
    }),

    // single Visiting Card Records
    getSingleVisitingCardRecords: builder.query<
      SingleVisitingCardRecordsResponse,
      SingleVisitingCardRecordsPayload
    >({
      query: (params: SingleVisitingCardRecordsPayload) => ({
        url: API_ENDPOINTS.GET_SINGLE_VISITING_CARD_RECORDS, // The ID is part of the URL
        method: API_METHODS.GET,
        params: {
          mobile_number: params.id,
        },
      }),
      providesTags: [VisitingCardRecordsApiTags.VISITING_CARD_RECORDS_SINGLE],
      transformResponse: (response: SingleVisitingCardRecordsResponse) => {
        return response
      },
    }),
  }),
})

export const {
  useGetSingleVisitingCardRecordsQuery,
  useVisitingCardRecordsDetailsQuery,
} = visitingCardRecordsApi
