import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ILoginFormInput } from './types'
import { loginValidationSchema } from './validation'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { authlogin } from '../../store/slice/auth/authActions'
import {
  toastErrorMessage,
} from '../../utils/toastMessage'
import { resetState } from '../../store/slice/auth/authSlice'

const useLogin = () => {
  const [isLoading, setLoading] = useState(false)

  const dispatch = useDispatch<AppDispatch>()
  const { error, loading } = useSelector(
    (state: RootState) => state.authReducer,
  )

  useEffect(() => {
    setLoading(loading === 'pending')
    if (!!error) {
      toastErrorMessage(error)
      dispatch(resetState())
    }
  }, [error, loading])

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ILoginFormInput>({
    defaultValues: {
      userName: '',
      password: '',
    },
    resolver: yupResolver(loginValidationSchema),
  })

  const onSubmit: SubmitHandler<ILoginFormInput> = (data: ILoginFormInput) => {
    const formData = new FormData()
    formData.append('email', data?.userName)
    formData.append('password', data?.password)
    dispatch(authlogin(formData))
  }

  return {
    control,
    register,
    errors,
    onSubmit,
    handleSubmit,
    isLoading,
  }
}

export default useLogin
