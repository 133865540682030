import { FC, useState, useEffect } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined'
import { RoundedImageUploaderProps } from './types'

const RoundedImageUploader: FC<RoundedImageUploaderProps> = ({
  onChange,
  readOnly = false,
  error,
  value,
}) => {
  const [isDragging, setIsDragging] = useState(false)
  const [preview, setPreview] = useState<string | null>(null)
  const [isHovered, setIsHovered] = useState(false)

  useEffect(() => {
    if (value) {
      setPreview(typeof value === 'string' ? value : URL.createObjectURL(value))
    } else {
      setPreview(null) // Clear preview when value is reset
    }

    // Clean up URL.createObjectURL if used
    return () => {
      if (typeof value !== 'string' && value instanceof File) {
        URL.revokeObjectURL(preview || '')
      }
    }
  }, [value])

  const handleDragOver = (event: React.DragEvent) => {
    if (readOnly) return
    event.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = () => {
    if (readOnly) return
    setIsDragging(false)
  }

  const handleDrop = (event: React.DragEvent) => {
    if (readOnly) return
    event.preventDefault()
    setIsDragging(false)
    const file = event.dataTransfer.files[0]
    if (file) {
      handleFile(file)
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (readOnly) return
    const file = event.target.files?.[0]
    if (file) {
      handleFile(file)
    }
  }

  const handleFile = (file: File) => {
    if (onChange) {
      onChange(file)
    }
    const reader = new FileReader()
    reader.onloadend = () => {
      setPreview(reader.result as string)
    }
    reader.readAsDataURL(file)
  }

  return (
    <Stack display={'flex'} flexDirection={'column'} alignItems={'center'}>
      {!readOnly && (
        <input
          accept='image/*'
          id='profile-image-uploader'
          type='file'
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      )}
      <label
        htmlFor='profile-image-uploader'
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onMouseEnter={() => !readOnly && setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          display: 'inline-block',
          cursor: readOnly ? 'not-allowed' : 'pointer',
        }}
      >
        <Box
          sx={{
            width: 150,
            height: 150,
            borderRadius: '50%',
            border: isDragging ? '1px solid' : '1px dashed',
            borderColor: 'secondary.main',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            mb: 2,
            backgroundColor: 'secondary.light',
            transition: 'background-color 0.2s, border-color 0.2s',
            overflow: 'hidden',
          }}
        >
          {preview ? (
            <>
              <img
                src={preview}
                alt='Preview'
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
              {isHovered && !readOnly && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Stack
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    color={'secondary.main'}
                  >
                    <PhotoCameraOutlinedIcon fontSize='large' />
                    <Typography variant='body2'>Update</Typography>
                  </Stack>
                </Box>
              )}
            </>
          ) : (
            <Stack
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              color={'secondary.main'}
            >
              <PhotoCameraOutlinedIcon fontSize='large' />
              <Typography variant='body2'>Upload Photo</Typography>
            </Stack>
          )}
        </Box>
      </label>
      {error && (
        <Typography variant='body2' color='error' align='center'>
          {error}
        </Typography>
      )}
    </Stack>
  )
}

export default RoundedImageUploader
