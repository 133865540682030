import DashboardIcon from '@mui/icons-material/Dashboard'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import PeopleIcon from '@mui/icons-material/People'
import StoreIcon from '@mui/icons-material/Store'
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import ContactPageIcon from '@mui/icons-material/ContactPage'
import EventIcon from '@mui/icons-material/Event'
import AssessmentIcon from '@mui/icons-material/Assessment'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined'
import QrCodeIcon from '@mui/icons-material/QrCode'

import routeUrls from '../../../../constants/routeUrls'

interface ItemDetail {
  title: string
  to: string
  Icon: React.ComponentType<any>
}

interface MenuItem {
  title: string
  item: ItemDetail[]
}

export const menuList: MenuItem[] = [
  {
    title: 'Dashboard',
    item: [
      {
        title: 'Overview',
        to: routeUrls.DASHBOARD,
        Icon: DashboardIcon,
      },
    ],
  },
  {
    title: 'Locations & Visiting Cards',
    item: [
      {
        title: 'State List',
        to: routeUrls.STATELISTMANAGEMENT,
        Icon: PublicOutlinedIcon,
      },
      {
        title: 'District List',
        to: routeUrls.DISTRICTLISTMANANGEMENT,
        Icon: LocationCityIcon,
      },
      {
        title: 'Visiting Cards',
        to: routeUrls.VISITINGCARDMANANGEMENT,
        Icon: ContactPageIcon,
      },
    ],
  },
  {
    title: 'Administration',
    item: [
      {
        title: 'District Admins',
        to: routeUrls.DISTRICTADMINSMANAGEMENT,
        Icon: AdminPanelSettingsIcon,
      },
      {
        title: 'Users Management',
        to: routeUrls.USERSMANAGEMENT,
        Icon: PeopleIcon,
      },
    ],
  },
  {
    title: 'Products & Gifts',
    item: [
      {
        title: 'Products Management',
        to: routeUrls.PRODUCTSMANANGEMENT,
        Icon: StoreIcon,
      },
      {
        title: 'Gifts Management',
        to: routeUrls.GIFTSMANANGEMENT,
        Icon: CardGiftcardIcon,
      },
      {
        title: 'Reedem Points',
        to: routeUrls.REDEEMPOINTSMANANGEMENT,
        Icon: SwapHorizIcon,
      },
    ],
  },
  {
    title: 'QR Code & Events Management',
    item: [
      {
        title: 'QR Code Management',
        to: routeUrls.QRCODEMANAGEMENT,
        Icon: QrCodeIcon,
      },
      {
        title: 'Events Management',
        to: routeUrls.EVENTSMANANGEMENT,
        Icon: EventIcon,
      },
    ],
  },
  {
    title: 'Reports',
    item: [
      {
        title: 'User Reports',
        to: routeUrls.USERSREPORTS,
        Icon: AssessmentIcon,
      },
    ],
  },
]
