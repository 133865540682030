import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { AddModalForm, AddModalProps } from './types'
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../../utils/toastMessage'
import { addModalValidationSchema } from './validation'
import { useAddEventMutation } from '../../../api/eventsApi'
import { AddEventResponse } from '../../../api/eventsApi/types'
import { formatDateToYYYYMMDD, formatTimeToHHMMSS } from '../../../utils/common'

const useAddModal = ({ handleCloseModal }: AddModalProps) => {
  const [addEvent, { isLoading }] = useAddEventMutation()
  const [eventImage, setEventImage] = useState<File | null>()

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm<AddModalForm>({
    defaultValues: {
      isEventImage: false,
      title: '',
      description: '',
      videoUrl: '',
      date: '',
      time: '',
    },
    resolver: yupResolver(addModalValidationSchema),
  })

  // Handle Event Image Field
  const handleEventImage = (file: File) => {
    const reader = new FileReader()

    setEventImage(file)
    setValue('isEventImage', true, {
      shouldValidate: true,
    })

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  const resetForm = () => {
    setEventImage(null)
    reset()
  }

  const onSubmit: SubmitHandler<AddModalForm> = async (data) => {
    if (!eventImage) {
      return
    }

    const formData = new FormData()
    formData.append('photo', eventImage)
    formData.append('title', data?.title)
    formData.append('description', data?.description || '')
    formData.append('video_link', data?.videoUrl)
    formData.append('date', formatDateToYYYYMMDD(data?.date)) // Add date field
    formData.append('time', formatTimeToHHMMSS(data?.time)) // Add time field

    addEvent(formData)
      .unwrap()
      .then((data: AddEventResponse) => {
        if (data?.success) {
          toastSuccessMessage('Event Added Successfully')
          handleCloseModal()
        } else {
          toastErrorMessage(data?.message || 'Unable to Add Event')
        }
      })
      .catch((error) => {
        toastErrorMessage(error?.data?.message || 'Unable to Add Event')
      })
  }

  return {
    control,
    register,
    errors,
    reset: resetForm,
    onSubmit,
    handleSubmit,
    isLoading,
    eventImage,
    handleEventImage,
  }
}

export default useAddModal
