import { Box, Button } from '@mui/material'
import DataTable from './DataTable'
import { AppDispatch, RootState } from '../../../store'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { APP_CONFIG } from '../../../constants/contants'
import ViewVisitingCardRecordsModal from './ViewVisitingCardRecordsModal'
import { useVisitingCardRecordsDetailsQuery } from '../../../api/visitingCardRecords'
import { setListingQueryParams } from '../../../store/slice/commonSlice'

const VisitingCardRecords = () => {
  const dispatch = useDispatch<AppDispatch>()

  // Listing Query Params
  const listingQueryParams = useSelector(
    (state: RootState) => state.commonReducer.listingQueryParams,
  )
  const { visitingCardRecords: visitingCardRecordsQueryParams } =
    listingQueryParams

  // Page
  const [page, setPage] = useState(APP_CONFIG.DEFAULT_PAGE)
  const [pageSize, setPageSize] = useState(APP_CONFIG.DEFAULT_PAGE_SIZE)

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage + 1)
  }

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(APP_CONFIG.DEFAULT_PAGE)
  }

  // Search Value
  const [search, setSearch] = useState('')

  const handleSearchValue = (value: string) => {
    setSearch(value)
  }

  // View Modal
  const [openViewModal, setOpenViewModal] = useState({
    id: '',
    open: false,
  })

  const handleOpenViewModal = (id: string) => {
    setOpenViewModal({
      id: id,
      open: true,
    })
  }

  const handleCloseViewModal = () => {
    setOpenViewModal({
      id: '',
      open: false,
    })
  }

  const { data, isFetching, isSuccess } = useVisitingCardRecordsDetailsQuery(
    visitingCardRecordsQueryParams,
  )

  useEffect(() => {
    dispatch(
      setListingQueryParams({
        ...listingQueryParams,
        visitingCardRecords: {
          q: search,
          page: page,
          page_size: pageSize,
        },
      }),
    )
  }, [search, page, pageSize])

  return (
    <>
      {/* Table */}
      <Box>
        <DataTable
          headers={[
            '#',
            'Language',
            'Full Name',
            'Mobile No.',
            'Address',
            'Created At',
            'Actions',
          ]}
          data={data?.results}
          totalPages={data?.count}
          page={page}
          rowsPerPage={pageSize}
          handlePageChange={handlePageChange}
          handleSearchValue={handleSearchValue}
          handleOpenViewModal={handleOpenViewModal}
          handleChangeRowsPerPage={handleChangePageSize}
          isSuccess={isSuccess && !isFetching}
        />
      </Box>

      {/* View Modal */}
      {!!openViewModal?.id && (
        <ViewVisitingCardRecordsModal
          id={openViewModal?.id}
          openModal={openViewModal?.open}
          handleCloseModal={handleCloseViewModal}
        />
      )}
    </>
  )
}

export default VisitingCardRecords
