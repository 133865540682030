import { FC, useEffect } from 'react'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ViewDistrictModalProps } from './types'
import InputField from '../../../components/InputField'
import { useForm } from 'react-hook-form'
import { DistrictForm } from './types'
import { useGetSingleDistrictQuery } from '../../../api/districtApi'
import SelectField from '../../../components/SelectField'
import { useStateDetailsQuery } from '../../../api/stateApi'
import { RootState } from '../../../store'
import { useSelector } from 'react-redux'

const ViewDistrictModal: FC<ViewDistrictModalProps> = ({
  id,
  openModal,
  handleCloseModal,
}) => {
  // Fetching District Data
  const { data, isSuccess, isFetching } = useGetSingleDistrictQuery(
    {
      id: id,
    },
    {
      skip: !openModal, // Skip the query if the modal is not open
    },
  )

  const { control, setValue } = useForm<DistrictForm>({
    defaultValues: {
      id: 0,
      district: '',
    },
  })

  // Listing Query Params
  const listingQueryParams = useSelector(
    (state: RootState) => state.commonReducer.listingQueryParams,
  )
  const { state: stateQueryParams } = listingQueryParams

  const {
    data: stateList,
    isFetching: isStateFetching,
    isSuccess: isStateSuccess,
  } = useStateDetailsQuery(stateQueryParams, {
    skip: !openModal,
  })

  useEffect(() => {
    if (data?.data) {
      // Set form values once data is available
      setValue('id', data.data.id)
      setValue('district', data.data.district_name)
      setValue('state', data.data.state_id)
    }
  }, [data, setValue])

  return (
    <Dialog
      fullWidth
      sx={{
        '.MuiDialog-paper': {
          borderRadius: 4,
        },
      }}
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle
        sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }}
        id='customized-dialog-title'
      >
        District Admin Details
        <IconButton
          aria-label='close'
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {isFetching || !isSuccess ? (
          <Box textAlign='center'>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={1}>
            {/* State Field */}
            <Grid item xs={12}>
              <SelectField
                label={'State'}
                name={'state'}
                options={stateList?.results}
                loading={!isStateSuccess && isStateFetching}
                control={control}
                getOptionLabel={(option) => option.state_name} // Use 'name' as the label
                getOptionValue={(option) => option.id} // Use 'id' as the value
                selectedOption={{
                  id: data?.data?.state_id,
                  state_name: data?.data?.state_name,
                }}
                required
                readOnly
              />
            </Grid>

            {/* District Field */}
            <Grid item xs={12}>
              <InputField
                name={'district'}
                control={control}
                label={'District'}
                readOnly
                fullWidth
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ViewDistrictModal
