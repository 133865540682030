import { Box, Container, Typography, Button } from '@mui/material'
import { keyframes } from '@emotion/react' // For animations
import { useNavigate } from 'react-router-dom'

// Keyframe animations
const fadeIn = keyframes`
  0% { opacity: 0; transform: translateY(10px); }
  100% { opacity: 1; transform: translateY(0); }
`

const gradientBackground = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`

const PageNotFound = () => {
  const navigate = useNavigate()

  const handleGoHome = () => {
    navigate('/')
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(120deg, #D9E1F5, #FEFFFF)',
        backgroundSize: '200% 200%',
        animation: `${gradientBackground} 6s ease infinite`,
        textAlign: 'center',
        padding: '0 20px',
      }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '600px',
          animation: `${fadeIn} 1.5s ease-in-out`,
        }}
      >
        {/* 404 Text with modern look */}
        <Typography
          variant='h1'
          sx={{
            fontSize: '10rem',
            fontWeight: 'bold',
            color: '#3f51b5',
            textShadow: '4px 4px 10px rgba(0, 0, 0, 0.1)',
            marginBottom: '0.5rem',
            '@media (max-width: 600px)': {
              fontSize: '6rem',
            },
          }}
        >
          404
        </Typography>

        {/* More styled 'Oops' text */}
        <Typography
          variant='h4'
          sx={{
            color: '#333',
            opacity: 0.9,
            fontSize: '2rem',
            marginBottom: '1rem',
            '@media (max-width: 600px)': {
              fontSize: '1.5rem',
            },
          }}
        >
          Oops! Page Not Found
        </Typography>

        {/* Additional descriptive text */}
        <Typography
          variant='body1'
          sx={{
            color: '#555',
            fontSize: '1.2rem',
            marginBottom: '2rem',
            lineHeight: '1.6',
            maxWidth: '500px',
            '@media (max-width: 600px)': {
              fontSize: '1rem',
            },
          }}
        >
          The page you're looking for might have been removed, had its name
          changed, or is temporarily unavailable.
        </Typography>

        {/* Button with light blue color and refined look */}
        <Button
          variant='contained'
          sx={{
            backgroundColor: '#90caf9', // Light blue color
            color: '#fff',
            padding: '12px 30px',
            fontSize: '1rem',
            fontWeight: '600',
            borderRadius: '8px',
            textTransform: 'none',
            transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
            boxShadow: '0 3px 8px rgba(0, 0, 0, 0.2)',
            '&:hover': {
              backgroundColor: '#64b5f6',
              boxShadow: '0 5px 15px rgba(0, 0, 0, 0.25)',
            },
          }}
          onClick={handleGoHome}
        >
          Go Home
        </Button>
      </Container>
    </Box>
  )
}

export default PageNotFound
