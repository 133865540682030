import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import EditIcon from '@mui/icons-material/Edit'
import { DataTableProps } from './types'
import { formatDateToYYYYMMDD } from '../../../utils/common'

export default function DataTable({
  headers,
  data,
  page,
  rowsPerPage,
  handlePageChange,
  handleChangeRowsPerPage,
  totalPages,
  isSuccess,
  action,
  handleOpenEditModal,
}: DataTableProps) {
  return (
    <Card elevation={0} sx={{ borderRadius: 3 }}>
      <CardHeader
        sx={{
          display: 'flex',
          flexDirection: 'column', // Stack title and action vertically on small screens
          alignItems: 'flex-start', // Align content to the start
          '.MuiCardHeader-action': {
            alignSelf: 'end',
            mr: 1,
            '@media (min-width:480px)': {
              alignSelf: 'center', // Align action to center on larger screens
            },
          },
          '@media (min-width:480px)': {
            flexDirection: 'row', // Default behavior on larger screens
            alignItems: 'center', // Align content to the center
          },
        }}
        action={action}
      />

      <Divider />

      <CardContent sx={{ px: 0, pb: 0 }}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell key={index} sx={{ fontWeight: 600 }}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isSuccess ? (
              data && data.length > 0 ? (
                data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component='th' scope='row'>
                      {(page - 1) * rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell>{row?.token}</TableCell>
                    <TableCell>{row?.points}</TableCell>
                    <TableCell>
                      {row?.is_scanned ? (
                        <Chip
                          label='Scanned'
                          sx={{
                            color: 'rgb(0, 200, 83)',
                            backgroundColor: 'rgba(185, 246, 202, 0.6)',
                          }}
                        />
                      ) : (
                        <Chip
                          label='Not Scanned'
                          sx={{
                            color: 'rgb(255, 193, 7)',
                            backgroundColor: 'rgb(255, 248, 225);',
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {formatDateToYYYYMMDD(row?.created_at)}
                    </TableCell>
                    <TableCell>
                      {formatDateToYYYYMMDD(row?.updated_at)}
                    </TableCell>
                    <TableCell sx={{ pl: 6 }}>
                      <IconButton
                        href={row?.qr_image}
                        target='_blank'
                        download
                        aria-label='download'
                      >
                        <DownloadIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <Tooltip title='Edit QRCode Details'>
                        <IconButton
                          aria-label='edit'
                          size='small'
                          onClick={() => handleOpenEditModal(row?.id)}
                          disabled={row?.is_scanned}
                        >
                          <EditIcon
                            color={row?.is_scanned ? 'disabled' : 'secondary'}
                            fontSize='inherit'
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={headers.length} align='center'>
                    No records found
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell
                  sx={{ textAlign: 'center', py: 5 }}
                  colSpan={headers.length}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>

      <CardActions
        sx={{
          justifyContent: 'end',
        }}
      >
        <TablePagination
          component='div'
          color='primary'
          count={totalPages || 0}
          page={page - 1}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CardActions>
    </Card>
  )
}
