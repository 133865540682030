import * as yup from 'yup'

export const addModalValidationSchema = yup.object().shape({
  isProfileImage: yup
    .boolean()
    .oneOf([true], 'Profile image must be uploaded')
    .required('Profile image is required'), // Ensure the field is included in the form data
  fullName: yup.string().required('Full Name is required'),
  mobileNumber: yup
    .string()
    .required('Mobile Number is required')
    .matches(
      /^[0-9]{10}$/, // Adjust this regex based on your specific requirements
      'Mobile Number must be exactly 10 digits',
    ),
  password: yup.string().required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('Confirm Password is required'),
  dob: yup.string().required('Date of Birth is required'),
  state: yup.number().required('State is required'),
  district: yup.number().required('District is required'),
  selectId: yup.string().required('Please select any one option'),
  isAadharPanImage: yup
    .boolean()
    .oneOf([true], 'Aadhar/Pan Image must be uploaded')
    .required('Aadhar/Pan Image is required'), // Ensure the field is included in the form data
})
