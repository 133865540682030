export const API_ENDPOINTS = {
  // login api`s
  LOGIN: '/admin/login.php',

  // district api`s
  DISTRICT: '/common/district/district.php',
  DISTRICTBYSTATE: '/common/district/getDistrictByState.php',

  // state
  GET_ALL_STATE: '/common/state/state.php',
  GET_STATE_BY_ID: '/common/state/state.php',
  ADD_STATE: '/common/state/state.php',
  UPDATE_STATE: '/common/state/state.php',
  DELETE_STATE: '/common/state/state.php',

  // district admin api`s
  GET_ALL_DISTRICT_ADMIN: '/district_admin/getAllDetails.php',
  GET_DISTRICT_ADMIN_BY_ID: '/district_admin/getDistrictById.php',
  ADD_DISTRICT_ADMIN: '/district_admin/district_register.php',
  UPDATE_DISTRICT_ADMIN: '/district_admin/updateDistrictById.php',
  DELETE_DISTRICT_ADMIN: '/district_admin/deleteDistrict.php',

  // approved users
  GET_ALL_APPROVED_USERS: '/user/admin/getAllApprovedDetails.php',
  DELETE_APPROVED_USER: '/user/admin/deleteApprovedById.php',
  GET_APPROVED_USER_BY_ID: '/user/admin/getApprovedById.php',

  // pending invitation users
  GET_ALL_PENDING_INVITATION_USERS: '/user/admin/getAllPendingDetails.php',
  UPDATE_PENDING_INVITATION_USERS_STATUS: '/user/admin/setStatus.php',
  GET_PENDING_INVITATION_USERS_BY_ID: '/user/admin/getPendingById.php',

  // products
  GET_ALL_PRODUCTS: '/product/product.php',
  GET_PRODUCT_BY_ID: '/product/product.php',
  ADD_PRODUCT: '/product/product.php',
  UPDATE_PRODUCT: '/product/product.php',
  DELETE_PRODUCT: '/product/product.php',

  // gift lists
  GET_ALL_GIFTS: '/gift/gift.php',
  ADD_GIFT: '/gift/gift.php',
  DELETE_GIFT: '/gift/gift.php',
  UPDATE_GIFT: '/gift/gift.php',
  GET_GIFT_BY_ID: '/gift/gift.php',

  // visiting card eligibility limit
  VISITING_CARD_ELIGIBILITY_LIMIT: '/limit/limit.php',

  // visiting card records
  GET_ALL_VISITING_CARD_RECORDS: '/visiting/getAll.php',
  GET_SINGLE_VISITING_CARD_RECORDS: '/visiting/getById.php',

  // Redeem points approved list
  GET_ALL_REDEEM_POINTS_APPROVED_LIST: '/redeem/getAllApproved.php',
  GET_SINGLE_REDEEM_POINTS_APPROVED_DETAILS: '/redeem/getApprovedById.php',

  // Redeem points declined list
  GET_ALL_REDEEM_POINTS_DECLINED_LIST: '/redeem/getAllDeny.php',
  GET_SINGLE_REDEEM_POINTS_DECLINED_DETAILS: '/redeem/getDenyById.php',

  // Redeem points pending list
  GET_ALL_REDEEM_POINTS_PENDING_LIST: '/redeem/getAllPending.php',
  GET_SINGLE_REDEEM_POINTS_PENDING_DETAILS: '/redeem/getPendingById.php',

  // Change Redeem Points Status
  EDIT_REDEEM_POINTS_STATUS: '/redeem/setStaus.php',

  // Event list
  GET_ALL_EVENTS: '/event/event.php',
  GET_EVENT_BY_ID: '/event/event.php',
  UPDATE_EVENT: '/event/event.php',
  ADD_EVENT: '/event/event.php',
  DELETE_EVENT: '/event/event.php',

  // Qr Code
  GET_ALL_QRCODE: '/Qr/getAll.php',
  GET_QRCODE_BY_ID: '/Qr/getById.php',
  UPDATE_QRCODE: '/Qr/updateQr.php',
  ADD_QRCODE: '/Qr/createQr.php',

  GET_QRCODE_TOKEN: '/token/createToken.php',

  // Reports
  GET_ALL_REPORTS: '/report/createReport.php',

  // Notifications
  GET_ALL_NOTIFICATIONS: '/notification/adminNotification.php',
  UPDATE_NOTIFICATION: '/notification/setAdmin.php',
}
