import HomeIcon from '@mui/icons-material/Home'

import TopBar from '../../components/TopBar'
import routeUrls from '../../constants/routeUrls'
import DataTable from './DataTable'
import { Box, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../utils/toastMessage'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { APP_CONFIG } from '../../constants/contants'
import { setListingQueryParams } from '../../store/slice/commonSlice'
import {
  useDeleteStateMutation,
  useStateDetailsQuery,
} from '../../api/stateApi'
import { DeleteStateResponse } from '../../api/stateApi/types'
import ViewStateModal from './ViewStateModal'
import AddStateModal from './AddStateModal'
import EditStateModal from './EditStateModal'

const StateListManagement = () => {
  const dispatch = useDispatch<AppDispatch>()

  // Listing Query Params
  const listingQueryParams = useSelector(
    (state: RootState) => state.commonReducer.listingQueryParams,
  )
  const { state: stateQueryParams } = listingQueryParams

  // BreadCrumbsItems
  const breadcrumbsItems = [
    {
      to: routeUrls.DASHBOARD,
      text: 'Home',
      icon: <HomeIcon sx={{ mr: 0.5 }} fontSize='inherit' />,
    },
    { text: 'States' },
  ]

  // Page
  const [page, setPage] = useState(APP_CONFIG.DEFAULT_PAGE)
  const [pageSize, setPageSize] = useState(APP_CONFIG.DEFAULT_PAGE_SIZE)

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage + 1)
  }

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(APP_CONFIG.DEFAULT_PAGE)
  }

  // Search Value
  const [search, setSearch] = useState('')

  const handleSearchValue = (value: string) => {
    setSearch(value)
  }

  // Add Modal
  const [openAddModal, setOpenAddModal] = useState<boolean>(false)

  const handleOpenAddModal = () => {
    setOpenAddModal(true)
  }

  const handleCloseAddModal = () => {
    setOpenAddModal(false)
  }

  // Edit Modal
  const [openEditModal, setOpenEditModal] = useState({
    id: 0,
    open: false,
  })

  const handleOpenEditModal = (id: number) => {
    setOpenEditModal({
      id: id,
      open: true,
    })
  }

  const handleCloseEditModal = () => {
    setOpenEditModal({
      id: 0,
      open: false,
    })
  }

  // View Modal
  const [openViewModal, setOpenViewModal] = useState({
    id: 0,
    open: false,
  })

  const handleOpenViewModal = (id: number) => {
    setOpenViewModal({
      id: id,
      open: true,
    })
  }

  const handleCloseViewModal = () => {
    setOpenViewModal({
      id: 0,
      open: false,
    })
  }

  // Delete Modal

  const [deleteState] = useDeleteStateMutation()

  const handleOpenDeleteModal = (id: number) => {
    Swal.fire({
      title: 'Are you sure you want to delete state?',
      icon: 'warning',
      showCancelButton: true,
      customClass: {
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button',
        title: 'custom-title',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteState({
          id: id,
        })
          .unwrap()
          .then((data: DeleteStateResponse) => {
            if (data?.success) {
              toastSuccessMessage('State has been deleted successfully')
            } else {
              toastErrorMessage(data?.message || 'Unable to Delete State')
            }
          })
          .catch((error) => {
            toastErrorMessage(error?.data?.message || 'Unable to Delete State')
          })
      }
    })
  }

  const { data, isFetching, isSuccess } = useStateDetailsQuery(stateQueryParams)

  useEffect(() => {
    dispatch(
      setListingQueryParams({
        ...listingQueryParams,
        state: {
          q: search,
          page: page,
          page_size: pageSize,
        },
      }),
    )
  }, [search, page, pageSize])

  return (
    <>
      <TopBar title='States' breadcrumbsItems={breadcrumbsItems} />

      {/* Table */}
      <Box mt={2}>
        <DataTable
          headers={['#', 'State', 'Actions']}
          action={
            <Button
              variant='contained'
              onClick={handleOpenAddModal}
              sx={{ textTransform: 'none', fontWeight: 600 }}
            >
              Add State
            </Button>
          }
          data={data?.results}
          totalPages={data?.count}
          page={page}
          rowsPerPage={pageSize}
          handlePageChange={handlePageChange}
          handleSearchValue={handleSearchValue}
          handleOpenEditModal={handleOpenEditModal}
          handleOpenDeleteModal={handleOpenDeleteModal}
          handleOpenViewModal={handleOpenViewModal}
          handleChangeRowsPerPage={handleChangePageSize}
          isSuccess={isSuccess && !isFetching}
        />
      </Box>

      {/* Add Modal */}
      <AddStateModal
        openModal={openAddModal}
        handleCloseModal={handleCloseAddModal}
      />

      {/* Edit Modal */}
      {!!openEditModal?.id && (
        <EditStateModal
          id={openEditModal?.id}
          openModal={openEditModal?.open}
          handleCloseModal={handleCloseEditModal}
        />
      )}

      {/* View Modal */}
      {!!openViewModal?.id && (
        <ViewStateModal
          id={openViewModal?.id}
          openModal={openViewModal?.open}
          handleCloseModal={handleCloseViewModal}
        />
      )}
    </>
  )
}

export default StateListManagement
