import { Grid } from '@mui/material'
import FileInput from '../../../../../components/FileInput'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { GiftForm } from './type'
import InputField from '../../../../../components/InputField'

const GiftDetails: FC<any> = ({ data }) => {
  const [giftImage, setGiftImage] = useState<File | string | null>()
  const { control, setValue } = useForm<GiftForm>({
    defaultValues: {
      isGiftImage: false,
      giftName: '',
      points: 0,
    },
  })

  useEffect(() => {
    if (data) {
      setGiftImage(data?.gift_photo)
      setValue('isGiftImage', true)
      setValue('giftName', data?.gift_name)
      setValue('points', data?.points)
    }
  }, [data, setValue])
  return (
    <>
      <Grid container spacing={1}>
        {/* Gift Image Image Field */}
        <Grid item xs={12}>
          <FileInput value={giftImage} readOnly />
        </Grid>

        {/* Gift Name Field */}
        <Grid item xs={12} sm={6}>
          <InputField
            name={'giftName'}
            control={control}
            label={'Gift Name'}
            fullWidth
            readOnly
          />
        </Grid>

        {/* Points Field */}
        <Grid item xs={12} sm={6}>
          <InputField
            name={'points'}
            control={control}
            label={'Gift Points'}
            type='number'
            fullWidth
            readOnly
          />
        </Grid>
      </Grid>
    </>
  )
}

export default GiftDetails
