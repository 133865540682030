import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import MenuIcon from '@mui/icons-material/Menu'
import { Container, Stack } from '@mui/material'
import ProfileSection from './ProfileSection'
import LogoSection from './LogoSection'
import AvatarButton from '../../../components/AvatarButton'
import Notification from './Notification'
import { useDispatch, useSelector } from 'react-redux'
import { setModalsData, TModalState } from '../../../store/slice/commonSlice'

const Header = () => {
  const dispatch = useDispatch()
  const modalsState: TModalState = useSelector(
    (state: any) => state.commonReducer.modals,
  )

  const handleSidebarToggle = () => {
    dispatch(
      setModalsData({ ...modalsState, showSidebar: !modalsState.showSidebar }),
    )
  }

  return (
    <AppBar elevation={0} position='fixed' color='inherit'>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Box display={'flex'} alignItems={'center'}>
            {/* Logo Section */}
            <LogoSection />

            {/* Sidebar Toggle Button */}
            <AvatarButton
              color='primary'
              icon={<MenuIcon fontSize='small' />}
              onClick={handleSidebarToggle}
            />
          </Box>
        </Box>

        <Box sx={{ flexGrow: 0 }}>
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            {/* Notification Section */}
            <Notification />

            {/* Profile Section */}
            <ProfileSection />
          </Stack>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Header
