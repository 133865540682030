import React, { FC } from 'react'
import { LoadingButton } from '@mui/lab'
import { ButtonFieldProps } from './types'

const ButtonField: FC<ButtonFieldProps> = ({
  loading = false,
  buttonText,
  ...props
}) => {
  return (
    <LoadingButton loading={loading} variant='contained' {...props}>
      {buttonText}
    </LoadingButton>
  )
}

export default ButtonField
