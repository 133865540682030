export enum PendingInvitationUsersApiTags {
  PENDING_INVITATION_USERS_CREATED = 'PENDING_INVITATION_USERS_CREATED',
  PENDING_INVITATION_USERS_MODIFIED = 'PENDING_INVITATION_USERS_MODIFIED',
  PENDING_INVITATION_USERS_DELETED = 'PENDING_INVITATION_USERS_DELETED',
  PENDING_INVITATION_USERS_SINGLE = 'PENDING_INVITATION_USERS_SINGLE',
}

export enum ApprovedUsersApiTags {
  APPROVED_USERS_CREATED = 'APPROVED_USERS_CREATED',
  APPROVED_USERS_MODIFIED = 'APPROVED_USERS_MODIFIED',
  APPROVED_USERS_DELETED = 'APPROVED_USERS_DELETED',
  APPROVED_USERS_SINGLE = 'APPROVED_USERS_SINGLE',
}
