import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { EditModalForm, EditModalProps } from './types'
import { editModalValidationSchema } from './validation'
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../../utils/toastMessage'
import {
  useEditStateMutation,
  useGetSingleStateQuery,
} from '../../../api/stateApi'
import { EditStateResponse } from '../../../api/stateApi/types'

const useEditModal = ({ id, openModal, handleCloseModal }: EditModalProps) => {
  // Get State
  const {
    data: singleStateData,
    isSuccess: isSingleStateDataSuccess,
    isFetching: isSingleStateDataFetching,
  } = useGetSingleStateQuery(
    {
      id: id,
    },
    {
      skip: !openModal, // Skip the query if the modal is not open
    },
  )

  // Edit State
  const [editState, { isLoading }] = useEditStateMutation()

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm<EditModalForm>({
    defaultValues: {
      state: '',
    },
    resolver: yupResolver(editModalValidationSchema),
  })

  useEffect(() => {
    if (singleStateData?.data) {
      setValue('state', singleStateData?.data?.state_name)
    }
  }, [singleStateData?.data, setValue])

  const onSubmit: SubmitHandler<EditModalForm> = async (data) => {
    const formData = {
      id: singleStateData?.data?.id || 0,
      state_name: data?.state,
    }

    editState(formData)
      .unwrap()
      .then((data: EditStateResponse) => {
        if (data?.success) {
          toastSuccessMessage('State Updated Successfully')
          handleCloseModal()
        } else {
          toastErrorMessage(data?.message || 'Unable to Updated State')
        }
      })
      .catch((error) => {
        toastErrorMessage(error?.data?.message || 'Unable to Updated State')
      })
  }

  return {
    control,
    register,
    errors,
    reset,
    onSubmit,
    handleSubmit,
    isLoading,
    isDataLoading: isSingleStateDataFetching && !isSingleStateDataSuccess,
  }
}

export default useEditModal
