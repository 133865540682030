import * as yup from 'yup'

export const editModalValidationSchema = yup.object().shape({
  isEventImage: yup
    .boolean()
    .oneOf([true], 'Event image must be uploaded')
    .required('Event image is required'), // Ensure the field is included
  title: yup.string().required('Event Name is required'),
  description: yup.string().required('Event Description is required'),
  videoUrl: yup
    .string()
    .url('Must be a valid URL') // Validates that it's a proper URL
    .required('Video URL is required'), // Makes the video URL mandatory
  date: yup.string().required('Event date is required'),
  time: yup.string().required('Event time is required'),
})
