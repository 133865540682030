import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import useAddModal from './useAddModal'
import InputField from '../../../components/InputField'
import { useEffect } from 'react'
import FileInput from '../../../components/FileInput'
import { AddGiftModalProps } from './types'
import ButtonField from '../../../components/ButtonField'

const AddGiftModal: React.FC<AddGiftModalProps> = ({
  openModal,
  handleCloseModal,
}) => {
  const {
    isLoading,
    control,
    handleSubmit,
    onSubmit,
    errors,
    reset,
    giftImage,
    handleGiftImage,
  } = useAddModal({
    handleCloseModal,
  })

  // reset data when open the modal
  useEffect(() => {
    reset()
  }, [openModal])

  return (
    <Dialog
      fullWidth
      sx={{
        '.MuiDialog-paper': {
          borderRadius: 4,
        },
      }}
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      component='form'
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* Title */}
      <DialogTitle sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }} id='add-gift'>
        Add Gift
      </DialogTitle>

      {/* Dialog box close button */}
      <IconButton
        onClick={handleCloseModal}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        <Grid container spacing={1}>
          {/* Gift Image Image Field */}
          <Grid item xs={12}>
            <FileInput
              value={giftImage}
              error={errors.isGiftImage ? errors.isGiftImage.message : ''}
              onChange={handleGiftImage}
            />
          </Grid>

          {/* Gift Name Field */}
          <Grid item xs={12} sm={6}>
            <InputField
              name={'giftName'}
              control={control}
              label={'Gift Name'}
              placeholder='Enter the gift name'
              fullWidth
              errors={!!errors.giftName}
              helperText={errors.giftName ? errors.giftName.message : ''}
              required
            />
          </Grid>

          {/* Points Field */}
          <Grid item xs={12} sm={6}>
            <InputField
              name={'points'}
              control={control}
              label={'Points'}
              placeholder='Enter the points limit'
              type='number'
              fullWidth
              required
              errors={!!errors.points}
              helperText={errors.points ? errors.points.message : ''}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <ButtonField
          variant='contained'
          onClick={handleCloseModal}
          buttonText={'Cancel'}
          sx={{
            backgroundColor: 'primary.main',
          }}
        />

        <ButtonField
          type='submit'
          loading={isLoading}
          variant='contained'
          buttonText={'Save'}
          sx={{
            backgroundColor: 'ternary.main',
            '&:hover': {
              backgroundColor: 'ternary.dark',
            },
          }}
        />
      </DialogActions>
      {/* </Box> */}
    </Dialog>
  )
}

export default AddGiftModal
