import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ListingResponse } from '../types'
import { DistrictAdminApiTags } from './tags'
import {
  AddDistrictAdminResponse,
  DeleteDistrictAdminPayload,
  DeleteDistrictAdminResponse,
  DistrictAdminPayload,
  DistrictAdminResponse,
  EditDistrictAdminPayload,
  EditDistrictAdminResponse,
  SingleDistrictAdminPayload,
  SingleDistrictAdminResponse,
} from './types'
import { API_METHODS } from '../../constants/contants'
import { API_ENDPOINTS } from '../../constants/apiendpoints'

export const districtAdminApi = createApi({
  reducerPath: 'districtAdminApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: [
    DistrictAdminApiTags.DISTIRCT_ADMIN_SINGLE,
    DistrictAdminApiTags.DISTRICT_ADMIN_CREATED,
    DistrictAdminApiTags.DISTRICT_ADMIN_DELETED,
    DistrictAdminApiTags.DISTRICT_ADMIN_MODIFIED,
  ],
  endpoints: (builder) => ({
    // Get All District Admin List
    districtAdminDetails: builder.query<
      ListingResponse<DistrictAdminResponse[]>,
      DistrictAdminPayload
    >({
      query: (params: DistrictAdminPayload) => {
        return {
          url: API_ENDPOINTS.GET_ALL_DISTRICT_ADMIN,
          method: API_METHODS.GET,
          params,
        }
      },
      providesTags: [
        DistrictAdminApiTags.DISTRICT_ADMIN_CREATED,
        DistrictAdminApiTags.DISTRICT_ADMIN_DELETED,
        DistrictAdminApiTags.DISTRICT_ADMIN_MODIFIED,
      ],
      transformResponse: (
        response: ListingResponse<DistrictAdminResponse[]>,
      ) => {
        return response
      },
    }),

    // Add District
    addDistrictAdmin: builder.mutation<AddDistrictAdminResponse, FormData>({
      query: (params: FormData) => ({
        url: API_ENDPOINTS.ADD_DISTRICT_ADMIN,
        method: API_METHODS.POST,
        body: params,
      }),
      invalidatesTags: [DistrictAdminApiTags.DISTRICT_ADMIN_CREATED],
      transformResponse: (response: AddDistrictAdminResponse) => {
        return response
      },
    }),

    // Delete District Admin
    deleteDistrictAdmin: builder.mutation<
      DeleteDistrictAdminResponse,
      DeleteDistrictAdminPayload
    >({
      query: (params: DeleteDistrictAdminPayload) => ({
        url: API_ENDPOINTS.DELETE_DISTRICT_ADMIN,
        method: API_METHODS.DELETE,
        params: params,
      }),
      invalidatesTags: [DistrictAdminApiTags.DISTRICT_ADMIN_DELETED],
      transformResponse: (response: DeleteDistrictAdminResponse) => {
        return response
      },
    }),

    // Edit District Admin
    editDistrictAdmin: builder.mutation<
      EditDistrictAdminResponse,
      { id: number; formData: FormData }
    >({
      query: ({ id, formData }) => ({
        url: API_ENDPOINTS.UPDATE_DISTRICT_ADMIN, // Assuming the ID is part of the URL
        method: API_METHODS.POST,
        body: formData,
        params: {
          id,
        },
      }),
      invalidatesTags: [
        DistrictAdminApiTags.DISTRICT_ADMIN_MODIFIED,
        DistrictAdminApiTags.DISTIRCT_ADMIN_SINGLE,
      ],
    }),

    // single district admin
    getSingleDistrictAdmin: builder.query<
      SingleDistrictAdminResponse,
      SingleDistrictAdminPayload
    >({
      query: (params: SingleDistrictAdminPayload) => ({
        url: API_ENDPOINTS.GET_DISTRICT_ADMIN_BY_ID, // The ID is part of the URL
        method: API_METHODS.GET,
        params: params,
      }),
      providesTags: [DistrictAdminApiTags.DISTIRCT_ADMIN_SINGLE],
      transformResponse: (response: SingleDistrictAdminResponse) => {
        return response
      },
    }),
  }),
})

export const {
  useAddDistrictAdminMutation,
  useDeleteDistrictAdminMutation,
  useDistrictAdminDetailsQuery,
  useEditDistrictAdminMutation,
  useGetSingleDistrictAdminQuery,
} = districtAdminApi
