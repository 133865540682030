import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FevicolLogo from '../../../../assets/images/fevicol_logo1.jpeg'
import { Avatar, ButtonBase, Container, Stack } from '@mui/material'
import { FC } from 'react'
import { LogoSectionProps } from './types'

const LogoSection: FC<LogoSectionProps> = ({ show = false }) => {
  return (
    <>
      {/* Logo Section */}
      <Box
        sx={{
          display: {
            xs: show ? 'flex' : 'none',
            md: 'flex',
          },
        }}
        alignItems={'center'}
        marginRight={10}
      >
        <Avatar alt='Sigma Logo' src={FevicolLogo} />
        <Typography
          variant='h6'
          noWrap
          component='div'
          fontWeight={'bold'}
          mx={1}
        >
          Sigma
        </Typography>
      </Box>
    </>
  )
}

export default LogoSection
