import * as yup from 'yup'

export const editModalValidationSchema = yup.object().shape({
  giftName: yup.string().required('Gift Name is required'),
  points: yup
    .number()
    .typeError('Please enter any number')
    .required('Points are required')
    .positive('Points must be a positive number')
    .integer('Points must be an integer'),
  isGiftImage: yup
    .boolean()
    .oneOf([true], 'Profile image must be uploaded')
    .required('Profile image is required'), // Ensure the field is included
})
