import HomeIcon from '@mui/icons-material/Home'

import { Box, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { APP_CONFIG } from '../../../constants/contants'
import DataTable from './DataTable'
import { setListingQueryParams } from '../../../store/slice/commonSlice'
import ViewDeclinedInvitationModal from './ViewDeclinedInvitation'
import { useRedeemPointsDeclinedListDetailsQuery } from '../../../api/redeemPointsApi/redeemPointsDeclinedApi'

const DeclinedInvitation = () => {
  const dispatch = useDispatch<AppDispatch>()

  // Listing Query Params
  const listingQueryParams = useSelector(
    (state: RootState) => state.commonReducer.listingQueryParams,
  )

  const {
    redeemPointsDeclinedInvitation: redeemPointsDeclinedInvitationQueryParams,
  } = listingQueryParams

  // Page
  const [page, setPage] = useState(APP_CONFIG.DEFAULT_PAGE)
  const [pageSize, setPageSize] = useState(APP_CONFIG.DEFAULT_PAGE_SIZE)

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage + 1)
  }

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(APP_CONFIG.DEFAULT_PAGE)
  }

  // Search Value
  const [search, setSearch] = useState('')

  const handleSearchValue = (value: string) => {
    setSearch(value)
  }

  // View Modal
  const [openViewModal, setOpenViewModal] = useState({
    id: 0,
    open: false,
  })

  const handleOpenViewModal = (id: number) => {
    setOpenViewModal({
      id: id,
      open: true,
    })
  }

  const handleCloseViewModal = () => {
    setOpenViewModal({
      id: 0,
      open: false,
    })
  }

  const { data, isFetching, isSuccess } =
    useRedeemPointsDeclinedListDetailsQuery(
      redeemPointsDeclinedInvitationQueryParams,
    )

  useEffect(() => {
    dispatch(
      setListingQueryParams({
        ...listingQueryParams,
        redeemPointsDeclinedInvitation: {
          q: search,
          page: page,
          page_size: pageSize,
        },
      }),
    )
  }, [search, page, pageSize])

  return (
    <>
      {/* Table */}
      <Box>
        <DataTable
          headers={[
            '#',
            'Full Name',
            'Mobile Number',
            'Gift Name',
            'Gift Points',
            'Approval Req. Date',
            'Status',
            'Actions',
          ]}
          data={data?.results}
          totalPages={data?.count}
          page={page}
          rowsPerPage={pageSize}
          handlePageChange={handlePageChange}
          handleSearchValue={handleSearchValue}
          handleOpenViewModal={handleOpenViewModal}
          handleChangeRowsPerPage={handleChangePageSize}
          isSuccess={isSuccess && !isFetching}
        />
      </Box>

      {/* View Modal */}
      {!!openViewModal?.id && (
        <ViewDeclinedInvitationModal
          id={openViewModal?.id}
          openModal={openViewModal?.open}
          handleCloseModal={handleCloseViewModal}
        />
      )}
    </>
  )
}

export default DeclinedInvitation
