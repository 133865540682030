import { FC, useEffect } from 'react'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import InputField from '../../../components/InputField'
import { useForm } from 'react-hook-form'
import { StateForm, ViewStateModalProps } from './types'
import { useGetSingleStateQuery } from '../../../api/stateApi'

const ViewStateModal: FC<ViewStateModalProps> = ({
  id,
  openModal,
  handleCloseModal,
}) => {
  // Fetching State Data
  const { data, isSuccess, isFetching } = useGetSingleStateQuery(
    {
      id: id,
    },
    {
      skip: !openModal, // Skip the query if the modal is not open
    },
  )

  const { control, setValue } = useForm<StateForm>({
    defaultValues: {
      state: '',
    },
  })

  useEffect(() => {
    if (data?.data) {
      // Set form values once data is available
      setValue('state', data.data.state_name)
    }
  }, [data, setValue])

  return (
    <Dialog
      fullWidth
      sx={{
        '.MuiDialog-paper': {
          borderRadius: 4,
        },
      }}
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle
        sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }}
        id='customized-dialog-title'
      >
        View State Details
        <IconButton
          aria-label='close'
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {isFetching || !isSuccess ? (
          <Box textAlign='center'>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={1}>
            {/* State Field */}
            <Grid item xs={12}>
              <InputField
                name={'state'}
                control={control}
                label={'State'}
                readOnly
                fullWidth
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ViewStateModal
