import React, { FC, useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ProductForm, ViewProductModalProps } from './types'
import InputField from '../../../components/InputField'
import { useForm } from 'react-hook-form'
import FileInput from '../../../components/FileInput'
import { useGetSingleProductQuery } from '../../../api/productsApi'

const ViewProductModal: FC<ViewProductModalProps> = ({
  id,
  openModal,
  handleCloseModal,
}) => {
  const [productImage, setProductImage] = useState<File | string | null>()

  // Fetching Product Data
  const { data, isSuccess, isFetching } = useGetSingleProductQuery(
    {
      id: id,
    },
    {
      skip: !openModal, // Skip the query if the modal is not open
    },
  )

  const { control, setValue, watch } = useForm<ProductForm>({
    defaultValues: {
      isProductImage: false,
      packing: 0,
      points: 0,
      productName: '',
      tokens: 0,
    },
  })

  useEffect(() => {
    if (data?.data) {
      setProductImage(data?.data?.product_photo)
      setValue('productName', data?.data?.product_name)
      setValue('packing', data?.data?.packing)
      setValue('points', data?.data?.points)
    }
  }, [data, setValue])

  return (
    <Dialog
      fullWidth
      sx={{
        '.MuiDialog-paper': {
          borderRadius: 4,
        },
      }}
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle
        sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }}
        id='customized-dialog-title'
      >
        View Product Details
        <IconButton
          aria-label='close'
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {isFetching || !isSuccess ? (
          <Box textAlign='center'>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={1}>
            {/* Product Image */}
            <Grid item xs={12}>
              <FileInput value={productImage} readOnly />
            </Grid>

            {/* Product Name Field */}
            <Grid item xs={12} sm={6}>
              <InputField
                name={'productName'}
                control={control}
                label={'Product Name'}
                fullWidth
                readOnly
              />
            </Grid>

            {/* Packing Field */}
            <Grid item xs={12} sm={6}>
              <InputField
                name={'packing'}
                control={control}
                label={'Packing in KG'}
                type='number'
                fullWidth
                readOnly
              />
            </Grid>

            {/* Points */}
            <Grid item xs={12}>
              <InputField
                name={'points'}
                control={control}
                label={'Points'}
                type={'number'}
                fullWidth
                readOnly
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ViewProductModal
