import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ListingResponse, NotificationListingResponse } from '../types'
import { API_METHODS } from '../../constants/contants'
import { API_ENDPOINTS } from '../../constants/apiendpoints'
import { NotificationsApiTags } from './tags'
import {
  EditNotificationResponse,
  NotificationPayload,
  NotificationResponse,
} from './types'

export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: [
    NotificationsApiTags.NOTIFICATIONS_CREATED,
    NotificationsApiTags.NOTIFICATIONS_DELETED,
    NotificationsApiTags.NOTIFICATIONS_MODIFIED,
    NotificationsApiTags.NOTIFICATIONS_SINGLE,
  ],
  endpoints: (builder) => ({
    // Get All Notifications
    notificationsDetails: builder.query<
      NotificationListingResponse<NotificationResponse[]>,
      NotificationPayload
    >({
      query: (params: NotificationPayload) => {
        return {
          url: API_ENDPOINTS.GET_ALL_NOTIFICATIONS,
          method: API_METHODS.GET,
          params,
        }
      },
      providesTags: [
        NotificationsApiTags.NOTIFICATIONS_CREATED,
        NotificationsApiTags.NOTIFICATIONS_DELETED,
        NotificationsApiTags.NOTIFICATIONS_SINGLE,
      ],
      transformResponse: (
        response: NotificationListingResponse<NotificationResponse[]>,
      ) => {
        return response
      },
    }),

    // Edit Notification
    editNotification: builder.mutation<
      EditNotificationResponse,
      { admin_id: string; formData: FormData }
    >({
      query: ({ admin_id, formData }) => ({
        url: API_ENDPOINTS.UPDATE_NOTIFICATION, // Assuming the ID is part of the URL
        method: API_METHODS.POST,
        body: formData,
        params: {
          admin_id,
        },
      }),
      invalidatesTags: [
        NotificationsApiTags.NOTIFICATIONS_MODIFIED,
        NotificationsApiTags.NOTIFICATIONS_SINGLE,
      ],
    }),
  }),
})

export const { useEditNotificationMutation, useNotificationsDetailsQuery } =
  notificationsApi
