import React, { FC, useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import InputField from '../../../components/InputField'
import { useForm } from 'react-hook-form'
import FileInput from '../../../components/FileInput'
import { EventForm, ViewEventModalProps } from './types'
import { useGetSingleEventQuery } from '../../../api/eventsApi'
import DateTimeField from '../../../components/DateTimeField'

const ViewEventModal: FC<ViewEventModalProps> = ({
  id,
  openModal,
  handleCloseModal,
}) => {
  const [eventImage, setEventImage] = useState<File | string | null>()

  // Fetching Event Data
  const { data, isSuccess, isFetching } = useGetSingleEventQuery(
    {
      id: id,
    },
    {
      skip: !openModal, // Skip the query if the modal is not open
    },
  )

  const { control, setValue } = useForm<EventForm>({
    defaultValues: {
      isEventImage: false,
      title: '',
      description: '',
      videoUrl: '',
      date: '',
      time: '',
    },
  })

  useEffect(() => {
    if (data?.event) {
      console.log(data)
      setEventImage(data?.event?.photo)
      setValue('title', data?.event?.title)
      setValue('description', data?.event?.description)
      setValue('videoUrl', data?.event?.video_link)
      setValue('date', data?.event?.date)
      setValue('time', data?.event?.time)
    }
  }, [data, setValue])

  return (
    <Dialog
      fullWidth
      sx={{
        '.MuiDialog-paper': {
          borderRadius: 4,
        },
      }}
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle
        sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }}
        id='customized-dialog-title'
      >
        View Event Details
        <IconButton
          aria-label='close'
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {isFetching || !isSuccess ? (
          <Box textAlign='center'>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={1}>
            {/* Event Image Field */}
            <Grid item xs={12}>
              <FileInput value={eventImage} readOnly />
            </Grid>

            {/* Event Name Field */}
            <Grid item xs={12}>
              <InputField
                name={'title'}
                control={control}
                label={'Event Name'}
                fullWidth
                readOnly
              />
            </Grid>

            {/* Video Field */}
            <Grid item xs={12}>
              <InputField
                name={'videoUrl'}
                control={control}
                label={'Video Link'}
                placeholder='Enter the video link'
                fullWidth
                required
                readOnly
              />
            </Grid>

            <Grid item xs={12} lg={6} mt={2}>
              <DateTimeField
                name='date'
                label='Event Date'
                control={control}
                required
                readOnly
              />
            </Grid>
            <Grid item xs={12} lg={6} my={2}>
              <DateTimeField
                name='time'
                type='time'
                label='Event Time'
                control={control}
                required
                readOnly
              />
            </Grid>

            {/* Event Description Field */}
            <Grid item xs={12}>
              <InputField
                name={'description'}
                control={control}
                label={'Event Description'}
                fullWidth
                multiline
                readOnly
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ViewEventModal
