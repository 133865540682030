import * as Yup from 'yup'
import { ILoginFormInput } from './types'
import { EMAILREGEX } from '../../constants/contants'

export const loginValidationSchema: Yup.ObjectSchema<ILoginFormInput> =
  Yup.object().shape({
    userName: Yup.string()
      .matches(EMAILREGEX, 'Please enter a valid email address') // Email validation with regex and custom error message
      .required('Email is required'), // Required field with custom error message
    password: Yup.string().required('Password is required'), // Required field with custom error message
  })
