import { FC, useState } from 'react'
import { TabPanelSwitcherProps } from './types'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Card, CardContent, Tab } from '@mui/material'

const TabPanelSwitcher: FC<TabPanelSwitcherProps> = ({
  tabs,
  defaultValue = '1',
  orientation = 'horizontal', // Default to horizontal
  fullWidth = false,
}) => {
  const [value, setValue] = useState(defaultValue)

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Card elevation={0} sx={{ borderRadius: 3 }}>
        <CardContent sx={{ px: 0, p: '0 !important' }}>
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: orientation === 'horizontal' ? 1 : 0,
                borderRight: orientation === 'vertical' ? 1 : 0,
                borderBottomColor: 'rgb(238, 238, 238)',
                display: 'flex',
                flexDirection: orientation === 'horizontal' ? 'column' : 'row',
              }}
            >
              <TabList
                variant={fullWidth ? 'fullWidth' : undefined}
                onChange={handleChange}
                aria-label='tabs example'
                orientation={
                  orientation === 'vertical' ? 'vertical' : 'horizontal'
                } // Apply orientation
                sx={{
                  flexDirection:
                    orientation === 'horizontal' ? 'row' : 'column',
                }}
              >
                {tabs.map((tab) => (
                  <Tab
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                    sx={{
                      fontWeight: '600',
                    }}
                  />
                ))}
              </TabList>
            </Box>
            {tabs.map((tab) => (
              <TabPanel
                key={tab.value}
                value={tab.value}
                sx={{
                  padding: 0,
                }}
              >
                <tab.Component {...tab.props} /> {/* Render the component */}
              </TabPanel>
            ))}
          </TabContext>
        </CardContent>
      </Card>
    </Box>
  )
}

export default TabPanelSwitcher
