import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ListingResponse } from '../../types'
import {
  RedeemPointsDeclinedPayload,
  RedeemPointsDeclinedResponse,
  SingleRedeemPointsDeclinedPayload,
  SingleRedeemPointsDeclinedResponse,
} from './types'
import { API_METHODS } from '../../../constants/contants'
import { API_ENDPOINTS } from '../../../constants/apiendpoints'
import { RedeemPointsDeclinedApiTags } from './tags'

export const redeemPointsDeclinedApi = createApi({
  reducerPath: 'redeemPointsDeclinedApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: [
    RedeemPointsDeclinedApiTags.REDEEM_POINTS_DECLINED_SINGLE,
    RedeemPointsDeclinedApiTags.REDEEM_POINTS_DECLINED_MODIFIED,
  ],
  endpoints: (builder) => ({
    // Get All Rededem Points Declined
    redeemPointsDeclinedListDetails: builder.query<
      ListingResponse<RedeemPointsDeclinedResponse[]>,
      RedeemPointsDeclinedPayload
    >({
      query: (params: RedeemPointsDeclinedPayload) => {
        return {
          url: API_ENDPOINTS.GET_ALL_REDEEM_POINTS_DECLINED_LIST,
          method: API_METHODS.GET,
          params,
        }
      },
      providesTags: [
        RedeemPointsDeclinedApiTags.REDEEM_POINTS_DECLINED_MODIFIED,
      ],
      transformResponse: (
        response: ListingResponse<RedeemPointsDeclinedResponse[]>,
      ) => {
        return response
      },
    }),

    // single redeem poins declined details
    getSingleRedeemPointsDeclinedDetails: builder.query<
      SingleRedeemPointsDeclinedResponse,
      SingleRedeemPointsDeclinedPayload
    >({
      query: (params: SingleRedeemPointsDeclinedPayload) => ({
        url: API_ENDPOINTS.GET_SINGLE_REDEEM_POINTS_DECLINED_DETAILS, // The ID is part of the URL
        method: API_METHODS.GET,
        params: params,
      }),
      providesTags: [RedeemPointsDeclinedApiTags.REDEEM_POINTS_DECLINED_SINGLE],
      transformResponse: (response: SingleRedeemPointsDeclinedResponse) => {
        return response
      },
    }),
  }),
})

export const {
  useGetSingleRedeemPointsDeclinedDetailsQuery,
  useRedeemPointsDeclinedListDetailsQuery,
} = redeemPointsDeclinedApi
