import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { RootState } from '../store'
import routeUrls from '../constants/routeUrls'

const ProtectedRoute: FC = () => {
  const { isAuthenticated, username, id } = useSelector(
    (state: RootState) => state.authReducer,
  )
  const location = useLocation()

  if (!isAuthenticated && !!!username && !!!id) {
    return <Navigate to={routeUrls.LOGIN} state={{ from: location }} />
  }
  return <Outlet />
}

export default ProtectedRoute
