import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import useEditModal from './useEditModal'
import InputField from '../../../components/InputField'
import ButtonField from '../../../components/ButtonField'
import { useDispatch, useSelector } from 'react-redux'
import FileInput from '../../../components/FileInput'
import { EditEventModalProps } from './types'
import DateTimeField from '../../../components/DateTimeField'

const EditEventModal: React.FC<EditEventModalProps> = ({
  id,
  openModal,
  handleCloseModal,
}) => {
  const {
    isLoading,
    control,
    handleSubmit,
    onSubmit,
    eventImage,
    handleEventImage,
    isDataLoading,
    errors,
  } = useEditModal({
    id,
    openModal,
    handleCloseModal,
  })

  const dispatch = useDispatch()

  return (
    <Dialog
      fullWidth
      sx={{
        '.MuiDialog-paper': {
          borderRadius: 4,
        },
      }}
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      component='form'
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* Title */}
      <DialogTitle
        sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }}
        id='customized-dialog-title'
      >
        Edit Event
      </DialogTitle>

      {/* Dialog box close button */}
      <IconButton
        aria-label='close'
        onClick={handleCloseModal}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        {isDataLoading ? (
          <Box textAlign='center'>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={1}>
            {/* Event Image Field */}
            <Grid item xs={12}>
              <FileInput
                value={eventImage}
                error={errors.isEventImage ? errors.isEventImage.message : ''}
                onChange={handleEventImage}
              />
            </Grid>

            {/* Event Name Field */}
            <Grid item xs={12}>
              <InputField
                name={'title'}
                control={control}
                label={'Event Name'}
                fullWidth
                errors={!!errors.title}
                helperText={errors.title ? errors.title.message : ''}
                required
              />
            </Grid>

            {/* Video Field */}
            <Grid item xs={12}>
              <InputField
                name={'videoUrl'}
                control={control}
                label={'Video Link'}
                placeholder='Enter the video link'
                fullWidth
                errors={!!errors.videoUrl}
                helperText={errors.videoUrl ? errors.videoUrl.message : ''}
                required
              />
            </Grid>

            <Grid item xs={12} lg={6} mt={2}>
              <DateTimeField
                name='date'
                label='Event Date'
                control={control}
                required
                errors={!!errors.date}
                helperText={errors.date ? errors.date.message : ''}
              />
            </Grid>
            <Grid item xs={12} lg={6} my={2}>
              <DateTimeField
                name='time'
                type='time'
                label='Event Time'
                control={control}
                required
                errors={!!errors.time}
                helperText={errors.time ? errors.time.message : ''}
              />
            </Grid>

            {/* Event Description Field */}
            <Grid item xs={12}>
              <InputField
                name={'description'}
                control={control}
                label={'Event Description'}
                fullWidth
                multiline
                required
                errors={!!errors.description}
                helperText={
                  errors.description ? errors.description.message : ''
                }
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <ButtonField
          variant='contained'
          onClick={handleCloseModal}
          buttonText={'Cancel'}
          sx={{
            backgroundColor: 'primary.main',
          }}
        />

        <ButtonField
          type='submit'
          loading={isLoading}
          variant='contained'
          buttonText={'Save'}
          sx={{
            backgroundColor: 'ternary.main',
            '&:hover': {
              backgroundColor: 'ternary.dark',
            },
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export default EditEventModal
