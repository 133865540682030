import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import useAddModal from './useAddModal'
import { useEffect, useState } from 'react'
import { AddVisitingCardEligibilityLimitModalProps } from './types'
import InputField from '../../../../components/InputField'
import ButtonField from '../../../../components/ButtonField'
import SelectField from '../../../../components/SelectField'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import {
  useDistrictDetailsByStateQuery,
  useDistrictDetailsQuery,
} from '../../../../api/districtApi'
import { APP_CONFIG } from '../../../../constants/contants'
import { setListingQueryParams } from '../../../../store/slice/commonSlice'
import { useStateDetailsQuery } from '../../../../api/stateApi'

const AddVisitingCardEligibilityLimitModal: React.FC<
  AddVisitingCardEligibilityLimitModalProps
> = ({ openModal, handleCloseModal }) => {
  const dispatch = useDispatch()

  const { isLoading, control, handleSubmit, onSubmit, errors, reset, watch } =
    useAddModal({
      handleCloseModal,
    })

  // State Id
  const stateId = watch('state')

  // Listing Query Params
  const listingQueryParams = useSelector(
    (state: RootState) => state.commonReducer.listingQueryParams,
  )

  // State List
  const [stateSearch, setStateSearch] = useState('')
  const { state: stateQueryParams } = listingQueryParams
  const {
    data: stateList,
    isFetching: isStateFetching,
    isSuccess: isStateSuccess,
  } = useStateDetailsQuery(stateQueryParams, {
    refetchOnMountOrArgChange: true,
    skip: !openModal,
  })

  useEffect(() => {
    dispatch(
      setListingQueryParams({
        ...listingQueryParams,
        state: {
          q: stateSearch,
          page: APP_CONFIG.DEFAULT_PAGE,
          page_size: APP_CONFIG.DEFAULT_PAGE_SIZE,
        },
      }),
    )
  }, [stateSearch, openModal])

  const { districtByStateId: districtByStateQueryParams } = listingQueryParams
  const {
    data: districtList,
    isFetching: isDistrictFetching,
    isSuccess: isDistrictSuccess,
  } = useDistrictDetailsByStateQuery(districtByStateQueryParams, {
    skip: !openModal || !stateId || stateId < 0,
  })

  useEffect(() => {
    if (stateId) {
      dispatch(
        setListingQueryParams({
          ...listingQueryParams,
          districtByStateId: {
            state_id: stateId,
          },
        }),
      )
    }
  }, [stateId])

  useEffect(() => {
    reset()
  }, [openModal])

  return (
    <Dialog
      fullWidth
      sx={{
        '.MuiDialog-paper': {
          borderRadius: 4,
        },
      }}
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      component='form'
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* Title */}
      <DialogTitle
        sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }}
        id='customized-dialog-title'
      >
        Add Token Limit
      </DialogTitle>

      {/* Dialog box close button */}
      <IconButton
        aria-label='close'
        onClick={handleCloseModal}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        <Grid container spacing={1}>
          
          {/* State Field */}
          <Grid item xs={12} mt={2}>
            <SelectField
              label={'State'}
              name={'state'}
              options={stateList?.results}
              loading={!isStateSuccess && isStateFetching}
              control={control}
              errors={!!errors.state}
              helperText={errors.state ? errors.state.message : ''}
              getOptionLabel={(option) => option.state_name} // Use 'name' as the label
              getOptionValue={(option) => option.id} // Use 'id' as the value
              onSearch={setStateSearch}
              required
            />
          </Grid>

          {/* District Field */}
          {!!stateId && (
            <Grid item xs={12} mt={2}>
              <SelectField
                label={'District'}
                name={'district'}
                options={districtList?.results}
                loading={!isDistrictSuccess && isDistrictFetching}
                control={control}
                errors={!!errors.district}
                helperText={errors.district ? errors.district.message : ''}
                getOptionLabel={(option) => option.district_name} // Use 'name' as the label
                getOptionValue={(option) => option.id} // Use 'id' as the value
                required
              />
            </Grid>
          )}

          {/* Points Limit */}
          <Grid item xs={12}>
            <InputField
              name={'points'}
              control={control}
              label={'Points Limit'}
              type='number'
              placeholder='Enter the points limit'
              fullWidth
              errors={!!errors.points}
              helperText={errors.points ? errors.points.message : ''}
              required
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <ButtonField
          variant='contained'
          onClick={handleCloseModal}
          buttonText={'Cancel'}
          sx={{
            backgroundColor: 'primary.main',
          }}
        />

        <ButtonField
          type='submit'
          loading={isLoading}
          variant='contained'
          buttonText={'Save'}
          sx={{
            backgroundColor: 'ternary.main',
            '&:hover': {
              backgroundColor: 'ternary.dark',
            },
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export default AddVisitingCardEligibilityLimitModal
