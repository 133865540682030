import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material'
import SearchBar from '../../../components/SearchBar'
import DeleteIcon from '@mui/icons-material/Delete'
import VisibilityIcon from '@mui/icons-material/Visibility'
import EditIcon from '@mui/icons-material/Edit'
import { DataTableProps } from './types'

export default function DataTable({
  headers,
  data,
  page,
  rowsPerPage,
  handleSearchValue,
  handlePageChange,
  handleChangeRowsPerPage,
  totalPages,
  isSuccess,
  action,
  handleOpenViewModal,
  handleOpenEditModal,
  handleOpenDeleteModal,
}: DataTableProps) {
  return (
    <Card elevation={0} sx={{ borderRadius: 3 }}>
      <CardHeader
        sx={{
          display: 'flex',
          flexDirection: 'column', // Stack title and action vertically on small screens
          alignItems: 'flex-start', // Align content to the start
          '.MuiCardHeader-action': {
            alignSelf: 'end',
            mr: 1,
            '@media (min-width:480px)': {
              alignSelf: 'center', // Align action to center on larger screens
            },
          },
          '@media (min-width:480px)': {
            flexDirection: 'row', // Default behavior on larger screens
            alignItems: 'center', // Align content to the center
          },
        }}
        title={<SearchBar handleSearchValue={handleSearchValue} />}
        action={action}
      />

      <Divider />

      <CardContent sx={{ px: 0, pb: 0 }}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell key={index} sx={{ fontWeight: 600 }}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isSuccess ? (
              data && data.length > 0 ? (
                data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component='th' scope='row'>
                      {(page - 1) * rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell>
                      <Avatar alt='Remy Sharp' src={row?.gift_photo} />
                    </TableCell>
                    <TableCell>{row?.gift_name}</TableCell>
                    <TableCell>{row?.points}</TableCell>
                    <TableCell>
                      <Tooltip title='View Gift'>
                        <IconButton
                          aria-label='view'
                          size='small'
                          onClick={() => handleOpenViewModal(row?.id)}
                        >
                          <VisibilityIcon color='action' fontSize='inherit' />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Edit Gift'>
                        <IconButton
                          aria-label='edit'
                          size='small'
                          onClick={() => handleOpenEditModal(row?.id)}
                        >
                          <EditIcon color='secondary' fontSize='inherit' />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Delete Gift'>
                        <IconButton
                          aria-label='delete'
                          size='small'
                          onClick={() => handleOpenDeleteModal(row?.id)}
                        >
                          <DeleteIcon color='primary' fontSize='inherit' />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={headers.length} align='center'>
                    No records found
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell
                  sx={{ textAlign: 'center', py: 5 }}
                  colSpan={headers.length}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>

      <CardActions
        sx={{
          justifyContent: 'end',
        }}
      >
        <TablePagination
          component='div'
          color='primary'
          count={totalPages || 0}
          page={page - 1}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CardActions>
    </Card>
  )
}
