import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ListingResponse } from '../types'
import {
  AddStateResponse,
  DeleteStatePayload,
  DeleteStateResponse,
  EditStatePayload,
  EditStateResponse,
  SingleStatePayload,
  SingleStateResponse,
  StatePayload,
  StateResponse,
} from './types'
import { API_METHODS } from '../../constants/contants'
import { API_ENDPOINTS } from '../../constants/apiendpoints'
import { StateApiTags } from './tags'

export const stateApi = createApi({
  reducerPath: 'stateApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: [
    StateApiTags.STATE_CREATED,
    StateApiTags.STATE_DELETED,
    StateApiTags.STATE_MODIFIED,
    StateApiTags.STATE_SINGLE,
  ],
  endpoints: (builder) => ({
    // Get All State List
    stateDetails: builder.query<ListingResponse<StateResponse[]>, StatePayload>(
      {
        query: (params: StatePayload) => {
          return {
            url: API_ENDPOINTS.GET_ALL_STATE,
            method: API_METHODS.GET,
            params,
          }
        },
        providesTags: [
          StateApiTags.STATE_CREATED,
          StateApiTags.STATE_DELETED,
          StateApiTags.STATE_MODIFIED,
        ],
        transformResponse: (response: ListingResponse<StateResponse[]>) => {
          return response
        },
      },
    ),

    // Add State
    addState: builder.mutation<AddStateResponse, FormData>({
      query: (params: FormData) => ({
        url: API_ENDPOINTS.ADD_STATE,
        method: API_METHODS.POST,
        body: params,
      }),
      invalidatesTags: [StateApiTags.STATE_CREATED],
      transformResponse: (response: AddStateResponse) => {
        return response
      },
    }),

    // Delete State
    deleteState: builder.mutation<DeleteStateResponse, DeleteStatePayload>({
      query: (params: DeleteStatePayload) => ({
        url: API_ENDPOINTS.DELETE_STATE,
        method: API_METHODS.DELETE,
        params: params,
      }),
      invalidatesTags: [StateApiTags.STATE_DELETED],
      transformResponse: (response: DeleteStateResponse) => {
        return response
      },
    }),

    // Edit State
    editState: builder.mutation<EditStateResponse, EditStatePayload>({
      query: ({ id, ...params }: EditStatePayload) => ({
        url: API_ENDPOINTS.UPDATE_STATE, // Assuming the ID is part of the URL
        method: API_METHODS.PUT,
        body: params,
        params: {
          id,
        },
      }),
      invalidatesTags: [StateApiTags.STATE_MODIFIED, StateApiTags.STATE_SINGLE],
    }),

    // single state
    getSingleState: builder.query<SingleStateResponse, SingleStatePayload>({
      query: (params: SingleStatePayload) => ({
        url: API_ENDPOINTS.GET_STATE_BY_ID, // The ID is part of the URL
        method: API_METHODS.GET,
        params: params,
      }),
      providesTags: [StateApiTags.STATE_SINGLE],
      transformResponse: (response: SingleStateResponse) => {
        return response
      },
    }),
  }),
})

export const {
  useAddStateMutation,
  useDeleteStateMutation,
  useEditStateMutation,
  useGetSingleStateQuery,
  useStateDetailsQuery,
} = stateApi
